<template>
  <svg id="linesS01" class="hidden lg:block" width="100%" height="100%" fill="none" viewBox="0 0 1920 1085">
    <!-- Routes -->
    <g stroke-linecap="round" stroke-width="32" stroke="#00CD69">
      <path stroke="#F4F5F6" d="M1813 1067V775.5c0-55.228-44.77-100-100-100h-664c-55.228 0-100-44.772-100-100V502c0-55.228 44.772-100 100-100h664c55.23 0 100-44.772 100-100V151c0-55.228-44.77-100-100-100h-638"/>
      <path ref="Route03" data-route="-1" d="M1813 1067V900"/>
      <path ref="Route02" data-route="1" d="M1341 402h-292c-55.228 0-100 44.772-100 100v73.5c0 55.228 44.772 100 100 100h664c55.23 0 100 44.772 100 100V900"/>
      <path ref="Route01" data-route="1" d="M1075 51h638c55.23 0 100 44.772 100 100v151c0 55.228-44.77 100-100 100h-372"/>
    </g>

    <!-- Dots -->
    <g>
      <path ref="MovingDot" fill="#00145B" d="M1797 900c0-8.836 7.16-16 16-16s16 7.164 16 16c0 8.837-7.16 16-16 16s-16-7.163-16-16z"/>
      <path ref="Dot01" fill="#CCCED7" d="M1797 204c0-8.836 7.16-16 16-16s16 7.164 16 16c0 8.837-7.16 16-16 16s-16-7.163-16-16z"/>
    </g>

    <!-- Label -->
    <g ref="Label">
      <path ref="LabelBg" fill="#CCCED7" d="M1873.6 248c2.24 0 3.36 0 4.22-.436a3.973 3.973 0 001.74-1.748c.44-.856.44-1.976.44-4.216v-75.2c0-2.24 0-3.36-.44-4.216a3.973 3.973 0 00-1.74-1.748c-.86-.436-1.98-.436-4.22-.436h-21.2c-2.24 0-3.36 0-4.22.436-.75.383-1.36.995-1.74 1.748-.44.856-.44 1.976-.44 4.216v29.115c0 .815 0 1.222-.08 1.609-.1.515-.3 1.005-.59 1.441-.22.328-.51.616-1.09 1.192-1.32 1.318-1.97 1.977-2.28 2.712-.4.98-.4 2.082 0 3.062.31.735.96 1.394 2.28 2.712.58.576.87.864 1.09 1.192.29.436.49.926.59 1.441.08.387.08.794.08 1.609V241.6c0 2.24 0 3.36.44 4.216.38.753.99 1.365 1.74 1.748.86.436 1.98.436 4.22.436h21.2z"/>
      <text class="font-main-b text-16" fill="#ffffff" opacity=".85" transform="rotate(90 852.25 1022.25)">
          <tspan x=".18" y="17.2">PAQUETE 1</tspan>
      </text>
    </g>

    <!-- Tunnel Top -->
    <g>
      <path fill="#E5E6EB" d="M1091.36 35h51.28c-3.55-20.329-13.69-35-25.64-35-11.95 0-22.09 14.671-25.64 35zm0 32h51.28c-3.55 20.329-13.69 35-25.64 35-11.95 0-22.09-14.671-25.64-35z"/>
      <path fill="#fff" d="M1092.39 30H1057v42h35.39c-1.54-6.404-2.39-13.514-2.39-21 0-7.486.85-14.596 2.39-21z"/>
    </g>

    <!-- Tunnel Bottom -->
    <g>
      <path fill="#E5E6EB" d="M1797 1050.64v-51.285c-20.33 3.555-35 13.695-35 25.645s14.67 22.09 35 25.64zm32 0v-51.285c20.33 3.555 35 13.695 35 25.645s-14.67 22.09-35 25.64z"/>
      <path fill="#fff" d="M1792 1049.61V1085h42v-35.39c-6.4 1.54-13.51 2.39-21 2.39s-14.6-.85-21-2.39z"/>
    </g>

    <g class="hidden" stroke-linecap="round">
      <path id="3_2" stroke="#F4F5F6" stroke-width="24" d="M1279.89 971.758l3.96 41.052"/>
      <path id="2_2" stroke="#00145B" stroke-width="24" d="M1176.27 948.172l-73.06 85.228"/>
      <path id="1_2" stroke="#00CD69" stroke-width="24" d="M1145.08 869.002l-72.58-9.502"/>
      <path id="4" stroke="#F4F5F6" stroke-width="8" d="M1212.63 1006.5l-6.63 59"/>
      <path id="5" stroke="#00CD69" stroke-width="8" d="M1112.38 931.916l-36.98 9.512"/>
    </g>
  </svg>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin);

export default {
  mixins: [vueGSAPMixin],

  data() {
    return {
      linesIntroTimeline: null,
      linesScrollTimeline: null,
      linesScrollTrigger: null,
      linesLabelTimeline: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetRoutes(this.$el);

      this.mediaQueries['lg'].addListener(() => {
        this.resetTimelines();
        this.createTimelines();
        this.playTimelines();
      });

      setTimeout(() => {
        this.createTimelines();
        this.playTimelines();
      }, 1000);
    });
  },

  methods: {
    createTimelines() {
      if (this.mediaQueries['lg'].matches) {
        this.linesAnimations();
      }
    },

    playTimelines() {
      if (this.mediaQueries['lg'].matches) {
        this.linesIntroTimeline.play();
      }
    },

    resetTimelines() {
      this.linesIntroTimeline && this.linesIntroTimeline.kill();
      this.linesScrollTimeline && this.linesScrollTimeline.kill();
      this.linesScrollTrigger && this.linesScrollTrigger.kill();

      this.linesScrollTimeline = null;
      this.linesIntroTimeline = null;
      this.linesScrollTrigger = null;

      this.resetRoutes(this.$el);
    },

    linesAnimations() {
      const $this = this;

      this.linesScrollTimeline = gsap.timeline({paused: true})
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()} ${this.$refs.Route02.getTotalLength()}`,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route03,
                  align: this.$refs.Route03,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()}`,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength() * -1}`,
                ease: 'none'
              },
              1
          )
          .to(this.$refs.MovingDot,
              {
                scale: 0,
                duration: 0.1,
                ease: 'none'
              },
              1
          )

      this.linesIntroTimeline = gsap.timeline(
          {
            paused: true,
            onComplete: () => this.linesScrollTrigger = ScrollTrigger.create(
                {
                  trigger: $this.$el,
                  start: 'bottom bottom-=15%',
                  end: 'bottom center-=15%',
                  scrub: 0.6,
                  animation: this.linesScrollTimeline,
                }
            )
          })
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route02,
                  align: this.$refs.Route02,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: .5,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.Route02,
              {
                duration: .5,
                drawSVG: `${this.$refs.Route02.getTotalLength()}`,
                ease: 'none'
              },
              0
          )
    },
  }
}
</script>

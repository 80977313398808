<template>
  <div class="fixed z-100 w-screen h-screen flex items-center justify-center bg-white">
    <svg class="fill-current w-12 xsm:w-14 sm:w-16 md:w-20 lg:w-24 xl:w-28 3xl:w-32 4xl:w-36 5xl:w-40" :class="`text-${color}`" viewBox="0 0 120 30">
      <circle cx="15" cy="15" r="15">
        <animate attributeName="r" from="15" to="15"
                 begin="0s" dur="0.8s"
                 values="15;9;15" calcMode="linear"
                 repeatCount="indefinite"/>
        <animate attributeName="fill-opacity" from="1" to="1"
                 begin="0s" dur="0.8s"
                 values="1;.5;1" calcMode="linear"
                 repeatCount="indefinite"/>
      </circle>
      <circle cx="60" cy="15" r="9" fill-opacity="0.3">
        <animate attributeName="r" from="9" to="9"
                 begin="0s" dur="0.8s"
                 values="9;15;9" calcMode="linear"
                 repeatCount="indefinite"/>
        <animate attributeName="fill-opacity" from="0.5" to="0.5"
                 begin="0s" dur="0.8s"
                 values=".5;1;.5" calcMode="linear"
                 repeatCount="indefinite"/>
      </circle>
      <circle cx="105" cy="15" r="15">
        <animate attributeName="r" from="15" to="15"
                 begin="0s" dur="0.8s"
                 values="15;9;15" calcMode="linear"
                 repeatCount="indefinite"/>
        <animate attributeName="fill-opacity" from="1" to="1"
                 begin="0s" dur="0.8s"
                 values="1;.5;1" calcMode="linear"
                 repeatCount="indefinite"/>
      </circle>
    </svg>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    color: String
  },

  mounted() {
    this.togglePreloaderState()
  },

  methods: {
    ...mapMutations([`togglePreloaderState`])
  }
}
</script>

<template>
  <svg class="w-full h-full" viewBox="0 0 1920 1029">
    <g fill="none" fill-rule="evenodd">
      <path stroke="#F4F5F6" stroke-linecap="round" stroke-width="32" d="M1904 0v186c0 55.228-44.772 100-100 100H940.812c-55.228 0-100 44.772-100 100v304c0 55.228 44.772 100 100 100h350c55.229 0 100 44.772 100 100v123h0"/>
      <path stroke="#FF8B00" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M840.813 572.664V690c0 55.228 44.771 100 100 100h263"/>
      <path fill="#E5E6EB" d="M1374.813 996c-22.667-5.022-34-13.416-34-25.183 0-11.766 11.333-20.372 34-25.817v51zm66-25.817c0 11.766-11.334 20.372-34 25.817v-51c22.666 5.022 34 13.416 34 25.183z"/>
      <g fill="#FFF" transform="rotate(90 205.406 1201.406)">
        <path d="M0 0h33v32H0c.61-5.371.942-10.716 1-16.034C1.058 10.648.724 5.326 0 0z"/>
        <circle cx="17" cy="16" r="16"/>
      </g>
      <path fill="#FFD400" d="M1219.813 790c0-8.836-7.164-16-16-16-8.837 0-16 7.164-16 16 0 8.837 7.163 16 16 16 8.836 0 16-7.163 16-16z"/>
      <path fill="#CCCED7" d="M1215 286c0-8.836-7.164-16-16-16s-16 7.164-16 16c0 8.837 7.164 16 16 16s16-7.163 16-16z"/>
    </g>
  </svg>
</template>

<script>
export default {}
</script>

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isMenuOpen: false,
        isLoginFormOpen: false,
        isRegisterComplete: false,
        preloaderMounted: false
    },
    getters: {},
    mutations: {
        togglePreloaderState(state) {
            state.preloaderMounted = !state.preloaderMounted
        },
        toggleSideMenuState(state) {
            state.isMenuOpen = !state.isMenuOpen
        },
        openLoginForm(state) {
            state.isLoginFormOpen = true
            state.isMenuOpen = true
        },
        closeLoginForm(state) {
            state.isLoginFormOpen = false
        },
        openRegisterCompleteScreen(state) {
            state.isRegisterComplete = true
            state.isMenuOpen = true
        },
        resetRegisterComplete(state) {
            state.isRegisterComplete = false
        },
    },
    actions: {}
});

<template>
  <svg class="w-0 h-0">
    <defs>
      <linearGradient id="paint0_linear" x1="21.963" x2="-3.07" y1="-10.264" y2="-3.495" gradientUnits="userSpaceOnUse">
        <stop stop-color="#00A0FF"/>
        <stop offset=".007" stop-color="#00A1FF"/>
        <stop offset=".26" stop-color="#00BEFF"/>
        <stop offset=".512" stop-color="#00D2FF"/>
        <stop offset=".76" stop-color="#00DFFF"/>
        <stop offset="1" stop-color="#00E3FF"/>
      </linearGradient>
      <linearGradient id="paint1_linear" x1="42.63" x2="12.142" y1="11.597" y2="11.597" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFE000"/>
        <stop offset=".409" stop-color="#FFBD00"/>
        <stop offset=".775" stop-color="orange"/>
        <stop offset="1" stop-color="#FF9C00"/>
      </linearGradient>
      <linearGradient id="paint2_linear" x1="12.69" x2="-7.426" y1="16.115" y2="49.888" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF3A44"/>
        <stop offset="1" stop-color="#C31162"/>
      </linearGradient>
      <linearGradient id="paint3_linear" x1=".814" x2="9.726" y1="4.287" y2="19.389" gradientUnits="userSpaceOnUse">
        <stop stop-color="#32A071"/>
        <stop offset=".069" stop-color="#2DA771"/>
        <stop offset=".476" stop-color="#15CF74"/>
        <stop offset=".801" stop-color="#06E775"/>
        <stop offset="1" stop-color="#00F076"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>

<template>
  <svg class="w-full h-full" viewBox="0 0 1920 1029">
    <g fill="none" fill-rule="evenodd">
      <path stroke="#F4F5F6" stroke-linecap="round" stroke-width="32" d="M1407 0v186c0 55.228-44.772 100-100 100H971c-55.228 0-100 44.772-100 100v304c0 55.228 44.772 100 100 100h667.5c55.228 0 100 44.772 100 100v114.5h0"/>
      <path stroke="#FFD400" stroke-width="32" d="M1407 0v186c0 55.228-44.772 100-100 100h-274.902 0"/>
      <path fill="#E5E6EB" d="M1722 996c-22.667-5.022-34-13.416-34-25.183 0-11.766 11.333-20.372 34-25.817v51zm66-25.817c0 11.766-11.333 20.372-34 25.817v-51c22.667 5.022 34 13.416 34 25.183z"/>
      <g fill="#FFF" transform="rotate(90 379 1375)">
        <path d="M0 0h33v32H0c.61-5.371.942-10.716 1-16.034C1.058 10.648.724 5.326 0 0z"/>
        <circle cx="17" cy="16" r="16"/>
      </g>
      <path fill="#FF8B00" d="M1047 286c0-8.836-7.164-16-16-16s-16 7.164-16 16c0 8.837 7.164 16 16 16s16-7.163 16-16z"/>
      <path fill="#CCCED7" d="M1411 790c0-8.836-7.164-16-16-16s-16 7.164-16 16c0 8.837 7.164 16 16 16s16-7.163 16-16z"/>
      <g>
        <path fill="#FF8B00" d="M992.128 220h77.744c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v23.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 01-1.512 1.512c-.652.348-1.299.534-3.082.534h-30.394c-1.032 0-1.39.037-1.786.129a3.482 3.482 0 00-1.09.452c-.345.215-.624.441-1.355 1.172L1034 257c-.595.595-1.262.992-1.952 1.19a3.764 3.764 0 01-2.096 0c-.69-.198-1.357-.595-1.952-1.19l-1.247-1.247c-.73-.73-1.01-.957-1.354-1.172a3.482 3.482 0 00-1.091-.452c-.396-.092-.754-.129-1.786-.129h-30.394c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 01-1.512-1.512c-.348-.652-.534-1.299-.534-3.082v-23.744c0-1.783.186-2.43.534-3.082a3.635 3.635 0 011.512-1.512c.652-.348 1.299-.534 3.082-.534z"/>
        <text class="font-main-b fill-current text-white text-16 opacity-85" transform="translate(987 220)">
          <tspan x="12" y="23">DROP OFF</tspan>
        </text>
      </g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<template>
  <div class="cursor fixed z-90 block top-0 left-0 w-3 h-3 rounded-full pointer-events-none will-change-transform opacity-0" :class="`bg-${color}`"></div>
</template>

<script>
import gsap from "gsap";
import MagnetCtrl from "@/includes/magnetCtrl";
import { getMousePos } from "@/includes/utils";

export default {
  props: {
    color: String
  },

  data() {
    return {
      mouse: {x: 0, y: 0},
      bounds: null,
      currentCursorZone: null,
      isMagnetInCursorZone: false,
      inLargeCursorZone: false,
      magnetObjects: [],
      menuMagnets: [],
      headerMagnets: [],
      pageMagnets: [],
    }
  },

  created() {
    window.addEventListener('load', () => {
      if (!this.mediaQueries['lg'].matches) {
        return;
      }
      this.bounds = this.$el.getBoundingClientRect();

      let magnets = document.querySelectorAll('[data-magnet]');
      this.initMagnets(magnets);
      this.cursorZones();

      setTimeout(() => {
        window.addEventListener('mousemove', this.show);
        window.addEventListener('mousemove', ev => this.followMouse(ev));
      }, 1000)
    });
  },

  methods: {
    show() {
      gsap.to(this.$el, 0.4, {opacity: 1});

      window.removeEventListener('mousemove', this.show);
    },

    followMouse(ev) {
      this.mouse = getMousePos(ev);

      gsap.to(this.$el, 0.4, {
        left: this.mouse.x - this.bounds.width / 2,
        top: this.mouse.y - this.bounds.height / 2,
        ease: 'power1'
      });
    },

    onMagnetEnter() {
      this.$el.classList.add('cursor-big')

      if (!this.currentCursorZone && !this.isMagnetInCursorZone) {
        gsap.to(this.$el, {duration: .1, opacity: .75})
      }
    },

    onMagnetLeave() {
      if (!this.inLargeCursorZone) {
        this.isMagnetInCursorZone = false
        this.$el.classList.remove('cursor-big')
        gsap.to(this.$el, {duration: .1, opacity: 1})
      }
    },

    reCalculateMagnetPosition(magnet) {
      if (!this.magnetObjects.length > 0 || !magnet) {
        return
      }

      // Recalculate magnet positioning
      magnet.calculateSizePosition();
    },

    stopMagnet(magnet) {
      if (!this.magnetObjects.length > 0 || !magnet) {
        return
      }

      // Remove mousemove event listener from magnet
      magnet.removeEventListener();
    },

    startMagnet(magnet) {
      if (!this.magnetObjects.length > 0 || !magnet) {
        return
      }

      // Remove mousemove event listener from magnet
      magnet.initMouseMoveEvent();
    },

    initMagnets(magnets) {
      [...magnets].forEach((el, i) => {
        // Check if the magnet is in the smooth scroll view (smooth scrollbar) or it's fixed outside
        let scrollArea = el.dataset.magnetType ? null : this.$root.bodyScrollBar;

        // Instantiate magnet controllers
        this.magnetObjects[i] = new MagnetCtrl(el, scrollArea);

        this.magnetObjects[i].on('enter', () => this.onMagnetEnter());
        this.magnetObjects[i].on('entered-zone', () => this.isMagnetInCursorZone = true);
        this.magnetObjects[i].on('leave', () => this.onMagnetLeave());
      });

      this.menuMagnets = [...this.magnetObjects].filter(m => {
        return m.domEl.dataset.magnet === 'side-menu';
      });

      this.headerMagnets = [...this.magnetObjects].filter(m => {
        return m.domEl.dataset.magnet === 'header';
      });

      this.pageMagnets = [...this.magnetObjects].filter(m => {
        return m.domEl.dataset.magnet === '';
      });
    },

    cursorZones() {
      const zones = document.querySelectorAll('[data-cursor-zone]');
      const largeCursorZones = document.querySelectorAll('[data-cursor-large]');

      [...largeCursorZones].forEach(zone => {
        zone.addEventListener('mouseenter', () => {
          this.inLargeCursorZone = true;
          this.$el.classList.add('cursor-big');
        });

        zone.addEventListener('mouseleave', () => {
          this.inLargeCursorZone = false;
          this.$el.classList.remove('cursor-big');
        });
      });

      [...zones].forEach(zone => {
        zone.addEventListener('mouseenter', () => {
          this.currentCursorZone = zone.dataset.cursorZone;
          this.$el.classList.add(`cursor-${this.currentCursorZone}`);
        });

        zone.addEventListener('mouseleave', () => {
          this.$el.classList.remove(`cursor-${this.currentCursorZone}`);
          this.currentCursorZone = null;
        });
      });
    },
  }
}
</script>

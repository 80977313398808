<script>
import gsap from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(CSSPlugin)

export default {
  props: {
    item: Object
  },

  data() {
    return {
      isOpen: false,
      duration: 0.3
    }
  },

  mounted() {
    window.addEventListener('resize', () => {
      if (!this.isOpen) {
        return
      }
      setTimeout(() => {
        this.close()
        this.isOpen = false
      }, 300)
    });

    gsap.set(this.$refs.circle, {opacity: 0})
    gsap.set(this.$refs.plus, {rotate: -45, fill: '#ccced7', transformOrigin: '50% 50%'})
  },

  methods: {

    toggle(node) {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        this.open(node)
      } else {
        this.close()
      }
    },

    open(node) {
      gsap.set(this.$refs.accContent, {height: "auto"})
      gsap.from(this.$refs.accContent, this.duration, {height: 0})
      gsap.to(this.$refs.circle, this.duration, {opacity: 1, ease: 'power2'})
      gsap.to(this.$refs.plus, this.duration, {rotate: 0, fill: '#fff', ease: 'power2'})

      let el = node ? this.$refs.accContent[0] : this.$refs.accContent

      setTimeout(() => {
        this.$root.scrollToSection(null, el);
      }, this.duration);
    },

    close() {
      gsap.fromTo(this.$refs.accContent, this.duration, {height: this.$refs.accContent.clientHeight}, {height: 0, ease: 'power2'})
      gsap.to(this.$refs.circle, this.duration, {opacity: 0, ease: 'power2'})
      gsap.to(this.$refs.plus, this.duration, {rotate: -45, fill: '#ccced7', ease: 'power2'})
    },
  }
}
</script>

<template>
    <g>
      <!-- Routes -->
      <g stroke-linecap="round" stroke-width="32" stroke="#00CD69">
        <path stroke="#F4F5F6" d="M1904 1806v0c0-40.97 0-61.45-5.91-77.87a99.998 99.998 0 00-60.22-60.22c-16.42-5.91-36.9-5.91-77.87-5.91h-225c-56.01 0-84.01 0-105.4-10.9a99.981 99.981 0 01-43.7-43.7c-10.9-21.39-10.9-49.39-10.9-105.4v-24c0-56.01 0-84.01-10.9-105.4a99.981 99.981 0 00-43.7-43.7c-21.39-10.9-49.39-10.9-105.4-10.9H671c-56.005 0-84.008 0-105.399 10.9a99.996 99.996 0 00-43.702 43.7C511 1393.99 511 1421.99 511 1478v1241c0 56.01 0 84.01 10.899 105.4a99.996 99.996 0 0043.702 43.7C586.992 2879 614.995 2879 671 2879h892c56.01 0 84.01 0 105.4 10.9a99.981 99.981 0 0143.7 43.7c10.9 21.39 10.9 49.39 10.9 105.4v190c0 56.01 0 84.01-10.9 105.4a99.981 99.981 0 01-43.7 43.7c-21.39 10.9-49.39 10.9-105.4 10.9h-443c-56.01 0-84.01 0-105.4 10.9a99.999 99.999 0 00-43.701 43.7C960 3464.99 960 3492.99 960 3549v183c0 56.01 0 84.01 10.899 105.4a99.999 99.999 0 0043.701 43.7c21.39 10.9 49.39 10.9 105.4 10.9h312c39.1 0 58.64 0 74.39 5.39a100 100 0 0162.22 62.22c5.39 15.75 5.39 35.29 5.39 74.39v0c0 39.1 0 58.64-5.39 74.39a100 100 0 01-62.22 62.22c-15.75 5.39-35.29 5.39-74.39 5.39h-312c-56.01 0-84.01 0-105.4 10.9a99.999 99.999 0 00-43.701 43.7C960 4251.99 960 4279.99 960 4336v100"/>

        <path ref="Route06" data-route="-1" d="M960 4436v-100c0-56.01 0-84.01 10.899-105.4a99.999 99.999 0 0143.701-43.7c21.39-10.9 49.39-10.9 105.4-10.9h252"/>
        <path ref="Route05" data-route="-1" d="M1372 4176h60c39.1 0 58.64 0 74.39-5.39a100 100 0 0062.22-62.22c5.39-15.75 5.39-35.29 5.39-74.39v0c0-39.1 0-58.64-5.39-74.39a100 100 0 00-62.22-62.22c-15.75-5.39-35.29-5.39-74.39-5.39h-312c-56.01 0-84.01 0-105.4-10.9a99.999 99.999 0 01-43.701-43.7C960 3816.01 960 3788.01 960 3732v-183c0-56.01 0-84.01 10.899-105.4a99.999 99.999 0 0143.701-43.7c21.39-10.9 49.39-10.9 105.4-10.9h443c56.01 0 84.01 0 105.4-10.9a99.981 99.981 0 0043.7-43.7c10.9-21.39 10.9-49.39 10.9-105.4v-117"/>
        <path ref="Route04" data-route="1" d="M1372 2879h191c56.01 0 84.01 0 105.4 10.9a99.981 99.981 0 0143.7 43.7c10.9 21.39 10.9 49.39 10.9 105.4v73"/>
        <path ref="Route03" data-route="1" d="M511 2098.5V2719c0 56.01 0 84.01 10.899 105.4a99.996 99.996 0 0043.702 43.7C586.992 2879 614.995 2879 671 2879h701"/>
        <path ref="Route02" data-route="1" d="M960 1318H671c-56.005 0-84.008 0-105.399 10.9a99.996 99.996 0 00-43.702 43.7C511 1393.99 511 1421.99 511 1478v620.5"/>
        <path ref="Route01" data-route="1" d="M1904 1806v0c0-40.97 0-61.45-5.91-77.87a99.998 99.998 0 00-60.22-60.22c-16.42-5.91-36.9-5.91-77.87-5.91h-225c-56.01 0-84.01 0-105.4-10.9a99.981 99.981 0 01-43.7-43.7c-10.9-21.39-10.9-49.39-10.9-105.4v-24c0-56.01 0-84.01-10.9-105.4a99.981 99.981 0 00-43.7-43.7c-21.39-10.9-49.39-10.9-105.4-10.9H960"/>
      </g>

      <!-- Dots -->
      <g>
        <path ref="MovingDot" fill="#00145B" d="M1920 1806c0-8.84-7.16-16-16-16s-16 7.16-16 16 7.16 16 16 16 16-7.16 16-16z"/>
        <path ref="Dot01" fill="#CCCED7" d="M1920 1806c0-8.84-7.16-16-16-16s-16 7.16-16 16 7.16 16 16 16 16-7.16 16-16z"/>
        <circle ref="Dot02" r="16" fill="#00145B" transform="matrix(1 0 0 1,1723,3113)"/>
        <circle ref="Dot03" r="16" fill="#00145B" transform="matrix(1 0 0 1 1372 4176)"/>
      </g>

      <!-- Label 01 -->
      <g ref="Label01">
        <path ref="Label01Bg" fill="#00145B" d="M1781.6 3064c2.24 0 3.36 0 4.22.44.75.38 1.36.99 1.74 1.74.44.86.44 1.98.44 4.22v81.2c0 2.24 0 3.36-.44 4.22-.38.75-.99 1.36-1.74 1.74-.86.44-1.98.44-4.22.44h-21.2c-2.24 0-3.36 0-4.22-.44-.75-.38-1.36-.99-1.74-1.74-.44-.86-.44-1.98-.44-4.22v-32.11c0-.82 0-1.23-.08-1.61-.1-.52-.3-1.01-.59-1.45-.22-.32-.51-.61-1.09-1.19-1.32-1.32-1.97-1.97-2.28-2.71-.4-.98-.4-2.08 0-3.06.31-.74.96-1.39 2.28-2.71.58-.58.87-.87 1.09-1.19.29-.44.49-.93.59-1.45.08-.38.08-.79.08-1.61v-32.11c0-2.24 0-3.36.44-4.22.38-.75.99-1.36 1.74-1.74.86-.44 1.98-.44 4.22-.44h21.2z"/>
        <text class="font-main-b text-16" fill="#ffffff" opacity=".85" transform="rotate(90 -648.25 2429.75)">
          <tspan x=".359" y="17.2">PARADA 3</tspan>
        </text>
      </g>

      <!-- Label 02 -->
      <g ref="Label02">
        <path ref="Label02Bg" fill="#00145B" d="M1328 4117.4c0-2.24 0-3.36.44-4.22.38-.75.99-1.36 1.74-1.74.86-.44 1.98-.44 4.22-.44h75.2c2.24 0 3.36 0 4.22.44.75.38 1.36.99 1.74 1.74.44.86.44 1.98.44 4.22v21.2c0 2.24 0 3.36-.44 4.22-.38.75-.99 1.36-1.74 1.74-.86.44-1.98.44-4.22.44h-29.11c-.82 0-1.23 0-1.61.08-.52.1-1.01.3-1.45.59-.32.22-.61.51-1.19 1.09-1.32 1.32-1.97 1.97-2.71 2.28-.98.4-2.08.4-3.06 0-.74-.31-1.39-.96-2.71-2.28-.58-.58-.87-.87-1.19-1.09-.44-.29-.93-.49-1.45-.59-.38-.08-.79-.08-1.61-.08h-29.11c-2.24 0-3.36 0-4.22-.44-.75-.38-1.36-.99-1.74-1.74-.44-.86-.44-1.98-.44-4.22v-21.2z"/>
        <text class="font-main-b text-16" fill="#ffffff" opacity=".85">
          <tspan x="1344.41" y="4134.2">DESTINO</tspan>
        </text>
      </g>

      <!-- Tunnel -->
      <g ref="Tunnel">
        <path fill="#E5E6EB" d="M976 4419.64v-51.28c20.329 3.55 35 13.69 35 25.64 0 11.95-14.671 22.09-35 25.64zm-32 0v-51.28c-20.329 3.55-35 13.69-35 25.64 0 11.95 14.671 22.09 35 25.64z"/>
        <path fill="#fff" d="M981 4418.61V4454h-42v-35.39c6.404 1.54 13.514 2.39 21 2.39 7.486 0 14.596-.85 21-2.39z"/>
      </g>
    </g>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';
import ExpoScaleEase from 'gsap/EasePack';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin, ExpoScaleEase);

export default {
  mixins: [vueGSAPMixin],

  data() {
    return {
      linesTimeline: null,
      linesScrollTrigger: null,
      linesLabelTimeline01: null,
      linesLabelTimeline02: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetRoutes(this.$el);

      setTimeout(() => {
        this.animateLines();
      }, 1000)
    });
  },

  methods: {
    animateLines() {
      let direction = 1;

      this.linesLabelTimeline01 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot02,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot02,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label01,
              0.3,
              {
                xPercent: 100,
                opacity: 0,
              },
              {
                xPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          );

      this.linesLabelTimeline02 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot03,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot03,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label02,
              0.3,
              {
                yPercent: -150,
                opacity: 0,
              },
              {
                yPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )

      this.linesTimeline = gsap.timeline()
          .fromTo(this.$refs.MovingDot,
              0.1,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route03,
                  align: this.$refs.Route03,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0.1
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              0.1
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()} ${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route04,
                  align: this.$refs.Route04,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              1.1
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline01.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline01.reverse();
                }
              },
              2.1
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength()} ${this.$refs.Route04.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route05,
                  align: this.$refs.Route05,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              2.1
          )
          .to(this.$refs.Route05,
              1,
              {
                drawSVG: `${this.$refs.Route05.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.Dot02,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              3.1
          )
          .to(this.$refs.Label01Bg,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              3.1
          )
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline02.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline02.reverse();
                }
              },
              3.1
          )
          .to(this.$refs.Route05,
              1,
              {
                drawSVG: `${this.$refs.Route05.getTotalLength() * -1}`,
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route06,
                  align: this.$refs.Route06,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              3.1
          )
          .to(this.$refs.Route06,
              1,
              {
                drawSVG: `${this.$refs.Route05.getTotalLength()}`,
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.Dot03,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              4.1
          )
          .to(this.$refs.Label02Bg,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              4.1
          )
          .to(this.$refs.Route06,
              1,
              {
                drawSVG: `${this.$refs.Route06.getTotalLength() * -1}`,
                ease: 'none',
              },
              4.1
          )
          .to(this.$refs.MovingDot,
              0.1,
              {
                scale: 0,
                ease: 'none'
              },
              5
          )

      ScrollTrigger.matchMedia({
        "(min-width: 960px)": () => {
          ScrollTrigger.create({
            trigger: this.$refs.Route03,
            start: 'top center+=7%',
            endTrigger: this.$refs.Tunnel,
            end: 'bottom center',
            scrub: 0.6,
            animation: this.linesTimeline,
            onUpdate: self => {
              if (self.direction === direction) {
                return;
              }
              direction = self.direction;
            },
          });
        },
      });
    },
  }
}
</script>


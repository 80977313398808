<template>
    <g>
      <!-- Routes -->
      <g stroke-linecap="round" stroke-width="32" stroke="#FF8B00">
        <path stroke="#F4F5F6" d="M1161 4036h583c56.01 0 84.01 0 105.4-10.9a100.033 100.033 0 0043.7-43.7C1904 3960 1904 3932 1904 3876v-134c0-56.01 0-84.01-10.9-105.4a99.981 99.981 0 00-43.7-43.7c-21.39-10.9-49.39-10.9-105.4-10.9h-213c-56.01 0-84.01 0-105.4-10.9a99.981 99.981 0 01-43.7-43.7c-10.9-21.39-10.9-49.39-10.9-105.4v-514c0-56.01 0-84.01-10.9-105.4a99.981 99.981 0 00-43.7-43.7c-21.39-10.9-49.39-10.9-105.4-10.9H341.995c-56.005 0-84.008 0-105.399-10.9a99.989 99.989 0 01-43.701-43.7c-10.9-21.39-10.9-49.39-10.9-105.4v-286c0-56.01 0-84.01 10.9-105.4a99.989 99.989 0 0143.701-43.7c21.391-10.9 49.394-10.9 105.399-10.9H678c56.005 0 84.008 0 105.399-10.9a99.996 99.996 0 0043.702-43.7C838 2066.01 838 2038.01 838 1982v-125 0c0-47.42 0-71.13-7.878-89.78a99.974 99.974 0 00-53.217-53.22c-18.655-7.88-42.364-7.88-89.783-7.88H176c-56.005 0-84.008 0-105.4-10.9a99.94 99.94 0 01-43.7-43.7C16 1630.13 16 1602.13 16 1546.12v-203.53c0-41.19 33.397-74.59 74.594-74.59v0c41.197 0 74.594-33.4 74.594-74.59V1168"/>

        <path ref="Route04" data-route="-1" d="M1161 4036h583c56.01 0 84.01 0 105.4-10.9a100.033 100.033 0 0043.7-43.7C1904 3960 1904 3932 1904 3876v-134c0-56.01 0-84.01-10.9-105.4a99.981 99.981 0 00-43.7-43.7c-21.39-10.9-49.39-10.9-105.4-10.9h-106.16"/>
        <path ref="Route03" data-route="1" d="M891 2748h320c56.01 0 84.01 0 105.4 10.9a99.981 99.981 0 0143.7 43.7c10.9 21.39 10.9 49.39 10.9 105.4v514c0 56.01 0 84.01 10.9 105.4a99.981 99.981 0 0043.7 43.7c21.39 10.9 49.39 10.9 105.4 10.9h106.84"/>
        <path ref="Route02" data-route="1" d="M838.005 1857v125c0 56.01 0 84.01-10.9 105.4a99.989 99.989 0 01-43.701 43.7c-21.391 10.9-49.394 10.9-105.399 10.9H342c-56.005 0-84.008 0-105.399 10.9a99.996 99.996 0 00-43.702 43.7C182 2217.99 182 2245.99 182 2302v286c0 56.01 0 84.01 10.899 105.4a99.996 99.996 0 0043.702 43.7C257.992 2748 285.995 2748 342 2748h549.005"/>
        <path ref="Route01" data-route="1" d="M165.188 1168v25.41c0 41.19-33.397 74.59-74.594 74.59v0C49.397 1268 16 1301.4 16 1342.59v203.53c0 56.01 0 84.01 10.9 105.4a99.94 99.94 0 0043.7 43.7c21.392 10.9 49.395 10.9 105.4 10.9h511.122c47.419 0 71.128 0 89.783 7.88a99.974 99.974 0 0153.217 53.22C838 1785.87 838 1809.58 838 1857v0"/>
      </g>

      <!-- Dots -->
      <g>
        <path ref="MovingDot" fill="#FFD400" d="M181 1168c0-8.84-7.164-16-16-16s-16 7.16-16 16 7.164 16 16 16 16-7.16 16-16z"/>
        <path ref="Dot01" fill="#FFD400" d="M181 1168c0-8.84-7.164-16-16-16s-16 7.16-16 16 7.164 16 16 16 16-7.16 16-16z"/>
        <path ref="Dot02" fill="#FFD400" d="M822 1857c0 8.84 7.164 16 16 16 8.837 0 16-7.16 16-16s-7.163-16-16-16c-8.836 0-16 7.16-16 16z"/>
        <path ref="Dot03" fill="#FFD400" d="M875 2748c0 8.84 7.164 16 16 16 8.837 0 16-7.16 16-16s-7.163-16-16-16c-8.836 0-16 7.16-16 16z"/>
      </g>

      <!-- Label 01 -->
      <g ref="Label01">
        <path ref="Label01Bg" fill="#FF8B00" d="M115 1102.4c0-2.24 0-3.36.436-4.22.383-.75.995-1.36 1.748-1.74.856-.44 1.976-.44 4.216-.44h89.2c2.24 0 3.36 0 4.216.44.753.38 1.365.99 1.748 1.74.436.86.436 1.98.436 4.22v28.2c0 2.24 0 3.36-.436 4.22a3.973 3.973 0 01-1.748 1.74c-.856.44-1.976.44-4.216.44h-35.402c-.959 0-1.439 0-1.891.11-.402.09-.786.25-1.139.46-.399.24-.743.57-1.431 1.24l-.876.85c-1.566 1.52-2.348 2.29-3.246 2.57a4.03 4.03 0 01-2.43 0c-.898-.28-1.68-1.05-3.246-2.57l-.876-.85c-.688-.67-1.032-1-1.431-1.24a3.905 3.905 0 00-1.139-.46c-.452-.11-.932-.11-1.891-.11H121.4c-2.24 0-3.36 0-4.216-.44a3.973 3.973 0 01-1.748-1.74c-.436-.86-.436-1.98-.436-4.22v-28.2z"/>
        <text class="font-main-b text-16" fill="#ffffff" opacity=".85">
          <tspan x="144.336" y="1123.2">DESDE</tspan>
        </text>
      </g>

      <!-- Label 02 -->
      <g ref="Label02">
        <path ref="Label02Bg" fill="#FF8B00" d="M897.6 1814c2.24 0 3.36 0 4.216.44.753.38 1.365.99 1.748 1.74.436.86.436 1.98.436 4.22v81.2c0 2.24 0 3.36-.436 4.22a3.973 3.973 0 01-1.748 1.74c-.856.44-1.976.44-4.216.44h-21.2c-2.24 0-3.36 0-4.216-.44a3.973 3.973 0 01-1.748-1.74c-.436-.86-.436-1.98-.436-4.22v-32.11c0-.82 0-1.23-.077-1.61a4.015 4.015 0 00-.597-1.45c-.219-.32-.507-.61-1.083-1.19-1.318-1.32-1.977-1.97-2.282-2.71a3.996 3.996 0 010-3.06c.305-.74.964-1.39 2.282-2.71.576-.58.864-.87 1.083-1.19.292-.44.495-.93.597-1.45.077-.38.077-.79.077-1.61v-32.11c0-2.24 0-3.36.436-4.22.383-.75.995-1.36 1.748-1.74.856-.44 1.976-.44 4.216-.44h21.2z"/>
        <text class="font-main-b text-16" fill="#ffffff" opacity=".85" transform="rotate(90 -465.25 1362.75)">
          <tspan x=".43" y="17.2">PARADA 2</tspan>
        </text>
      </g>

      <!-- Label 03 -->
      <g ref="Label03">
        <path ref="Label03Bg" fill="#FF8B00" d="M847 2688.4c0-2.24 0-3.36.436-4.22.383-.75.995-1.36 1.748-1.74.856-.44 1.976-.44 4.216-.44h75.2c2.24 0 3.36 0 4.216.44.753.38 1.365.99 1.748 1.74.436.86.436 1.98.436 4.22v21.2c0 2.24 0 3.36-.436 4.22a3.973 3.973 0 01-1.748 1.74c-.856.44-1.976.44-4.216.44h-29.115c-.815 0-1.222 0-1.609.08-.515.1-1.005.3-1.441.59-.328.22-.616.51-1.192 1.09-1.318 1.32-1.977 1.97-2.712 2.28-.98.4-2.082.4-3.062 0-.735-.31-1.394-.96-2.712-2.28-.576-.58-.864-.87-1.192-1.09a3.966 3.966 0 00-1.441-.59c-.387-.08-.794-.08-1.609-.08H853.4c-2.24 0-3.36 0-4.216-.44a3.973 3.973 0 01-1.748-1.74c-.436-.86-.436-1.98-.436-4.22v-21.2z"/>
        <text class="font-main-b text-16" fill="#ffffff" opacity=".85" transform="translate(860.5 2688.5)">
          <tspan x=".055" y="17.2">TRABAJO</tspan>
        </text>
      </g>

      <!-- FX -->
      <g>
        <path ref="FxLine01" fill="#F4F5F6" d="M686 2785c0-1.66 1.343-3 3-3h40c1.657 0 3 1.34 3 3s-1.343 3-3 3h-40c-1.657 0-3-1.34-3-3z"/>
        <path ref="FxLine02" fill="#F4F5F6" d="M732 2705c0-1.66 1.343-3 3-3h70c1.657 0 3 1.34 3 3s-1.343 3-3 3h-70c-1.657 0-3-1.34-3-3z"/>
        <text ref="MovingText" class="font-main-b text-16 opacity-0" fill="#ffffff" opacity=".85">
          <tspan x="805.295" y="2753.93">LLEGANDO</tspan>
        </text>
      </g>
    </g>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';
import ExpoScaleEase from 'gsap/EasePack';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin, ExpoScaleEase);

export default {
  mixins: [vueGSAPMixin],

  data() {
    return {
      linesTimeline: null,
      linesScrollTrigger: null,
      linesLabelTimeline01: null,
      linesLabelTimeline02: null,
      linesLabelTimeline03: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetRoutes(this.$el);

      setTimeout(() => {
        this.animateLines();
      }, 1000)
    });
  },

  methods: {
    animateLines() {
      let direction = 1;

      this.linesLabelTimeline01 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot01,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot01,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label01,
              0.3,
              {
                yPercent: -150,
                opacity: 0,
              },
              {
                yPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          );

      this.linesLabelTimeline02 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot02,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot02,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label02,
              0.3,
              {
                xPercent: 150,
                opacity: 0,
              },
              {
                xPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          );

      this.linesLabelTimeline03 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot03,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot03,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label03,
              0.3,
              {
                yPercent: -150,
                opacity: 0,
              },
              {
                yPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          );

      this.linesTimeline = gsap.timeline()
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline01.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline01.reverse();
                }
              },
              0.1
          )
          .fromTo(this.$refs.MovingDot,
              0.1,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1,
                ease: 'none'
              },
              0.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route01,
                  align: this.$refs.Route01,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0.2
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              0.2
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()} ${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              1.2
          )
          .to(this.$refs.Dot01,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              1.2
          )
          .to(this.$refs.Label01Bg,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              1.2
          )
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline02.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline02.reverse();
                }
              },
              1.2
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route02,
                  align: this.$refs.Route02,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              1.2
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              1.2
          )
          .to(this.$refs.MovingText,
              0.1,
              {
                opacity: .85,
              },
              1.99
          )
          .fromTo(this.$refs.MovingText,
              0.21,
              {
                xPercent: -690,
              },
              {
                xPercent: -15,
                ease: 'none'
              },
              1.99
          )
          .to(this.$refs.Dot02,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              2.2
          )
          .to(this.$refs.Label02Bg,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              2.2
          )
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline03.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline03.reverse();
                }
              },
              2.2
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()} ${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              2.2
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route03,
                  align: this.$refs.Route03,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              2.2
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              2.2
          )
          .to(this.$refs.MovingText,
              0.1,
              {
                fill: '#ccced7',
                ease: 'none'
              },
              3.2
          )
          .to(this.$refs.Dot03,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              3.2
          )
          .to(this.$refs.Label03Bg,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              3.2
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()} ${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              3.2
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route04,
                  align: this.$refs.Route04,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              3.2
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength()}`,
                ease: 'none',
              },
              3.2
          )

      ScrollTrigger.matchMedia({
        "(min-width: 960px)": () => {
          ScrollTrigger.create({
            trigger: this.$refs.Route01,
            start: 'top bottom-=20%',
            endTrigger: this.$refs.Route04,
            end: 'bottom center+=12%',
            scrub: 0.6,
            animation: this.linesTimeline,
            onUpdate: self => {
              if (self.direction === direction) {
                return;
              }
              direction = self.direction;
            },
          });
        },
      });
    },
  }
}
</script>


<template>
  <svg class="hidden xsm:block lg:hidden w-full h-full" fill="none" viewBox="0 0 636 750">
    <!-- Routes -->
    <g stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
      <path stroke="#F4F5F6" d="M320 735.375v-163c0-33.137-26.863-60-60-60H72.425c-33.137 0-60-26.863-60-60v-47c0-33.137 26.863-60 60-60H563.927c33.137 0 60-26.863 60-60V239.597c0-33.137-26.863-60-60-60h-437.08c-33.137 0-60-26.863-60-60V72.949c0-33.137 26.863-60 60-60H320"/>
      <path :stroke="color1" d="M624 262.992v-23.324c0-33.137-26.863-60-60-60H320"/>
      <path :stroke="color2" d="M103.048 346.316h435.231"/>
    </g>

    <!-- Tunnel -->
    <g fill-rule="evenodd" clip-path="url(#dwm-tunnel-clip)" clip-rule="evenodd">
      <path fill="#E5E6EB" d="M332 723.241v-39.974c15.668 2.725 27 10.643 27 19.987 0 9.343-11.332 17.262-27 19.987zm-.074-39.987a70.28 70.28 0 00-11.926-1c-4.16 0-8.167.351-11.926 1h23.852zm-23.926.013v39.974c-15.668-2.725-27-10.644-27-19.987 0-9.344 11.332-17.262 27-19.987z"/>
      <path :fill="maskColor" d="M336 722.411v26.843h-32v-26.843c4.882 1.184 10.298 1.843 16 1.843s11.118-.659 16-1.843z"/>
    </g>

    <!-- Dots -->
    <g>
      <circle cx="624" cy="263.375" r="12" :fill="color2"/>
      <circle cx="103" cy="346.375" r="12" :fill="color1"/>
    </g>

    <defs>
      <clipPath id="dwm-tunnel-clip">
        <rect width="78" height="66" fill="white" transform="matrix(1 0 0 -1 281 749.254)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    color1: String,
    color2: String,
    sectionBg: Boolean
  },

  data() {
    return {
      maskColor: ''
    }
  },

  created() {
    this.maskColor = this.sectionBg ? '#FAFAFB' : '#FFF';
  }
}
</script>

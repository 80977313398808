<template>
  <svg class="hidden xsm:block lg:hidden w-full" fill="none" viewBox="0 0 643 2486">
    <g>
      <!-- Routes -->
      <g stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
        <path stroke="#F4F5F6" d="M321 14v279c0 33.603 0 50.405-6.54 63.239a59.993 59.993 0 01-26.221 26.221C275.405 389 258.603 389 225 389H109c-33.603 0-50.405 0-63.24 6.54a59.996 59.996 0 00-26.22 26.221C13 434.595 13 451.397 13 485v485c0 33.6 0 50.4 6.54 63.24a59.984 59.984 0 0026.22 26.22C58.596 1066 75.398 1066 109 1066h425.5c33.603 0 50.405 0 63.239 6.54a59.98 59.98 0 0126.221 26.22c6.54 12.84 6.54 29.64 6.54 63.24v487.5c0 33.6 0 50.4-6.54 63.24a59.98 59.98 0 01-26.221 26.22c-12.834 6.54-29.636 6.54-63.239 6.54h-426c-33.603 0-50.405 0-63.24 6.54a59.984 59.984 0 00-26.22 26.22c-6.54 12.84-6.54 29.64-6.54 63.24V2200c0 33.6 0 50.4 6.54 63.24a59.984 59.984 0 0026.22 26.22c12.835 6.54 29.637 6.54 63.24 6.54H225c33.603 0 50.405 0 63.239 6.54a59.98 59.98 0 0126.221 26.22C321 2341.6 321 2358.4 321 2392v32"/>
        <path ref="Route06" data-route="-1" stroke="#FFD400" d="M321 2472v-80c0-33.6 0-50.4-6.54-63.24a59.98 59.98 0 00-26.221-26.22C275.405 2296 258.603 2296 225 2296h-49"/>
        <path ref="Route05" data-route="1" stroke="#FFD400" d="M321 1745.5H108.5c-33.603 0-50.405 0-63.24 6.54a59.984 59.984 0 00-26.22 26.22c-6.54 12.84-6.54 29.64-6.54 63.24V2200c0 33.6 0 50.4 6.54 63.24a59.984 59.984 0 0026.22 26.22c12.835 6.54 29.637 6.54 63.24 6.54H176"/>
        <path ref="Route04" data-route="1" stroke="#FF8B00" d="M630.5 1406v243.5c0 33.6 0 50.4-6.54 63.24a59.98 59.98 0 01-26.221 26.22c-12.834 6.54-29.636 6.54-63.239 6.54H321"/>
        <path ref="Route03" data-route="1" stroke="#FF8B00" d="M337 1066h197.5c33.603 0 50.405 0 63.239 6.54a59.98 59.98 0 0126.221 26.22c6.54 12.84 6.54 29.64 6.54 63.24v244"/>
        <path ref="Route02" data-route="1" stroke="#FFD400" d="M13 728v242c0 33.6 0 50.4 6.54 63.24a59.984 59.984 0 0026.22 26.22C58.596 1066 75.398 1066 109 1066h228"/>
        <path ref="Route01" data-route="1" stroke="#FFD400" d="M321 14v279c0 33.603 0 50.405-6.54 63.239a59.993 59.993 0 01-26.221 26.221C275.405 389 258.603 389 225 389H109c-33.603 0-50.405 0-63.24 6.54a59.996 59.996 0 00-26.22 26.221C13 434.595 13 451.397 13 485v243"/>
      </g>

      <!-- Routes -->
      <path ref="MovingDot" fill="#FF8B00" d="M13 740c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12s-12 5.372-12 12c0 6.627 5.373 12 12 12z"/>

      <!-- Tunnel Top -->
      <g ref="TunnelTop" fill-rule="evenodd" clip-path="url(#alm1)" clip-rule="evenodd">
        <path fill="#E5E6EB" d="M308.98 26.177v40.226c-15.766-2.743-27.17-10.71-27.17-20.113 0-9.402 11.404-17.37 27.17-20.113zm.072 40.238a70.677 70.677 0 0012.003 1.007 70.68 70.68 0 0012.004-1.007h-24.007zm24.079-.012c15.766-2.743 27.17-10.71 27.17-20.113 0-9.402-11.404-17.37-27.17-20.113v40.226z"/>
        <path fill="#FAFAFB" d="M304.955 27.012V0h32.201v27.012c-4.912-1.192-10.363-1.855-16.1-1.855-5.738 0-11.188.663-16.101 1.855z"/>
      </g>

      <!-- Tunnel Bottom -->
      <g ref="TunnelBottom" fill-rule="evenodd" clip-path="url(#alm2)" clip-rule="evenodd">
        <path fill="#E5E6EB" d="M333.133 2459.82v-40.22c15.766 2.74 27.17 10.71 27.17 20.11 0 9.4-11.404 17.37-27.17 20.11zm-.071-40.24c-3.784-.65-7.817-1-12.004-1-4.187 0-8.221.35-12.004 1h24.008zm-24.08.02c-15.766 2.74-27.17 10.71-27.17 20.11 0 9.4 11.404 17.37 27.17 20.11v-40.22z"/>
        <path fill="#FAFAFB" d="M337.158 2458.99V2486h-32.201v-27.01c4.913 1.19 10.363 1.85 16.101 1.85 5.737 0 11.188-.66 16.1-1.85z"/>
      </g>
    </g>

    <defs>
      <clipPath id="alm1">
        <path fill="#fff" d="M0 0h78.491v66.415H0z" transform="matrix(-1 0 0 1 360.301 0)"/>
      </clipPath>
      <clipPath id="alm2">
        <path fill="#fff" d="M0 0h78.491v66.415H0z" transform="matrix(1 0 0 -1 281.812 2486)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';
import ExpoScaleEase from 'gsap/EasePack';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin, ExpoScaleEase);

export default {
  mixins: [vueGSAPMixin],

  data() {
    return {
      linesTimeline: null,
      linesScrollTrigger: null,
      linesLabelTimeline01: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetRoutes(this.$el);

      setTimeout(() => {
        this.animateLines();
      }, 1000)
    });
  },

  methods: {
    animateLines() {
      this.linesTimeline = gsap.timeline()
          .fromTo(this.$refs.MovingDot,
              0.1,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route01,
                  align: this.$refs.Route01,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0.1
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              0.1
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()} ${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route02,
                  align: this.$refs.Route02,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              1.1
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()} ${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.MovingDot,
              0.2,
              {
                fill: '#FFD400',
                duration: 1,
                ease: 'none'
              },
              2.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route03,
                  align: this.$refs.Route03,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              2.1
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()} ${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route04,
                  align: this.$refs.Route04,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              3.1
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength()}`,
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength()} ${this.$refs.Route04.getTotalLength()}`,
                ease: 'none',
              },
              4.1
          )
          .to(this.$refs.MovingDot,
              0.2,
              {
                fill: '#FF8B00',
                duration: 1,
                ease: 'none'
              },
              4.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route05,
                  align: this.$refs.Route05,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              4.1
          )
          .to(this.$refs.Route05,
              1,
              {
                drawSVG: `${this.$refs.Route05.getTotalLength()}`,
                ease: 'none',
              },
              4.1
          )
          .to(this.$refs.Route05,
              1,
              {
                drawSVG: `${this.$refs.Route05.getTotalLength()} ${this.$refs.Route05.getTotalLength()}`,
                ease: 'none',
              },
              5.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route06,
                  align: this.$refs.Route06,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              5.1
          )
          .to(this.$refs.Route06,
              1,
              {
                drawSVG: `${this.$refs.Route06.getTotalLength()}`,
                ease: 'none',
              },
              5.1
          )

      ScrollTrigger.matchMedia({
        "(max-width: 959.9px)": () => {
          ScrollTrigger.create({
            trigger: this.$refs.TunnelTop,
            start: 'top bottom-=20%',
            endTrigger: this.$refs.TunnelBottom,
            end: 'bottom center',
            scrub: 0.6,
            animation: this.linesTimeline,
          });
        },
      });
    },
  }
}
</script>


import Vue from 'vue'
import VueGlobalVar from 'vue-global-var'

Vue.use(VueGlobalVar, {
    globals: {
        mediaQueries: {
            'xsm': window.matchMedia('(min-width: 375px)'),
            'sm': window.matchMedia('(min-width: 640px)'),
            'md': window.matchMedia('(min-width: 768px)'),
            'lg': window.matchMedia('(min-width: 960px)'),
            'xl': window.matchMedia('(min-width: 1080px)'),
            '2xl': window.matchMedia('(min-width: 1280px)'),
            '3xl': window.matchMedia('(min-width: 1380px)'),
            '4xl': window.matchMedia('(min-width: 1690px)'),
            'hd': window.matchMedia('(min-width: 1920px)'),
            '5xl': window.matchMedia('(min-width: 2060px)'),
            '6xl': window.matchMedia('(min-width: 2400px)'),
        }
    },
});

Vue.config.productionTip = false

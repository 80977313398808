<template>
  <svg class="hidden lg:block w-full" fill="none" viewBox="0 0 1920 4454">
    <drivers-how-it-works-line-02 :color1="color2" :color2="color1"></drivers-how-it-works-line-02>
    <drivers-how-it-works-line-03 :color1="color1" :color2="color2"></drivers-how-it-works-line-03>
    <drivers-how-it-works-line-01 :color1="color1" :color2="color2"></drivers-how-it-works-line-01>
  </svg>
</template>

<script>
export default {
  props: {
    color1: String,
    color2: String,
  }
}
</script>


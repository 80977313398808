<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(ScrollTrigger, CSSPlugin);

export default {
  mixins: [vueGSAPMixin],

  data() {
    return {
      resizeTimer: null,
      introTimeline: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.setOffset();
      this.createTimelines();

      window.addEventListener('resize', () => {
        clearTimeout(this.resizeTimer);

        this.resizeTimer = setTimeout(() => {
          if (this.mediaQueries['lg'].matches) {
            this.setOffset();
          }
        }, 60);
      });


      this.mediaQueries['lg'].addListener(() => {
        this.resetTimelines();
        this.createTimelines();
        this.playTimelines();
      });

      setTimeout(() => {
        this.playTimelines();
      }, 1000);
    });
  },

  methods: {
    createTimelines() {
      if (this.mediaQueries['lg'].matches) {
        this.introAnimations(0);
      } else {
        this.removeOffset();
        this.introAnimations(.3);
      }
    },

    playTimelines() {
      if (this.introTimeline) {
        this.introTimeline.play()
      }
    },

    resetTimelines() {
      this.introTimeline && this.introTimeline.kill();
      this.introTimeline = null;
    },

    introAnimations(position) {
      const el = document.querySelector('[data-hero-image]')
      if (!el) {
        return
      }

      let duration = .9;
      let opacity = 1;
      if (!this.mediaQueries['lg'].matches) {
        duration = .5;
        opacity = 0;
      }

      this.introTimeline = gsap.timeline({paused: true})
          .fromTo('[data-hero-image]',
              duration,
              {
                opacity: opacity,
                yPercent: 20,
                ease: 'power2',
              },
              {
                opacity: 1,
                yPercent: 0,
                ease: 'power2',
              },
              position
          );
    },

    setOffset() {
      /**
       * Hero section is 100vh and the SVG is always centered with it's width in %.
       * Set a margin from the next section if the SVG is higher than the Hero section so it doesn't overlap
       */
      const lines = document.getElementById('linesS01');

      if (lines.clientHeight > window.innerHeight) {
        const divider = window.innerWidth > 2060 ? 1.8 : 2;
        this.$el.setAttribute('style', `margin-bottom: ${(lines.clientHeight - window.innerHeight) / divider}px;`);
      }
    },

    removeOffset() {
      this.$el.removeAttribute('style');
    },
  }
}
</script>

<script>
export default {
  data() {
    return {
      title: '',
      qualities: '',
      location: '',
      jobs: []
    }
  },

  watch: {
    title() {
      if (this.title === null) {
        this.title = "";
      }
    },
    qualities() {
      if (this.qualities === null) {
        this.qualities = "";
      }
    },
    location() {
      if (this.location === null) {
        this.location = "";
      }
    }
  },

  computed: {
    filterJobs() {
      return this.filterJobsByLocation(this.filterJobsByTitle(this.filterJobsByQualities(this.jobs)))
    }
  },

  methods: {
    filterJobsByQualities(jobs) {
      return jobs.filter(job => !job.qualities.indexOf(this.qualities))
    },

    filterJobsByTitle(jobs) {
      return jobs.filter(job => !job.title.indexOf(this.title))
    },

    filterJobsByLocation(jobs){
      return jobs.filter(job => !job.location.indexOf(this.location))
    }
  },
}
</script>

<template>
  <div :data-parallax="parallax" class="flex flex-row justify-center lg:justify-start items-center" :class="classes">
    <div class="hidden lg:block w-1/12"></div>

    <div data-animation-box
         class="flex flex-col items-center
         w-full xsm:w-9/12 sm:w-10/12 lg:w-5/12 3xl:w-4/12
         py-6 xsm:py-8 sm:py-12 md:py-16 lg:p-0
         bg-white border border-gray-mid rounded rounded-4xl lg:rounded-none lg:border-none lg:bg-transparent
         text-center lg:text-right">

      <div class="w-5/12 mb-4 xsm:mb-4 sm:mb-8 md:mb-12 lg:hidden">
        <slot name="icon"></slot>
      </div>

      <div class="hidden lg:block w-full lg:mb-1 xl:mb-8 3xl:mb-10 5xl:mb-16">
        <slot name="subtitle"></slot>
      </div>

      <div class="inline-block w-8/12 md:w-7/12 lg:w-full mb-4 xsm:mb-4 sm:mb-8 md:mb-12 lg:mb-3 3xl:mb-4 4xl:mb-6 5xl:mb-8">
        <slot name="title"></slot>
      </div>

      <div class="w-9/12 xsm:w-10/12 sm:w-8/12 md:w-6/12 lg:w-full">
        <slot name="paragraph"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    parallax: Number,
    classes: String,
    title: String,
    paragraph: String,
    number: String
  }
}
</script>

<script>
// TODO convert to inline template ^^
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';
import ExpoScaleEase from 'gsap/EasePack';
import { mapMutations } from "vuex";

gsap.registerPlugin(ScrollTrigger, CSSPlugin, ExpoScaleEase);

export default {
  mixins: [vueGSAPMixin],

  props: {
    page: String,
    color: String,
    logoCorp: Boolean
  },

  data() {
    return {
      introTimeline: null,
      menuMagnets: null,
      pageMagnets: null,
      cursor: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.createTimelines();

      setTimeout(() => {
        this.playTimelines();

        this.mediaQueries['lg'].addListener(() => {
          this.resetTimelines();
          this.createTimelines();
          this.playTimelines();
        });
      }, 1000);
    });
  },

  mounted() {
    this.cursor = this.$root.$refs.cursor;
  },

  methods: {
    ...mapMutations([`toggleSideMenuState`, `openLoginForm`]),

    createTimelines() {
      this.introAnimations();

      if (this.mediaQueries['lg'].matches) {
        this.stickyHeader();
      } else {
        this.$el.classList.remove('header-sticky')
      }
    },

    playTimelines() {
      this.introTimeline.play();
    },

    resetTimelines() {
      this.introTimeline && this.introTimeline.kill();
      this.introTimeline = null;
    },

    introAnimations() {
      this.introTimeline = gsap.timeline(
          {
            paused: true,
            onComplete: () => {
              this.$el.classList.add('transition-all');
              this.$el.classList.add('duration-300');
            }
          })
          .fromTo(this.$el,
              1.4,
              {
                yPercent: -120,
              },
              {
                yPercent: 0,
                ease: 'power4',
              }
          );
    },

    stickyHeader() {
      const $this = this;

      ScrollTrigger.matchMedia({
        "(min-width: 960px)": function () {
          ScrollTrigger.create({
            trigger: '.section-hero',
            start: `bottom bottom-=5%`,
            toggleActions: 'play none none reverse',
            scrub: false,
            onEnter: () => {
              $this.$el.classList.add('header-sticky')
              setTimeout(() => {
                $this.cursor.headerMagnets.forEach(magnet => {
                  $this.cursor.reCalculateMagnetPosition(magnet)
                })
              },500)
            },
            onLeaveBack: () => {
              $this.$el.classList.remove('header-sticky')
              setTimeout(() => {
                $this.cursor.headerMagnets.forEach(magnet => {
                  $this.cursor.reCalculateMagnetPosition(magnet)
                })
              },500)
            },
          });
        },
      });
    },
  }
}
</script>

<template>
  <g id="LINE 3">
    <!-- Routes -->
    <g stroke="#FFD400" stroke-linecap="round" stroke-width="32">
      <path stroke="#F4F5F6" d="M960 3818v-156c0-55.23 44.77-100 100-100h372c55.23 0 100-44.77 100-100V2374c0-55.23-44.77-100-100-100h-372c-55.23 0-100 44.77-100 100v52c0 55.23-44.772 100-100 100H492c-55.228 0-100-44.77-100-100v-683"/>
      <path ref="Route05" data-route="-1" d="M960 3818v-156c0-55.23 44.77-100 100-100h186"/>
      <path ref="Route04" data-route="1" d="M1532 3063.75V3462c0 55.23-44.77 100-100 100h-186"/>
      <path ref="Route03" data-route="1" d="M1532 2565.5v498.25"/>
      <path ref="Route02" data-route="1" d="M676 2526h184c55.228 0 100-44.77 100-100v-52c0-55.23 44.77-100 100-100h372c55.23 0 100 44.77 100 100v191.5"/>
      <path ref="Route01" data-route="1" d="M392 1743v683c0 55.23 44.772 100 100 100h184"/>
    </g>

    <!-- Dots -->
    <g>
      <path ref="MovingDot" fill="#FF8B00" d="M1246 3578c8.84 0 16-7.16 16-16s-7.16-16-16-16-16 7.16-16 16 7.16 16 16 16z"/>
      <path ref="Dot01" fill="#FF8B00" d="M1253 2290c8.84 0 16-7.16 16-16s-7.16-16-16-16-16 7.16-16 16 7.16 16 16 16z"/>
    </g>

    <!-- Label -->
    <g ref="Label">
      <path ref="LabelBg" fill="#FF8B00" d="M1206 2214.4c0-2.24 0-3.36.44-4.22.38-.75.99-1.36 1.74-1.74.86-.44 1.98-.44 4.22-.44h81.2c2.24 0 3.36 0 4.22.44.75.38 1.36.99 1.74 1.74.44.86.44 1.98.44 4.22v21.2c0 2.24 0 3.36-.44 4.22-.38.75-.99 1.36-1.74 1.74-.86.44-1.98.44-4.22.44h-32.11c-.82 0-1.23 0-1.61.08-.52.1-1.01.3-1.45.59-.32.22-.61.51-1.19 1.09-1.32 1.32-1.97 1.97-2.71 2.28-.98.4-2.08.4-3.06 0-.74-.31-1.39-.96-2.71-2.28-.58-.58-.87-.87-1.19-1.09-.44-.29-.93-.49-1.45-.59-.38-.08-.79-.08-1.61-.08h-32.11c-2.24 0-3.36 0-4.22-.44-.75-.38-1.36-.99-1.74-1.74-.44-.86-.44-1.98-.44-4.22v-21.2z"/>
      <text class="font-main-b text-16" fill="#ffffff" opacity=".85" transform="translate(1220 2214.5)">
        <tspan x=".43" y="17.2">PARADA 2</tspan>
      </text>
    </g>

    <!-- Icon -->
    <g data-parallax="12">
      <g data-animate="fade-up">
        <g filter="url(#filter0_d)">
          <circle cx="1532" cy="2971" r="196" fill="#EFC700"/>
        </g>
        <ellipse cx="1532" cy="2963" fill="#FFD400" rx="196" ry="188"/>
        <g stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="10">
          <path d="M1467.2 2978.36h129.6"/>
          <path d="M1467.2 2948.91l-16.2-36.82 81-22.09 32 9"/>
          <path d="M1467.2 2948.91l-16.2-36.82 81-22.09 81 22.09-6.55 17.89" opacity=".4"/>
          <path d="M1532 3052c-35.79 0-64.8-26.38-64.8-58.91v-44.18h129.6v44.18c0 32.53-29.01 58.91-64.8 58.91z"/>
        </g>
      </g>
    </g>

    <g ref="TunnelBottom">
      <path fill="#E5E6EB" d="M944 3801.64v-51.28c-20.329 3.55-35 13.69-35 25.64 0 11.95 14.671 22.09 35 25.64zm32 0v-51.28c20.329 3.55 35 13.69 35 25.64 0 11.95-14.671 22.09-35 25.64z"/>
      <path fill="#fff" d="M939 3800.61V3836h42v-35.39c-6.404 1.54-13.514 2.39-21 2.39-7.486 0-14.596-.85-21-2.39z"/>
    </g>
  </g>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';
import ExpoScaleEase from 'gsap/EasePack';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin, ExpoScaleEase);

export default {
  mixins: [vueGSAPMixin],

  data() {
    return {
      linesTimeline: null,
      linesScrollTrigger: null,
      linesLabelTimeline01: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetRoutes(this.$el);

      setTimeout(() => {
        this.animateLines();
      }, 1000)
    });
  },

  methods: {
    animateLines() {
      let direction = 1;

      this.linesLabelTimeline01 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot01,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot01,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label,
              0.3,
              {
                yPercent: -150,
                opacity: 0,
              },
              {
                yPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          );

      this.linesTimeline = gsap.timeline()
          .fromTo(this.$refs.MovingDot,
              0.1,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route01,
                  align: this.$refs.Route01,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0.1
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              0.1
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()} ${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route02,
                  align: this.$refs.Route02,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              1.1
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline01.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline01.reverse();
                }
              },
              1.6
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()} ${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route03,
                  align: this.$refs.Route03,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              2.1
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.LabelBg,
              0.2,
              {
                fill: '#CCCED7',
                ease: 'none',
              },
              2.7
          )
          .to(this.$refs.Dot01,
              0.2,
              {
                fill: '#CCCED7',
                ease: 'none',
              },
              2.7
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()} ${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route04,
                  align: this.$refs.Route04,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              3.1
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength()}`,
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength()} ${this.$refs.Route04.getTotalLength()}`,
                ease: 'none',
              },
              4.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route05,
                  align: this.$refs.Route05,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              4.1
          )
          .to(this.$refs.Route05,
              1,
              {
                drawSVG: `${this.$refs.Route05.getTotalLength()}`,
                ease: 'none',
              },
              4.1
          )
          .to(this.$refs.Route05,
              1,
              {
                drawSVG: `${this.$refs.Route05.getTotalLength() *-1}`,
                ease: 'none',
              },
              5.1
          )
          .to(this.$refs.MovingDot,
              {
                scale: 0,
                duration: 1,
                ease: 'none'
              },
              5.1
          )

      ScrollTrigger.matchMedia({
        "(min-width: 960px)": () => {
          ScrollTrigger.create({
            trigger: this.$refs.Route01,
            start: 'top center',
            endTrigger: this.$refs.TunnelBottom,
            end: 'bottom center+=12%',
            scrub: 0.6,
            animation: this.linesTimeline,
            onUpdate: self => {
              if (self.direction === direction) {
                return;
              }
              direction = self.direction;
            },
          });
        },
      });
    },
  }
}
</script>


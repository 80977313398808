<template>
  <div>
    <div ref="menuBackdrop"
         @click="isRegisterComplete ? goToCorporatePage() : toggleSideMenuState()"
         class="fixed z-60 top-0 left-0 w-screen h-screen bg-gray-bright opacity-0 pointer-events-none">
    </div>

    <div ref="menu"
         class="menu-fixed z-80 fixed top-0 right-0 h-full w-full overflow-auto"
         :class="[isLoginFormOpen ? `text-white bg-${color}` : '', isRegisterComplete ? 'text-white bg-blue-dark' : '', !isLoginFormOpen && !isRegisterComplete ? 'text-dark bg-white' : ''] "
    >

      <!-- Menu -->
      <div v-show="!isLoginFormOpen && !isRegisterComplete" data-cursor-zone="diff"
           class="container flex flex-col justify-between items-start h-auto md:h-full lg:max-w-full px-4 lg:px-12 xl:px-16 4xl:px-20 5xl:px-24">

        <!-- Menu Header -->
        <div class="flex items-center justify-between w-full pt-8 sm:pt-10 md:pt-12">
          <a href="/"
             data-magnet="side-menu"
             data-magnet-type="fixed"
             class="logo inline-block text-black">
            <svg class="h-full" viewBox="0 0 161 53">
              <path class="fill-current"
                    d="M42.981 22.43C42.981 10.595 33.365 1 21.504 1 9.642 1 .026 10.595.026 22.43L0 46.508C0 50.093 2.913 53 6.507 53c3.593 0 6.507-2.907 6.507-6.492 0 0-.006-16.751.003-24.078.006-4.677 3.8-8.468 8.487-8.468s8.48 3.791 8.486 8.468c.01 7.327.004 24.077.004 24.077 0 3.578 2.907 6.478 6.493 6.478 3.585 0 6.492-2.9 6.492-6.478.04-7.519.013-16.558.002-24.077z"/>
              <path class="fill-current"
                    d="M64.982 22.683C64.982 10.939 56.01-3.37 35 2.267c4.328 2.212 8.433 6.462 9.92 12.097 4.143.506 7.235 4.025 7.24 8.319.009 7.27.003 23.889.003 23.889 0 3.55 2.87 6.428 6.409 6.428 3.539 0 6.408-2.878 6.408-6.428.038-7.46.012-16.428.002-23.889z"/>
              <path class="fill-current"
                    d="M69.019 30.57c0 11.835 9.616 21.43 21.477 21.43 11.862 0 21.478-9.595 21.478-21.43L112 6.492C112 2.907 109.087 0 105.493 0c-3.593 0-6.506 2.907-6.506 6.492 0 0 .005 16.75-.004 24.078-.006 4.676-3.8 8.467-8.487 8.467s-8.48-3.791-8.486-8.467c-.01-7.327-.004-24.077-.004-24.077 0-3.578-2.907-6.479-6.493-6.479-3.585 0-6.492 2.901-6.492 6.479-.04 7.519-.013 16.558-.002 24.077z"/>
              <path class="fill-current"
                    d="M138.533 53h-.063c-3.222 0-5.855-2.097-6.968-4.927-1.152-2.933-3.717-8.419-5.399-12.633-3.25-8.124-6.47-17.368-9.684-25.502a6.597 6.597 0 01-.419-2.32C116 3.964 118.973 1 122.64 1a6.641 6.641 0 016.28 4.463l9.58 27.746c.264-.72 9.58-27.746 9.58-27.746A6.642 6.642 0 01154.36 1c3.667 0 6.64 2.963 6.64 6.619a6.58 6.58 0 01-.42 2.32c-3.214 8.133-6.434 17.377-9.683 25.5-1.681 4.215-4.246 9.701-5.4 12.634-1.112 2.83-3.745 4.927-6.967 4.927h-.062.065z"/>
            </svg>
          </a>

          <button
              class="focus:outline-none"
              data-magnet="side-menu"
              data-magnet-type="fixed"
              @click="toggleSideMenuState()">
            <svg class="w-8 xsm:w-10 4xl:w-12 5xl:w-14" fill="none" viewBox="0 0 56 56">
              <circle cx="28" cy="28" r="28" class="fill-current text-black"/>
              <g class="text-white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                <path class="stroke-current" d="M34.5 34.5L21 21"/>
                <path class="stroke-current" d="M21 34.5L34.5 21"/>
              </g>
            </svg>
          </button>
        </div>
        <!-- End of Menu Header -->

        <!-- Menu -->
        <nav class="menu-nav flex flex-wrap w-full items-start py-12 lg:py-10 3xl:py-14 px-10 sm:px-0">
          <div class="flex flex-col w-full sm:w-1/2 order-2 sm:order-1">

            <div class="flex flex-col mb-8 xsm:mb-10 sm:mb-8 lg:mb-4 xl:mb-5 3xl:mb-6 4xl:mb-8 5xl:mb-10">
              <span class="menu-nav-title">Empresas</span>

              <div data-cursor-large class="inline-flex flex-col max-w-content">
                <a href="/corp-action.html"
                   title="Ingresá a tu cuenta"
                   data-magnet="side-menu"
                   data-magnet-type="fixed"
                   class="menu-nav-item menu-nav-item-secondary">Registrate</a>

                <a href="https://muv-app.co/corp"
                   title="Ingresá a tu cuenta"
                   data-magnet="side-menu"
                   data-magnet-type="fixed"
                   class="menu-nav-item menu-nav-item-secondary">Ingresá a tu cuenta</a>
              </div>
            </div>

            <div class="flex flex-col mb-8 xsm:mb-10 sm:mb-8 lg:mb-4 xl:mb-5 3xl:mb-6 4xl:mb-8 5xl:mb-10">
              <span class="menu-nav-title">Conductores</span>

              <div data-cursor-large class="inline-flex flex-col max-w-content">
                <a href="https://muv-app.co/registro"
                   title="Registrate"
                   data-magnet="side-menu"
                   data-magnet-type="fixed"
                   class="menu-nav-item menu-nav-item-secondary">Registrate</a>
              </div>
            </div>

            <div class="flex flex-col mb-8 xsm:mb-10 sm:mb-8 lg:mb-4 xl:mb-5 3xl:mb-6 4xl:mb-8 5xl:mb-10">
              <span class="menu-nav-title">Otra</span>

              <div data-cursor-large class="inline-flex flex-col max-w-content">
                <a href="/contactos.html"
                   title="Contactános"
                   data-magnet="side-menu"
                   data-magnet-type="fixed"
                   class="menu-nav-item menu-nav-item-secondary" :class="[page === 'contactános' ? `text-${color}` : '']">Contactános</a>
                <a href="/sobre-nosotros.html"
                   title="Sobre nosotros"
                   data-magnet="side-menu"
                   data-magnet-type="fixed"
                   class="menu-nav-item menu-nav-item-secondary" :class="[page === 'sobre nosotros' ? `text-${color}` : '']">Sobre nosotros</a>
                <a href="/sumate-al-equipo.html"
                   title="Sumate al equipo"
                   data-magnet="side-menu"
                   data-magnet-type="fixed"
                   class="menu-nav-item menu-nav-item-secondary" :class="[page === 'Sumate al equipo' ? `text-${color}` : '']">Sumate al equipo</a>
                <a href="descargas.html"
                   title="Descargá la app"
                   data-magnet="side-menu"
                   data-magnet-type="fixed"
                   class="menu-nav-item menu-nav-item-secondary" :class="[page === 'descargas' ? `text-${color}` : '']">Descargá la app</a>
              </div>
            </div>

            <div class="flex flex-col">
              <span class="menu-nav-title">Encuéntranos en</span>

              <div data-cursor-large class="inline-flex flex-col max-w-content">
                <a href="https://www.facebook.com/app.muv"
                   target="_blank"
                   title="Facebook"
                   data-magnet="side-menu"
                   data-magnet-type="fixed"
                   class="menu-nav-item menu-nav-item-secondary">Facebook</a>
                <a href="https://twitter.com/app_muv"
                   target="_blank"
                   title="Twitter"
                   data-magnet="side-menu"
                   data-magnet-type="fixed"
                   class="menu-nav-item menu-nav-item-secondary">Twitter</a>
                <a href="https://www.instagram.com/app.muv/?hl=es-la"
                   target="_blank"
                   title="Instagram"
                   data-magnet="side-menu"
                   data-magnet-type="fixed"
                   class="menu-nav-item menu-nav-item-secondary">Instagram</a>
              </div>
            </div>
          </div>

          <div class="flex flex-col w-full sm:w-1/2 order-1 sm:order-2 mb-8 xsm:mb-10 sm:mb-0">
            <span class="menu-nav-title">Menu</span>

            <div data-cursor-large class="inline-flex flex-col max-w-content">
              <a href="/"
                 title="Conductores"
                 data-magnet="side-menu"
                 data-magnet-type="fixed"
                 class="menu-nav-item mr-6 2xl:mr-8" :class="[page === 'conductores' ? `text-${color}` : '']">Conductores</a>
              <a href="pasajeros.html"
                 title="Pasajeros"
                 data-magnet="side-menu"
                 data-magnet-type="fixed"
                 class="menu-nav-item mr-6 2xl:mr-8" :class="[page === 'pasajeros' ? `text-${color}` : '']">Pasajeros</a>
              <a href="empresas.html"
                 title="Empresas"
                 data-magnet="side-menu"
                 data-magnet-type="fixed"
                 class="menu-nav-item mr-6 2xl:mr-8" :class="[page === 'empresas' ? `text-${color}` : '']">Empresas</a>
              <a href="ayuda.html"
                 title="Ayuda"
                 data-magnet="side-menu"
                 data-magnet-type="fixed"
                 class="menu-nav-item" :class="[page === 'ayuda' ? `text-${color}` : '']">Ayuda</a>
            </div>
          </div>
        </nav>
        <!-- End of Menu -->

        <div class="w-full md:h-12 lg:h-24">&nbsp;</div>
      </div>
      <!-- End of Menu -->

      <!-- Login Form -->
      <div v-show="isLoginFormOpen"
           class="container flex flex-col justify-between items-start h-auto xsm:h-full lg:max-w-full px-4 lg:px-12 xl:px-16 4xl:px-20 5xl:px-24">

        <!-- Menu Header -->
        <div class="flex items-center justify-between w-full pt-8 sm:pt-10 md:pt-12">
          <a href="/"
             data-magnet="side-menu"
             data-magnet-type="fixed"
             class="logo inline-block text-white">
            <svg class="h-full" viewBox="0 0 161 53">
              <path class="fill-current"
                    d="M42.981 22.43C42.981 10.595 33.365 1 21.504 1 9.642 1 .026 10.595.026 22.43L0 46.508C0 50.093 2.913 53 6.507 53c3.593 0 6.507-2.907 6.507-6.492 0 0-.006-16.751.003-24.078.006-4.677 3.8-8.468 8.487-8.468s8.48 3.791 8.486 8.468c.01 7.327.004 24.077.004 24.077 0 3.578 2.907 6.478 6.493 6.478 3.585 0 6.492-2.9 6.492-6.478.04-7.519.013-16.558.002-24.077z"/>
              <path class="fill-current"
                    d="M64.982 22.683C64.982 10.939 56.01-3.37 35 2.267c4.328 2.212 8.433 6.462 9.92 12.097 4.143.506 7.235 4.025 7.24 8.319.009 7.27.003 23.889.003 23.889 0 3.55 2.87 6.428 6.409 6.428 3.539 0 6.408-2.878 6.408-6.428.038-7.46.012-16.428.002-23.889z"/>
              <path class="fill-current"
                    d="M69.019 30.57c0 11.835 9.616 21.43 21.477 21.43 11.862 0 21.478-9.595 21.478-21.43L112 6.492C112 2.907 109.087 0 105.493 0c-3.593 0-6.506 2.907-6.506 6.492 0 0 .005 16.75-.004 24.078-.006 4.676-3.8 8.467-8.487 8.467s-8.48-3.791-8.486-8.467c-.01-7.327-.004-24.077-.004-24.077 0-3.578-2.907-6.479-6.493-6.479-3.585 0-6.492 2.901-6.492 6.479-.04 7.519-.013 16.558-.002 24.077z"/>
              <path class="fill-current"
                    d="M138.533 53h-.063c-3.222 0-5.855-2.097-6.968-4.927-1.152-2.933-3.717-8.419-5.399-12.633-3.25-8.124-6.47-17.368-9.684-25.502a6.597 6.597 0 01-.419-2.32C116 3.964 118.973 1 122.64 1a6.641 6.641 0 016.28 4.463l9.58 27.746c.264-.72 9.58-27.746 9.58-27.746A6.642 6.642 0 01154.36 1c3.667 0 6.64 2.963 6.64 6.619a6.58 6.58 0 01-.42 2.32c-3.214 8.133-6.434 17.377-9.683 25.5-1.681 4.215-4.246 9.701-5.4 12.634-1.112 2.83-3.745 4.927-6.967 4.927h-.062.065z"/>
            </svg>
          </a>

          <button
              class="focus:outline-none"
              data-magnet="side-menu"
              data-magnet-type="fixed"
              @click="toggleSideMenuState()">
            <svg class="w-8 xsm:w-10 4xl:w-12 5xl:w-14" fill="none" viewBox="0 0 56 56">
              <circle cx="28" cy="28" r="28" class="fill-current text-white"/>
              <g class="stroke-current" :class="`text-${color}`" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                <path d="M34.5 34.5L21 21"/>
                <path d="M21 34.5L34.5 21"/>
              </g>
            </svg>
          </button>
        </div>
        <!-- End of Menu Header -->

        <div class="flex flex-col justify-between flex-1 w-full py-14 md:py-16 4xl:py-20">
          <!-- Login Form -->
          <form ref="form" @submit.prevent="sendForm()" action="" class="w-full mb-12 md:mb-14">
            <p v-if="!formSent">Esta página está en construcción. Dejanos tu email y te avisaremos cuando esté lista.</p>
            <p v-else>Genial, te avisaremos cuando esté lista</p>

            <div class="relative w-full mt-12 md:mt-14 p-small">
              <input
                  ref="emailInput"
                  type="text"
                  :placeholder="[formSent ? '' : 'Ingrese correo electrónico']"
                  v-model="email"
                  :disabled="formSent"
                  :class="[email ? 'text-gray-darker font-main-r' : 'text-gray-dark font-main-l']"
                  class="w-full py-4 sm:py-5 lg:py-4 3xl:py-5 5xl:py-6 px-5 sm:px-6 lg:px-5 3xl:px-6 rounded-xl xl:rounded-2xl leading-normal bg-white focus:outline-none focus:text-gray-darker focus:font-main-r transition-colors duration-200"
              >

              <button type="submit" class="absolute inline-block right-0 center-y mr-6 focus:outline-none cursor-pointer">
                <svg class="btn-icon" :class="`text-${color}`" fill="none" viewBox="0 0 16 17">
                  <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                    <path class="stroke-current" d="M14.286 8.501H1.715"/>
                    <path class="stroke-current" d="M9.715 3.93L14.286 8.5l-4.571 4.572"/>
                  </g>
                </svg>
              </button>

              <div v-if="formSent" @click="toggleSideMenuState()" :class="`text-${color}`" class="absolute top-0 left-0 z-1 w-full h-full flex items-center pl-6 pr-12 cursor-pointer">
                Volver a página incial
              </div>
            </div>

            <div v-if="this.errorEmail" class="mt-2 text-center font-main-l p-small opacity-50">Favor ingresar un correo electrónico válido.</div>
          </form>
          <!-- End of Login Form -->

          <div class="w-full text-center">
            <a title="¿Necesitas ayuda?" href="/ayuda.html" class="btn rounded-full bg-white" :class="`text-${color}`">
              <span>¿Necesitas ayuda?</span>
              <svg class="btn-icon" :class="`text-${color}`" fill="none" viewBox="0 0 16 17">
                <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                  <path class="stroke-current" d="M14.286 8.501H1.715"/>
                  <path class="stroke-current" d="M9.715 3.93L14.286 8.5l-4.571 4.572"/>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <!-- End of Login Form -->

      <!-- Registration success -->
      <div v-show="isRegisterComplete"
           class="container flex flex-col justify-between items-start h-auto xsm:h-full lg:max-w-full px-4 lg:px-12 xl:px-16 4xl:px-20 5xl:px-24">

        <!-- Menu Header -->
        <div class="flex items-center justify-between w-full pt-8 sm:pt-10 md:pt-12">
          <a href="/"
             data-magnet="side-menu"
             data-magnet-type="fixed"
             class="logo-corporate inline-block text-white">
            <svg class="h-full" fill="none" viewBox="0 0 136 45">
              <g class="fill-current" fill-rule="evenodd" clip-rule="evenodd">
                <path d="M40.19 25.791c0-7.44-5.61-16.507-18.748-12.935 2.707 1.401 5.273 4.094 6.203 7.664 2.59.32 4.524 2.55 4.527 5.271.006 4.606.002 15.136.002 15.136 0 2.25 1.794 4.073 4.007 4.073 2.213 0 4.007-1.824 4.007-4.073.024-4.727.008-10.409.001-15.136z"/>
                <path d="M26.525 25.782c0-7.44-5.934-13.472-13.254-13.472C5.95 12.31.015 18.34.015 25.782L0 40.918C0 43.172 1.798 45 4.016 45c2.217 0 4.015-1.828 4.015-4.082 0 0-.004-10.53.002-15.136.004-2.94 2.345-5.324 5.238-5.324 2.892 0 5.233 2.384 5.237 5.324.006 4.606.002 15.136.002 15.136 0 2.249 1.794 4.072 4.007 4.072 2.213 0 4.007-1.823 4.007-4.072.024-4.727.007-10.41.001-15.136z"/>
                <path d="M42.764 31.4c0 7.511 5.991 13.6 13.38 13.6 7.39 0 13.38-6.089 13.38-13.6l.017-15.28c0-2.275-1.815-4.12-4.054-4.12-2.238 0-4.053 1.845-4.053 4.12 0 0 .003 10.63-.002 15.28-.004 2.968-2.367 5.374-5.288 5.374-2.92 0-5.282-2.406-5.286-5.374-.006-4.65-.002-15.28-.002-15.28 0-2.27-1.812-4.11-4.046-4.11-2.233 0-4.044 1.84-4.044 4.11-.024 4.772-.008 10.508-.002 15.28z"/>
                <path d="M86.066 45h-.039c-1.998 0-3.631-1.327-4.321-3.116-.715-1.855-2.306-5.324-3.348-7.99-2.016-5.138-4.013-10.983-6.007-16.128a4.246 4.246 0 01-.26-1.467c0-2.311 1.845-4.186 4.12-4.186 1.804 0 3.338 1.18 3.894 2.823l5.94 17.547c.165-.455 5.942-17.547 5.942-17.547.556-1.643 2.09-2.823 3.895-2.823 2.274 0 4.118 1.875 4.118 4.186 0 .516-.091 1.01-.26 1.467-1.993 5.145-3.99 10.99-6.005 16.128-1.043 2.666-2.634 6.135-3.35 7.99-.689 1.79-2.322 3.116-4.32 3.116h-.039.04z"/>
              </g>
              <mask id="mask-logo-corp-side" width="31" height="31" x="105" y="0" maskUnits="userSpaceOnUse">
                <path fill="#fff" fill-rule="evenodd" d="M105 0h30.698v30.644H105V0z" clip-rule="evenodd"/>
              </mask>
              <g class="fill-current" fill-rule="evenodd" clip-rule="evenodd" mask="url(#mask-logo-corp-side)">
                <path d="M129.858 16.708h-.83V20.9h-1.756V9.744h2.586c1.756 0 2.618.972 2.618 2.757v1.45c0 1.785-.862 2.757-2.618 2.757zm-5.333 4.191c-.096-.286-.16-.462-.16-1.37v-1.753c0-1.036-.351-1.418-1.149-1.418h-.607v4.541h-1.757V9.744h2.651c1.82 0 2.602.845 2.602 2.566v.876c0 1.148-.367 1.881-1.15 2.247v.033c.878.366 1.166 1.194 1.166 2.358v1.72c0 .543.016.941.192 1.355h-1.788zm-4.807-2.677c0 1.785-.942 2.805-2.666 2.805s-2.666-1.02-2.666-2.805v-5.8c0-1.786.942-2.805 2.666-2.805s2.666 1.02 2.666 2.805v5.8zm-6.259-4.717h-1.66V12.31c0-.797-.352-1.1-.91-1.1-.559 0-.91.303-.91 1.1v6.024c0 .797.351 1.083.91 1.083.558 0 .91-.286.91-1.083V16.74h1.66v1.482c0 1.785-.894 2.805-2.618 2.805-1.725 0-2.618-1.02-2.618-2.805v-5.8c0-1.786.893-2.805 2.618-2.805 1.724 0 2.618 1.02 2.618 2.805v1.083zM120.35 0C111.872 0 105 6.86 105 15.322c0 8.461 6.872 15.322 15.35 15.322 8.477 0 15.349-6.861 15.349-15.322C135.699 6.86 128.827 0 120.35 0z"/>
                <path d="M117.032 11.224c-.558 0-.91.302-.91 1.1v6.026c0 .797.352 1.1.91 1.1.559 0 .91-.303.91-1.1v-6.027c0-.797-.351-1.1-.91-1.1z"/>
                <path d="M123.445 11.291h-.852v3.439h.691c.659 0 1.061-.288 1.061-1.184v-1.103c0-.8-.273-1.152-.9-1.152z"/>
                <path d="M129.89 11.358h-.827v3.777h.827c.557 0 .859-.255.859-1.052V12.41c0-.796-.302-1.052-.859-1.052z"/>
              </g>
            </svg>
          </a>

          <button
              class="focus:outline-none"
              data-magnet="side-menu"
              data-magnet-type="fixed"
              @click="goToCorporatePage()">
            <svg class="w-8 xsm:w-10 4xl:w-12 5xl:w-14" fill="none" viewBox="0 0 56 56">
              <circle cx="28" cy="28" r="28" class="fill-current text-white"/>
              <g class="text-blue-dark" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                <path class="stroke-current" d="M34.5 34.5L21 21"/>
                <path class="stroke-current" d="M21 34.5L34.5 21"/>
              </g>
            </svg>
          </button>
        </div>
        <!-- End of Menu Header -->

        <div class="flex-1 w-full py-14 md:py-16 4xl:py-20">
            <p>¡Muchas gracias! Le estaremos contactando para brindarle más información.</p>
        </div>
      </div>
      <!-- End of Registration success -->
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin';
import ExpoScaleEase from 'gsap/EasePack';
import {mapState, mapMutations} from 'vuex';
import axios from "axios";

gsap.registerPlugin(CSSPlugin, ExpoScaleEase);

export default {
  props: {
    page: String,
    color: String,
  },

  data() {
    return {
      activeClass: null,
      cursor: null,
      formSent: false,
      email: null,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      errorEmail: null,
      postUrl: '/send.php',
    }
  },

  computed: {
    ...mapState({
      isMenuOpen: `isMenuOpen`,
      isLoginFormOpen: `isLoginFormOpen`,
      isRegisterComplete: `isRegisterComplete`,
    }),
  },

  watch: {
    isMenuOpen() {
      this.toggleMenu()
    },
    isLoginFormOpen() {
      this.formSent = false
      this.resetForm()
      this.cursor.menuMagnets.forEach(magnet => {
        this.cursor.reCalculateMagnetPosition(magnet);
      })
    },
  },

  mounted() {
    gsap.set(this.$refs.menu, {xPercent: 100})
    this.cursor = this.$root.$refs.cursor;
  },

  destroyed() {
      this.resetRegisterComplete()
  },

  methods: {
    ...mapMutations([`toggleSideMenuState`, `openLoginForm`, `closeLoginForm`, `resetRegisterComplete`]),

    toggleMenu() {
      gsap.to(this.$refs.menu,
          {
            duration: .3,
            xPercent: this.isMenuOpen ? 0 : 100,
            ease: 'expoScale(0.1, 5, power4.inOut)',
            onStart: () => {
              this.$root.$refs.scrollbar.classList.remove('hidden')
            },
            onComplete: () => {
              this.cursor.menuMagnets.forEach(magnet => {
                this.cursor.reCalculateMagnetPosition(magnet);
              })

              this.cursor.magnetObjects.forEach(magnet => {
                this.cursor.stopMagnet(magnet);
              })

              if (this.isMenuOpen) {
                this.cursor.menuMagnets.forEach(magnet => {
                  this.cursor.startMagnet(magnet);
                })
                if (!this.mediaQueries['lg'].matches) {
                  this.$root.$refs.scrollbar.classList.add('hidden')
                }
                document.addEventListener('keydown', this.closeMenuWithEscapeKey);
              } else {
                this.closeLoginForm()

                this.cursor.pageMagnets.forEach(magnet => {
                  this.cursor.startMagnet(magnet);
                })
                this.cursor.headerMagnets.forEach(magnet => {
                  this.cursor.startMagnet(magnet);
                })
                document.removeEventListener('keydown', this.closeMenuWithEscapeKey);
              }
            }
          }
      )

      gsap.to(this.$refs.menuBackdrop,
          {
            duration: .3,
            opacity: this.isMenuOpen ? .8 : 0,
            ease: 'expoScale(0.1, 5, power4.inOut)',
            onComplete: () => {
              this.$refs.menuBackdrop.classList.toggle('pointer-events-none');
            }
          }
      )
    },

    sendForm() {
      if (this.formSent) {
        return
      }

      if(this.email == null || this.email === '' || !this.reg.test(this.email))
      {
        this.errorEmail = true
        return
      }

      axios.post(this.postUrl, {
        mail: this.email,
      }).then(_ => {
        this.formSent = true
        this.resetForm()

      }).catch(error => {
        console.log(error);
      })
    },

    resetForm() {
      this.errorEmail = false
      this.email = null
    },

    goToCorporatePage() {
      document.location.href = '/empresas.html'
    },

    closeMenuWithEscapeKey(evt) {
      evt = evt || window.event
      let isEscape
      if ('key' in evt) {
        isEscape = (evt.key === 'Escape' || evt.key === 'Esc')
      } else {
        isEscape = (evt.keyCode === 27)
      }
      if (isEscape && this.isMenuOpen) {
        this.toggleSideMenuState()
      }
    }
  }
}
</script>

<template>
  <svg class="block lg:hidden w-full transform -scale-x-1" viewBox="0 0 639 3381">
    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
      <g transform="translate(-80 -1913)">
        <g transform="translate(80 1913)">
          <path id="linesS03MGrayPath" stroke="#F4F5F6" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" d="M320 12v42c0 33.137 26.863 60 60 60h187c33.137 0 60 26.863 60 60v469.828c0 33.137-26.863 60-60 60H72c-33.137 0-60 26.863-60 60V824c0 33.137 26.863 60 60 60h495c33.137 0 60 26.863 60 60v697c0 33.137-26.863 60-60 60H72c-33.137 0-60 26.863-60 60v60c0 33.137 26.863 60 60 60h495c33.137 0 60 26.863 60 60v60c0 33.137-26.863 60-60 60H72c-33.137 0-60 26.863-60 60v611c0 33.137 26.863 60 60 60h495c33.137 0 60 26.863 60 60v60c0 33.137-26.863 60-60 60H72c-33.137 0-60 26.863-60 60v60c0 33.137 26.863 60 60 60h188c33.137 0 60 26.863 60 60v157h0"/>
          <path id="linesS03MRoute01" data-route="1" :stroke="color1" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" d="M320 12v42c0 33.137 26.863 60 60 60h187c33.137 0 60 26.863 60 60v61h0"/>
          <path id="linesS03MRoute02" data-route="1" :stroke="color1" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" d="M627 235v409c0 33.137-26.863 60-60 60h-16.5 0"/>
          <path id="linesS03MRoute03" data-route="1" :stroke="color1" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" d="M550.5 704H72c-33.137 0-60 26.863-60 60v29.5h0"/>
          <path id="linesS03MRoute04" data-route="1" :stroke="color1" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" d="M12 793.5V824c0 33.137 26.863 60 60 60h478.5"/>
          <path id="linesS03MRoute05" data-route="1" :stroke="color1" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" d="M550.5 884H567c33.137 0 60 26.863 60 60v336h0"/>

          <path id="linesS03MRoute06" data-route="1" :stroke="color2" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" d="M627 1280v361c0 33.137-26.863 60-60 60h-16.5 0"/>
          <path id="linesS03MRoute07" data-route="1" :stroke="color2" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" d="M550.5 1701H72c-33.137 0-60 26.863-60 60v30.5h0"/>
          <path id="linesS03MRoute08" data-route="1" :stroke="color2" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" d="M12 1791.5v29.5c0 33.137 26.863 60 60 60h478.5"/>
          <path id="linesS03MRoute09" data-route="1" :stroke="color2" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" d="M550.5 1881H567c33.137 0 60 26.863 60 60v60c0 33.137-26.863 60-60 60H98h0"/>
          <path id="linesS03MRoute10" data-route="1" :stroke="color2" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" d="M98 2061H72c-33.137 0-60 26.863-60 60v300h0"/>

          <path id="linesS03MRoute11" data-route="1" :stroke="color1" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" d="M12 2421v311c0 33.137 26.863 60 60 60h26"/>
          <path id="linesS03MRoute12" data-route="1" :stroke="color1" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" d="M98 2792h469c33.137 0 60 26.863 60 60v60c0 33.137-26.863 60-60 60h-16.5 0"/>
          <path id="linesS03MRoute13" data-route="1" :stroke="color1" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" d="M550.5 2972H72c-33.137 0-60 26.863-60 60v23h0"/>
          <path id="linesS03MRoute14" data-route="1" :stroke="color1" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" d="M12 3055v37c0 33.137 26.863 60 60 60h188c33.137 0 60 26.863 60 60v157h0"/>

          <path id="linesS03MMovingDot" :fill="color2" d="M320 24c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12z"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin);

export default {
  mixins: [vueGSAPMixin],

  props: {
    color1: String,
    color2: String,
  },

  data() {
    return {
      linesSTMobile: null,
      linesTimelineMobile: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetTimelines();

      setTimeout(() => {
        this.createTimelines();
      }, 1000)

      this.mediaQueries['lg'].addListener(() => {
        this.resetTimelines();
        this.createTimelines();
      });
    });
  },

  methods: {
    createTimelines() {
      if (!this.mediaQueries['lg'].matches) {
        this.linesAnimationsMobile();
      }
    },

    resetTimelines() {
      this.linesTimelineMobile && this.linesTimelineMobile.kill();
      this.linesSTMobile && this.linesSTMobile.kill();
      this.linesTimelineMobile = null;
      this.linesSTMobile = null;

      this.resetRoutes(this.$el);
    },

    linesAnimationsMobile() {
      const route01 = document.getElementById('linesS03MRoute01');
      const route01DashOffset = route01.getTotalLength();

      const route02 = document.getElementById('linesS03MRoute02');
      const route02DashOffset = route02.getTotalLength();

      const route03 = document.getElementById('linesS03MRoute03');
      const route03DashOffset = route03.getTotalLength();

      const route04 = document.getElementById('linesS03MRoute04');
      const route04DashOffset = route04.getTotalLength();

      const route05 = document.getElementById('linesS03MRoute05');
      const route05DashOffset = route05.getTotalLength();

      const route06 = document.getElementById('linesS03MRoute06');
      const route06DashOffset = route06.getTotalLength();

      const route07 = document.getElementById('linesS03MRoute07');
      const route07DashOffset = route07.getTotalLength();

      const route08 = document.getElementById('linesS03MRoute08');
      const route08DashOffset = route08.getTotalLength();

      const route09 = document.getElementById('linesS03MRoute09');
      const route09DashOffset = route09.getTotalLength();

      const route10 = document.getElementById('linesS03MRoute10');
      const route10DashOffset = route10.getTotalLength();

      const route11 = document.getElementById('linesS03MRoute11');
      const route11DashOffset = route11.getTotalLength();

      const route12 = document.getElementById('linesS03MRoute12');
      const route12DashOffset = route12.getTotalLength();

      const route13 = document.getElementById('linesS03MRoute13');
      const route13DashOffset = route13.getTotalLength();

      const route14 = document.getElementById('linesS03MRoute14');
      const route14DashOffset = route14.getTotalLength();

      const movingDot = document.getElementById('linesS03MMovingDot');

      this.linesTimelineMobile = gsap.timeline()
          .fromTo(movingDot,
              0.1,
              {
                scale: 0,
                transformOrigin: 'center center',
              },
              {
                scale: 1,
                ease: 'none'
              },
              0
          )
          .to(movingDot,
              {
                motionPath: {
                  path: route01,
                  align: route01,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0.1
          )
          .to(route01,
              1,
              {
                drawSVG: `${route01DashOffset}`,
                ease: 'none',
              },
              0.1
          )
          .to(route01,
              1,
              {
                drawSVG: `${route01DashOffset} ${route01DashOffset}`,
                ease: 'none',
              },
              1.1
          )
          .to(movingDot,
              {
                motionPath: {
                  path: route02,
                  align: route02,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              1.1
          )
          .to(route02,
              1,
              {
                drawSVG: `${route02DashOffset}`,
                ease: 'none',
              },
              1.1
          )
          .to(route02,
              1,
              {
                drawSVG: `${route02DashOffset} ${route02DashOffset}`,
                ease: 'none',
              },
              2.1
          )
          .to(movingDot,
              {
                motionPath: {
                  path: route03,
                  align: route03,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              2.1
          )
          .to(route03,
              1,
              {
                drawSVG: `${route03DashOffset}`,
                ease: 'none',
              },
              2.1
          )
          .to(route03,
              1,
              {
                drawSVG: `${route03DashOffset} ${route03DashOffset}`,
                ease: 'none',
              },
              3.1
          )
          .to(movingDot,
              {
                motionPath: {
                  path: route04,
                  align: route04,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              3.1
          )
          .to(route04,
              1,
              {
                drawSVG: `${route04DashOffset}`,
                ease: 'none',
              },
              3.1
          )
          .to(route04,
              1,
              {
                drawSVG: `${route04DashOffset} ${route04DashOffset}`,
                ease: 'none',
              },
              4.1
          )
          .to(movingDot,
              {
                motionPath: {
                  path: route05,
                  align: route05,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              4.1
          )
          .to(route05,
              1,
              {
                drawSVG: `${route05DashOffset}`,
                ease: 'none',
              },
              4.1
          )
          .to(movingDot,
              0.1,
              {
                fill: this.color1,
                ease: 'none',
              },
              5.1
          )
          .to(route05,
              1,
              {
                drawSVG: `${route05DashOffset} ${route05DashOffset}`,
                ease: 'none',
              },
              5.1
          )
          .to(movingDot,
              {
                motionPath: {
                  path: route06,
                  align: route06,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              5.1
          )
          .to(route06,
              1,
              {
                drawSVG: `${route06DashOffset}`,
                ease: 'none',
              },
              5.1
          )
          .to(route06,
              1,
              {
                drawSVG: `${route06DashOffset} ${route06DashOffset}`,
                ease: 'none',
              },
              6.1
          )
          .to(movingDot,
              {
                motionPath: {
                  path: route07,
                  align: route07,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              6.1
          )
          .to(route07,
              1,
              {
                drawSVG: `${route07DashOffset}`,
                ease: 'none',
              },
              6.1
          )
          .to(route07,
              1,
              {
                drawSVG: `${route07DashOffset} ${route07DashOffset}`,
                ease: 'none',
              },
              7.1
          )
          .to(movingDot,
              {
                motionPath: {
                  path: route08,
                  align: route08,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              7.1
          )
          .to(route08,
              1,
              {
                drawSVG: `${route08DashOffset}`,
                ease: 'none',
              },
              7.1
          )
          .to(route08,
              1,
              {
                drawSVG: `${route08DashOffset} ${route08DashOffset}`,
                ease: 'none',
              },
              8.1
          )
          .to(movingDot,
              {
                motionPath: {
                  path: route09,
                  align: route09,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              8.1
          )
          .to(route09,
              1,
              {
                drawSVG: `${route09DashOffset}`,
                ease: 'none',
              },
              8.1
          )
          .to(route09,
              1,
              {
                drawSVG: `${route09DashOffset} ${route09DashOffset}`,
                ease: 'none',
              },
              9.1
          )
          .to(movingDot,
              {
                motionPath: {
                  path: route10,
                  align: route10,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              9.1
          )
          .to(route10,
              1,
              {
                drawSVG: `${route10DashOffset}`,
                ease: 'none',
              },
              9.1
          )
          .to(movingDot,
              0.1,
              {
                fill: this.color2,
                ease: 'none',
              },
              10.1
          )
          .to(route10,
              1,
              {
                drawSVG: `${route10DashOffset} ${route10DashOffset}`,
                ease: 'none',
              },
              10.1
          )
          .to(movingDot,
              {
                motionPath: {
                  path: route11,
                  align: route11,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              10.1
          )
          .to(route11,
              1,
              {
                drawSVG: `${route11DashOffset}`,
                ease: 'none',
              },
              10.1
          )
          .to(route11,
              1,
              {
                drawSVG: `${route11DashOffset} ${route11DashOffset}`,
                ease: 'none',
              },
              11.1
          )
          .to(movingDot,
              {
                motionPath: {
                  path: route12,
                  align: route12,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              11.1
          )
          .to(route12,
              1,
              {
                drawSVG: `${route12DashOffset}`,
                ease: 'none',
              },
              11.1
          )
          .to(route12,
              1,
              {
                drawSVG: `${route12DashOffset} ${route12DashOffset}`,
                ease: 'none',
              },
              12.1
          )
          .to(movingDot,
              {
                motionPath: {
                  path: route13,
                  align: route13,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              12.1
          )
          .to(route13,
              1,
              {
                drawSVG: `${route13DashOffset}`,
                ease: 'none',
              },
              12.1
          )
          .to(route13,
              1,
              {
                drawSVG: `${route13DashOffset} ${route13DashOffset}`,
                ease: 'none',
              },
              13.1
          )
          .to(movingDot,
              {
                motionPath: {
                  path: route14,
                  align: route14,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              13.1
          )
          .to(route14,
              1,
              {
                drawSVG: `${route14DashOffset}`,
                ease: 'none',
              },
              13.1
          )

      this.linesSTMobile = ScrollTrigger.create({
        trigger: route01,
        start: 'top bottom-=20%',
        endTrigger: route14,
        end: 'bottom center+=12%',
        scrub: 0.6,
        animation: this.linesTimelineMobile,
      });
    },
  }
}
</script>


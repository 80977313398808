<template>
  <g id="LINE 2">
    <!-- Routes -->
    <g stroke="#FF8B00" stroke-linecap="round" stroke-width="32">
      <path stroke="#F4F5F6" d="M620 2358H279c-55.228 0-100-44.77-100-100v-116c0-55.23 44.772-100 100-100h517c55.228 0 100-44.77 100-100v-101c0-55.23-44.772-100-100-100H116c-55.228 0-100-44.77-100-100V970c0-55.228 44.772-100 100-100h221c55.228 0 100 44.771 100 100v274c0 55.23 44.772 100 100 100h289.5c55.228 0 100-44.77 100-100v-17c0-55.23 44.772-100 100-100H1432c55.23 0 100-44.77 100-100V870"/>
      <path ref="Route06" data-route="-1" d="M620 2358H279c-55.228 0-100-44.77-100-100v-58"/>
      <path ref="Route05" data-route="1" d="M456 1741h340c55.228 0 100 44.77 100 100v101c0 55.23-44.772 100-100 100H279c-55.228 0-100 44.77-100 100v58"/>
      <path ref="Route04" data-route="1" d="M226.5 870H116c-55.228 0-100 44.772-100 100v671c0 55.23 44.772 100 100 100h340"/>
      <path ref="Route03" data-route="1" d="M681.75 1344H537c-55.228 0-100-44.77-100-100V970c0-55.229-44.772-100-100-100H226.5"/>
      <path ref="Route02" data-route="1" d="M1229.25 1127H1026.5c-55.228 0-100 44.77-100 100v17c0 55.23-44.772 100-100 100H681.75"/>
      <path ref="Route01" data-route="1" d="M1532 870v157c0 55.23-44.77 100-100 100h-202.75"/>
    </g>

    <!-- Dots -->
    <g>
      <path ref="MovingDot" fill="#FFD400" d="M179 2216c8.836 0 16-7.16 16-16s-7.164-16-16-16-16 7.16-16 16 7.164 16 16 16z"/>
      <path ref="Dot01" fill="#FFD400" d="M0 1220c0 8.84 7.164 16 16 16 8.837 0 16-7.16 16-16s-7.163-16-16-16c-8.836 0-16 7.16-16 16z"/>
      <path ref="Dot02" fill="#FFD400" d="M699 2058c8.836 0 16-7.16 16-16s-7.164-16-16-16-16 7.16-16 16 7.164 16 16 16z"/>
    </g>

    <!-- Label 01 -->
    <g ref="Label01">
      <path ref="Label01Bg" fill="#FFD400" d="M75.6 1173c2.24 0 3.36 0 4.216.44.753.38 1.364.99 1.748 1.74.436.86.436 1.98.436 4.22v81.2c0 2.24 0 3.36-.436 4.22a3.978 3.978 0 01-1.748 1.74c-.856.44-1.976.44-4.216.44H54.4c-2.24 0-3.36 0-4.216-.44a3.978 3.978 0 01-1.748-1.74c-.436-.86-.436-1.98-.436-4.22v-32.11c0-.82 0-1.23-.077-1.61a4.026 4.026 0 00-.597-1.45c-.219-.32-.507-.61-1.083-1.19-1.318-1.32-1.977-1.97-2.282-2.71a3.997 3.997 0 010-3.06c.305-.74.964-1.39 2.282-2.71.576-.58.864-.87 1.083-1.19.292-.44.495-.93.597-1.45.077-.38.077-.79.077-1.61v-32.11c0-2.24 0-3.36.436-4.22.383-.75.995-1.36 1.748-1.74.856-.44 1.976-.44 4.216-.44h21.2z"/>
      <text class="font-main-b text-16" fill="#ffffff" opacity=".85" transform="rotate(90 -555.75 631.25)">
        <tspan x=".43" y="17.2">PARADA 2</tspan>
      </text>
    </g>

    <!-- Label 02 -->
    <g ref="Label02">
      <path ref="Label02Bg" fill="#FFD400" d="M652 1982.4c0-2.24 0-3.36.436-4.22.383-.75.995-1.36 1.748-1.74.856-.44 1.976-.44 4.216-.44h81.2c2.24 0 3.36 0 4.216.44.753.38 1.365.99 1.748 1.74.436.86.436 1.98.436 4.22v21.2c0 2.24 0 3.36-.436 4.22a3.973 3.973 0 01-1.748 1.74c-.856.44-1.976.44-4.216.44h-32.115c-.815 0-1.222 0-1.609.08-.515.1-1.005.3-1.441.59-.328.22-.616.51-1.192 1.09-1.318 1.32-1.977 1.97-2.712 2.28-.98.4-2.082.4-3.062 0-.735-.31-1.394-.96-2.712-2.28-.576-.58-.864-.87-1.192-1.09a3.966 3.966 0 00-1.441-.59c-.387-.08-.794-.08-1.609-.08H658.4c-2.24 0-3.36 0-4.216-.44a3.973 3.973 0 01-1.748-1.74c-.436-.86-.436-1.98-.436-4.22v-21.2z"/>
      <text class="font-main-b text-16" fill="#ffffff" opacity=".85" transform="translate(681 1982.5)">
        <tspan x=".367" y="17.2">PARE</tspan>
      </text>
    </g>

    <!-- Icon -->
    <g data-parallax="12">
      <g data-animate="fade-up">
        <g filter="url(#filter1_d)">
          <circle cx="392" cy="1743" r="196" fill="#EF8200"/>
        </g>
        <ellipse cx="392" cy="1735" fill="#FF8B00" rx="196" ry="188"/>
        <g fill="#fff" fill-rule="evenodd" clip-rule="evenodd">
          <path d="M311 1742.98c0-44.66 36.324-80.98 80.975-80.98 17.138 0 33.517 5.29 47.369 15.29a5.039 5.039 0 011.13 7.03 5.042 5.042 0 01-7.019 1.13c-12.127-8.76-26.469-13.39-41.48-13.39-39.107 0-70.921 31.81-70.921 70.92 0 39.1 31.814 70.92 70.921 70.92 39.107 0 70.922-31.82 70.922-70.92 0-9.9 10.053-11.3 10.053 0 0 44.65-36.324 80.97-80.975 80.97-44.651 0-80.975-36.32-80.975-80.97z"/>
          <path d="M391.975 1823.95c-44.651 0-80.975-36.32-80.975-80.97 0-44.66 36.324-80.98 80.975-80.98 17.138 0 33.517 5.29 47.369 15.29a5.039 5.039 0 011.13 7.03 5.042 5.042 0 01-7.019 1.13c-12.127-8.76-26.469-13.39-41.48-13.39-39.107 0-70.921 31.81-70.921 70.92 0 39.1 31.814 70.92 70.921 70.92 39.107 0 70.922-31.82 70.922-70.92 0-9.9-1.997-19.47-5.933-28.45a5.023 5.023 0 012.586-6.62 5.024 5.024 0 016.623 2.58c4.498 10.26 6.777 21.19 6.777 32.49 0 44.65-36.324 80.97-80.975 80.97z" opacity=".4"/>
          <path d="M467.925 1748H316.028a5.026 5.026 0 110-10.05h151.897a5.025 5.025 0 110 10.05z"/>
          <path d="M367.688 1818.76a5.01 5.01 0 01-4.157-2.19c-41.742-61.14-14.639-121.99-1.048-145.31l.559-.97c.789-1.9 2.693-3.17 4.884-3.09a5.03 5.03 0 014.778 5.27c-.063 1.32-.493 2.06-1.534 3.85-12.628 21.67-37.845 78.17.667 134.58a5.032 5.032 0 01-1.319 6.99c-.867.59-1.854.87-2.83.87z"/>
          <path d="M414.906 1818.76c-.976 0-1.961-.28-2.828-.87a5.03 5.03 0 01-1.319-6.99c38.512-56.41 13.296-112.91.667-134.58-1.04-1.79-1.473-2.53-1.536-3.86a5.03 5.03 0 014.78-5.26c2.197-.04 4.096 1.19 4.883 3.09.126.22.316.54.558.96 13.591 23.33 40.695 84.18-1.046 145.32a5.014 5.014 0 01-4.159 2.19z"/>
          <path d="M391.977 1823.95a5.028 5.028 0 01-5.028-5.03v-151.89a5.028 5.028 0 1110.056 0v151.89c0 2.78-2.251 5.03-5.028 5.03z"/>
        </g>
      </g>
    </g>

    <!-- Tunnel Top -->
    <g ref="TunnelTop">
      <path fill="#E5E6EB" d="M1548 886.355v51.29c20.33-3.553 35-13.692 35-25.645s-14.67-22.092-35-25.645zm-32 0v51.29c-20.33-3.553-35-13.692-35-25.645s14.67-22.092 35-25.645z"/>
      <path fill="#fff" d="M1553 887.388V852h-42v35.388c6.4-1.534 13.51-2.388 21-2.388s14.6.854 21 2.388z"/>
    </g>

    <!-- Tunnel Bottom -->
    <g ref="TunnelBottom">
      <path fill="#E5E6EB" d="M603.645 2374h-51.29c3.553 20.33 13.692 35 25.645 35s22.092-14.67 25.645-35zm0-32h-51.29c3.553-20.33 13.692-35 25.645-35s22.092 14.67 25.645 35z"/>
      <path fill="#fff" d="M602.612 2379H638v-42h-35.388c1.534 6.41 2.388 13.52 2.388 21 0 7.49-.854 14.6-2.388 21z"/>
    </g>
  </g>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';
import ExpoScaleEase from 'gsap/EasePack';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin, ExpoScaleEase);

export default {
  mixins: [vueGSAPMixin],

  data() {
    return {
      linesTimeline: null,
      linesScrollTrigger: null,
      linesLabelTimeline01: null,
      linesLabelTimeline02: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetRoutes(this.$el);

      setTimeout(() => {
        this.animateLines();
      }, 1000)
    });
  },

  methods: {
    animateLines() {
      let direction = 1;

      this.linesLabelTimeline01 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot01,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot01,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label01,
              0.3,
              {
                xPercent: 100,
                opacity: 0,
              },
              {
                xPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          );

      this.linesLabelTimeline02 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot02,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot02,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label02,
              0.3,
              {
                yPercent: -150,
                opacity: 0,
              },
              {
                yPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          );

      this.linesTimeline = gsap.timeline()
          .fromTo(this.$refs.MovingDot,
              0.1,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route01,
                  align: this.$refs.Route01,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0.1
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              0.1
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()} ${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route02,
                  align: this.$refs.Route02,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              1.1
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()} ${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route03,
                  align: this.$refs.Route03,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              2.1
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()} ${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route04,
                  align: this.$refs.Route04,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              3.1
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength()}`,
                ease: 'none',
              },
              3.1
          )
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline01.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline01.reverse();
                }
              },
              3.3
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength()} ${this.$refs.Route04.getTotalLength()}`,
                ease: 'none',
              },
              4.1
          )
          .to(this.$refs.Label01Bg,
              0.2,
              {
                fill: '#CCCED7',
                ease: 'none',
              },
              4.4
          )
          .to(this.$refs.Dot01,
              0.2,
              {
                fill: '#CCCED7',
                ease: 'none',
              },
              4.4
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route05,
                  align: this.$refs.Route05,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              4.1
          )
          .to(this.$refs.Route05,
              1,
              {
                drawSVG: `${this.$refs.Route05.getTotalLength()}`,
                ease: 'none',
              },
              4.1
          )
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline02.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline02.reverse();
                }
              },
              4.7
          )
          .to(this.$refs.Route05,
              1,
              {
                drawSVG: `${this.$refs.Route05.getTotalLength()} ${this.$refs.Route05.getTotalLength()}`,
                ease: 'none',
              },
              5.1
          )
          .to(this.$refs.Label02Bg,
              0.2,
              {
                fill: '#CCCED7',
                ease: 'none',
              },
              5.6
          )
          .to(this.$refs.Dot02,
              0.2,
              {
                fill: '#CCCED7',
                ease: 'none',
              },
              5.6
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route06,
                  align: this.$refs.Route06,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              5.1
          )
          .to(this.$refs.Route06,
              1,
              {
                drawSVG: `${this.$refs.Route06.getTotalLength()}`,
                ease: 'none',
              },
              5.1
          )
          .to(this.$refs.Route06,
              1,
              {
                drawSVG: `${this.$refs.Route06.getTotalLength() *-1}`,
                ease: 'none',
              },
              6.1
          )
          .to(this.$refs.MovingDot,
              {
                scale: 0,
                duration: 1,
                ease: 'none'
              },
              6.1
          )

      ScrollTrigger.matchMedia({
        "(min-width: 960px)": () => {
          ScrollTrigger.create({
            trigger: this.$refs.Route01,
            start: 'top bottom-=20%',
            endTrigger: this.$refs.TunnelBottom,
            end: 'bottom center+=12%',
            scrub: 0.6,
            animation: this.linesTimeline,
            onUpdate: self => {
              if (self.direction === direction) {
                return;
              }
              direction = self.direction;
            },
          });
        },
      });
    },
  }
}
</script>


<template>
  <g>
    <!-- Routes -->
    <g stroke-linecap="round" stroke-width="32" :stroke="color2">
      <path stroke="#F4F5F6" d="M16.5 1806v0c0-40.97 0-61.45 5.912-77.87a99.999 99.999 0 0160.214-60.22c16.421-5.91 36.905-5.91 77.874-5.91h225c56.005 0 84.008 0 105.399-10.9a99.996 99.996 0 0043.702-43.7c10.899-21.39 10.899-49.39 10.899-105.4v-24c0-56.01 0-84.01 10.899-105.4a99.996 99.996 0 0143.702-43.7c21.391-10.9 49.394-10.9 105.399-10.9h544c56.01 0 84.01 0 105.4 10.9a99.981 99.981 0 0143.7 43.7c10.9 21.39 10.9 49.39 10.9 105.4v1241c0 56.01 0 84.01-10.9 105.4a99.981 99.981 0 01-43.7 43.7c-21.39 10.9-49.39 10.9-105.4 10.9h-892c-56.005 0-84.008 0-105.399 10.9a99.996 99.996 0 00-43.702 43.7C197.5 2954.99 197.5 2982.99 197.5 3039v190c0 56.01 0 84.01 10.899 105.4a99.996 99.996 0 0043.702 43.7c21.391 10.9 49.394 10.9 105.399 10.9H800c56.005 0 84.008 0 105.399 10.9a99.996 99.996 0 0143.702 43.7C960 3464.99 960 3492.99 960 3549v183c0 56.01 0 84.01-10.899 105.4a99.996 99.996 0 01-43.702 43.7C884.008 3892 856.005 3892 800 3892H488.5c-39.096 0-58.645 0-74.392 5.39a100.001 100.001 0 00-62.217 62.22c-5.391 15.75-5.391 35.29-5.391 74.39v0c0 39.1 0 58.64 5.391 74.39a100.001 100.001 0 0062.217 62.22c15.747 5.39 35.296 5.39 74.392 5.39h312c56.005 0 84.008 0 105.399 10.9a99.996 99.996 0 0143.702 43.7c10.899 21.39 10.899 49.39 10.899 105.4v100"/>

      <path ref="Route07" data-route="-1" d="M960.5 4436v-100c0-56.01 0-84.01-10.899-105.4a99.996 99.996 0 00-43.702-43.7C884.508 4176 856.505 4176 800.5 4176h-252"/>
      <path ref="Route06" data-route="-1" d="M548.5 4176h-60c-39.096 0-58.645 0-74.392-5.39a100.001 100.001 0 01-62.217-62.22c-5.391-15.75-5.391-35.29-5.391-74.39v0c0-39.1 0-58.64 5.391-74.39a100.001 100.001 0 0162.217-62.22c15.747-5.39 35.296-5.39 74.392-5.39H800c56.005 0 84.008 0 105.399-10.9a99.996 99.996 0 0043.702-43.7C960 3816.01 960 3788.01 960 3732v-91.5"/>
      <path ref="Route05" data-route="1" d="M197.5 3112v117c0 56.01 0 84.01 10.899 105.4a99.996 99.996 0 0043.702 43.7c21.391 10.9 49.394 10.9 105.399 10.9H800c56.005 0 84.008 0 105.399 10.9a99.996 99.996 0 0143.702 43.7C960 3464.99 960 3492.99 960 3549v91.5"/>
      <path ref="Route04" data-route="1" d="M548.5 2879h-191c-56.005 0-84.008 0-105.399 10.9a99.996 99.996 0 00-43.702 43.7C197.5 2954.99 197.5 2982.99 197.5 3039v73"/>
      <path ref="Route03" data-route="1" d="M1409.5 2098.5V2719c0 56.01 0 84.01-10.9 105.4a99.981 99.981 0 01-43.7 43.7c-21.39 10.9-49.39 10.9-105.4 10.9h-701"/>
      <path ref="Route02" data-route="1" d="M960.5 1318h289c56.01 0 84.01 0 105.4 10.9a99.981 99.981 0 0143.7 43.7c10.9 21.39 10.9 49.39 10.9 105.4v620.5"/>
      <path ref="Route01" data-route="1" d="M16.5 1806v0c0-40.97 0-61.45 5.912-77.87a99.999 99.999 0 0160.214-60.22c16.421-5.91 36.905-5.91 77.874-5.91h225c56.005 0 84.008 0 105.399-10.9a99.996 99.996 0 0043.702-43.7c10.899-21.39 10.899-49.39 10.899-105.4v-24c0-56.01 0-84.01 10.899-105.4a99.996 99.996 0 0143.702-43.7c21.391-10.9 49.394-10.9 105.399-10.9h255"/>
    </g>

    <!-- Dots -->
    <g>
      <path ref="MovingDot" :fill="color1" d="M.5 1806c0-8.84 7.164-16 16-16 8.837 0 16 7.16 16 16s-7.163 16-16 16c-8.836 0-16-7.16-16-16z"/>
      <path ref="Dot01" fill="#CCCED7" d="M.5 1806c0-8.84 7.164-16 16-16 8.837 0 16 7.16 16 16s-7.163 16-16 16c-8.836 0-16-7.16-16-16z"/>
      <circle ref="Dot02" cx="197.5" cy="3112" r="16" :fill="color1" transform="rotate(180 197.5 3112)"/>
      <circle ref="Dot03" cx="548.5" cy="4176" r="16" :fill="color1" transform="rotate(180 548.5 4176)"/>
    </g>

    <!-- Label 01 -->
    <g ref="Label01">
      <path ref="Label01Bg" :fill="color1" fill-rule="evenodd" d="M138.9 3158c-2.24 0-3.36 0-4.216-.44a3.973 3.973 0 01-1.748-1.74c-.436-.86-.436-1.98-.436-4.22v-81.2c0-2.24 0-3.36.436-4.22.383-.75.995-1.36 1.748-1.74.856-.44 1.976-.44 4.216-.44h21.2c2.24 0 3.36 0 4.216.44.753.38 1.365.99 1.748 1.74.436.86.436 1.98.436 4.22v32.11c0 .82 0 1.23.077 1.61.102.52.305 1.01.597 1.45.219.32.507.61 1.083 1.19 1.318 1.32 1.977 1.97 2.282 2.71.406.98.406 2.08 0 3.06-.305.74-.964 1.39-2.282 2.71-.576.58-.864.87-1.083 1.19-.292.44-.495.93-.597 1.45-.077.38-.077.79-.077 1.61v32.11c0 2.24 0 3.36-.436 4.22a3.973 3.973 0 01-1.748 1.74c-.856.44-1.976.44-4.216.44h-21.2z" clip-rule="evenodd"/>
      <text class="font-main-b text-16" fill="#ffffff" opacity=".85" transform="rotate(-90 1641.5 1502.5)">
        <tspan x=".359" y="17.2">PARADA 3</tspan>
      </text>
    </g>

    <!-- Label 02 -->
    <g ref="Label02">
      <path ref="Label02Bg" :fill="color1" fill-rule="evenodd" d="M504.5 4117.4c0-2.24 0-3.36.436-4.22.383-.75.995-1.36 1.748-1.74.856-.44 1.976-.44 4.216-.44h75.2c2.24 0 3.36 0 4.216.44.753.38 1.365.99 1.748 1.74.436.86.436 1.98.436 4.22v21.2c0 2.24 0 3.36-.436 4.22a3.973 3.973 0 01-1.748 1.74c-.856.44-1.976.44-4.216.44h-29.115c-.815 0-1.222 0-1.609.08-.515.1-1.005.3-1.441.59-.328.22-.616.51-1.192 1.09-1.318 1.32-1.977 1.97-2.712 2.28-.98.4-2.082.4-3.062 0-.735-.31-1.394-.96-2.712-2.28-.576-.58-.864-.87-1.192-1.09a3.966 3.966 0 00-1.441-.59c-.387-.08-.794-.08-1.609-.08H510.9c-2.24 0-3.36 0-4.216-.44a3.973 3.973 0 01-1.748-1.74c-.436-.86-.436-1.98-.436-4.22v-21.2z" clip-rule="evenodd"/>
      <text class="font-main-b text-16" fill="#ffffff" opacity=".85">
        <tspan x="520.914" y="4134.2">DESTINO</tspan>
      </text>
    </g>

    <!-- Tunnel -->
    <g ref="Tunnel">
      <path fill="#E5E6EB" d="M944.5 4419.64v-51.28c-20.329 3.55-35 13.69-35 25.64 0 11.95 14.671 22.09 35 25.64zm32 0v-51.28c20.329 3.55 35 13.69 35 25.64 0 11.95-14.671 22.09-35 25.64z"/>
      <path fill="#fff" d="M939.5 4418.61V4454h42v-35.39c-6.404 1.54-13.514 2.39-21 2.39-7.486 0-14.596-.85-21-2.39z"/>
    </g>

    <!-- FX -->
    <g fill="#F4F5F6">
      <path ref="FxLine01" d="M742.5 3854c0-1.66-1.343-3-3-3h-70c-1.657 0-3 1.34-3 3s1.343 3 3 3h70c1.657 0 3-1.34 3-3z"/>
      <path ref="FxLine2" d="M789.5 3929c0-1.66-1.343-3-3-3h-40c-1.657 0-3 1.34-3 3s1.343 3 3 3h40c1.657 0 3-1.34 3-3z"/>
    </g>
  </g>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';
import ExpoScaleEase from 'gsap/EasePack';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin, ExpoScaleEase);

export default {
  mixins: [vueGSAPMixin],

  props: {
    color1: String,
    color2: String,
  },

  data() {
    return {
      linesTimeline: null,
      linesScrollTrigger: null,
      linesLabelTimeline01: null,
      linesLabelTimeline02: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetRoutes(this.$el);

      setTimeout(() => {
        this.animateLines();
      }, 1000)
    });
  },

  methods: {
    animateLines() {
      let direction = 1;

      this.linesLabelTimeline01 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot02,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot02,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label01,
              0.3,
              {
                xPercent: -100,
                opacity: 0,
              },
              {
                xPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          );

      this.linesLabelTimeline02 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot03,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot03,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label02,
              0.3,
              {
                yPercent: -150,
                opacity: 0,
              },
              {
                yPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )

      this.linesTimeline = gsap.timeline()
          .fromTo(this.$refs.MovingDot,
              0.1,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route03,
                  align: this.$refs.Route03,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0.1
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              0.1
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()} ${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route04,
                  align: this.$refs.Route04,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              1.1
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline01.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline01.reverse();
                }
              },
              2.1
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength()} ${this.$refs.Route04.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route05,
                  align: this.$refs.Route05,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              2.1
          )
          .to(this.$refs.Route05,
              1,
              {
                drawSVG: `${this.$refs.Route05.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.Dot02,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              3.1
          )
          .to(this.$refs.Label01Bg,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              3.1
          )
          .to(this.$refs.Route05,
              1,
              {
                drawSVG: `${this.$refs.Route05.getTotalLength()} ${this.$refs.Route05.getTotalLength()}`,
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route06,
                  align: this.$refs.Route06,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              3.1
          )
          .to(this.$refs.Route06,
              1,
              {
                drawSVG: `${this.$refs.Route06.getTotalLength()}`,
                ease: 'none',
              },
              3.1
          )
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline02.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline02.reverse();
                }
              },
              4.1
          )
          .to(this.$refs.Route06,
              1,
              {
                drawSVG: `${this.$refs.Route06.getTotalLength() * -1}`,
                ease: 'none',
              },
              4.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route07,
                  align: this.$refs.Route07,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              4.1
          )
          .to(this.$refs.Route07,
              1,
              {
                drawSVG: `${this.$refs.Route07.getTotalLength()}`,
                ease: 'none',
              },
              4.1
          )
          .to(this.$refs.Dot03,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              5.1
          )
          .to(this.$refs.Label02Bg,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              5.1
          )
          .to(this.$refs.Route07,
              1,
              {
                drawSVG: `${this.$refs.Route07.getTotalLength() * -1}`,
                ease: 'none',
              },
              5.1
          )
          .to(this.$refs.MovingDot,
          0.1,
              {
                scale: 0,
                ease: 'none'
              },
              6
          )

      ScrollTrigger.matchMedia({
        "(min-width: 960px)": () => {
          ScrollTrigger.create({
            trigger: this.$refs.Route03,
            start: 'top center+=7%',
            endTrigger: this.$refs.Tunnel,
            end: 'bottom center',
            scrub: 0.6,
            animation: this.linesTimeline,
            onUpdate: self => {
              if (self.direction === direction) {
                return;
              }
              direction = self.direction;
            },
          });
        },
      });
    },
  }
}
</script>


<template>
  <g>
    <!-- Routes -->
    <g stroke="#FF8B00" stroke-linecap="round" stroke-width="32">
      <path stroke="#F4F5F6" d="M1299 2591h505c55.23 0 100 44.77 100 100v183c0 55.23-44.77 100-100 100h-563c-55.23 0-100 44.77-100 100v132c0 55.23 44.77 100 100 100h355c55.23 0 100 44.77 100 100v161c0 55.23 44.77 100 100 100h106"/>
      <path ref="Route05" data-route="-1" d="M1902 3667h-106c-55.23 0-100-44.77-100-100v-81"/>
      <path ref="Route04" data-route="-1" d="M1696 3486v-80c0-55.23-44.77-100-100-100h-177"/>
      <path ref="Route03" data-route="1" d="M1522.5 2974H1241c-55.23 0-100 44.77-100 100v132c0 55.23 44.77 100 100 100h178"/>
      <path ref="Route02" data-route="1" d="M1904 2782.5v91.5c0 55.23-44.77 100-100 100h-281.5"/>
      <path ref="Route01" data-route="1" d="M1299 2591h505c55.23 0 100 44.77 100 100v91.5"/>
    </g>

    <!-- Dots -->
    <path ref="MovingDot" fill="#FFD400" d="M1419 3322c8.84 0 16-7.16 16-16s-7.16-16-16-16-16 7.16-16 16 7.16 16 16 16z"/>

    <!-- Tunnel Top -->
    <g ref="TunnelTop">
      <path fill="#E5E6EB" d="M1315.36 2575h51.28c-3.55-20.33-13.69-35-25.64-35-11.95 0-22.09 14.67-25.64 35zm0 32h51.28c-3.55 20.33-13.69 35-25.64 35-11.95 0-22.09-14.67-25.64-35z"/>
      <path fill="#fff" d="M1316.39 2570H1281v42h35.39c-1.54-6.4-2.39-13.51-2.39-21s.85-14.6 2.39-21z"/>
    </g>

    <!-- Tunnel Bottom -->
    <g ref="TunnelBottom">
      <path fill="#E5E6EB" d="M1885.64 3683h-51.28c3.55 20.33 13.69 35 25.64 35 11.95 0 22.09-14.67 25.64-35zm0-32h-51.28c3.55-20.33 13.69-35 25.64-35 11.95 0 22.09 14.67 25.64 35z"/>
      <path fill="#fff" d="M1884.61 3688H1920v-42h-35.39c1.54 6.4 2.39 13.51 2.39 21s-.85 14.6-2.39 21z"/>
    </g>
  </g>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';
import ExpoScaleEase from 'gsap/EasePack';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin, ExpoScaleEase);

export default {
  mixins: [vueGSAPMixin],

  data() {
    return {
      linesTimeline: null,
      linesScrollTrigger: null,
      linesLabelTimeline01: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetRoutes(this.$el);

      setTimeout(() => {
        this.animateLines();
      }, 1000)
    });
  },

  methods: {
    animateLines() {
      this.linesTimeline = gsap.timeline()
          .fromTo(this.$refs.MovingDot,
              0.1,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route01,
                  align: this.$refs.Route01,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0.1
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              0.1
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()} ${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route02,
                  align: this.$refs.Route02,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              1.1
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()} ${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route03,
                  align: this.$refs.Route03,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              2.1
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()} ${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route04,
                  align: this.$refs.Route04,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              3.1
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength()}`,
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength() *-1}`,
                ease: 'none',
              },
              4.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route05,
                  align: this.$refs.Route05,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              4.1
          )
          .to(this.$refs.Route05,
              1,
              {
                drawSVG: `${this.$refs.Route05.getTotalLength()}`,
                ease: 'none',
              },
              4.1
          )
          .to(this.$refs.Route05,
              1,
              {
                drawSVG: `${this.$refs.Route05.getTotalLength() *-1}`,
                ease: 'none',
              },
              5.1
          )
          .to(this.$refs.MovingDot,
              {
                scale: 0,
                duration: 1,
                ease: 'none'
              },
              5.1
          )

      ScrollTrigger.matchMedia({
        "(min-width: 960px)": () => {
          ScrollTrigger.create({
            trigger: this.$refs.TunnelTop,
            start: 'top bottom-=20%',
            endTrigger: this.$refs.TunnelBottom,
            end: 'bottom center+=12%',
            scrub: 0.6,
            animation: this.linesTimeline,
          });
        },
      });
    },
  }
}
</script>


<template>
  <g>
    <!-- Routes -->
    <g stroke-linecap="round" stroke-width="32" stroke="#FFD400">
      <path stroke="#F4F5F6" d="M960 18v126c0 55.228 44.77 100 100 100h744c55.23 0 100 44.772 100 100v74c0 55.228-44.77 100-100 100h-547c-55.23 0-100 44.772-100 100v574c0 55.23 44.77 100 100 100h175c55.23 0 100 44.77 100 100v64"/>
      <path ref="Route05" data-route="-1" d="M1532 1456v-64c0-55.23-44.77-100-100-100h-87.5"/>
      <path ref="Route04" data-route="1" d="M1157 806v386c0 55.23 44.77 100 100 100h87.5"/>
      <path ref="Route03" data-route="1" d="M1530.5 518H1257c-55.23 0-100 44.772-100 100v188"/>
      <path ref="Route02" data-route="1" d="M1204.5 244H1804c55.23 0 100 44.772 100 100v74c0 55.228-44.77 100-100 100h-273.5"/>
      <path ref="Route01" data-route="1" d="M960 18v126c0 55.228 44.77 100 100 100h144.5"/>
    </g>

    <!-- Dots -->
    <g>
      <path ref="MovingDot" fill="#FF8B00" d="M1157 822c8.84 0 16-7.164 16-16 0-8.837-7.16-16-16-16s-16 7.163-16 16c0 8.836 7.16 16 16 16z"/>
      <path ref="Dot01" fill="#FF8B00" d="M1534 260c8.84 0 16-7.164 16-16 0-8.837-7.16-16-16-16s-16 7.163-16 16c0 8.836 7.16 16 16 16z"/>
    </g>

    <!-- Label -->
    <g ref="Label">
      <path ref="LabelBg" fill="#FF8B00" d="M1487 184.4c0-2.24 0-3.36.44-4.216.38-.753.99-1.365 1.74-1.748.86-.436 1.98-.436 4.22-.436h81.2c2.24 0 3.36 0 4.22.436.75.383 1.36.995 1.74 1.748.44.856.44 1.976.44 4.216v21.2c0 2.24 0 3.36-.44 4.216a3.973 3.973 0 01-1.74 1.748c-.86.436-1.98.436-4.22.436h-32.11c-.82 0-1.23 0-1.61.077-.52.102-1.01.305-1.45.597-.32.219-.61.507-1.19 1.083-1.32 1.318-1.97 1.977-2.71 2.282-.98.406-2.08.406-3.06 0-.74-.305-1.39-.964-2.71-2.282-.58-.576-.87-.864-1.19-1.083a4.015 4.015 0 00-1.45-.597c-.38-.077-.79-.077-1.61-.077h-32.11c-2.24 0-3.36 0-4.22-.436a3.973 3.973 0 01-1.74-1.748c-.44-.856-.44-1.976-.44-4.216v-21.2z"/>
      <text class="font-main-b text-16" fill="#ffffff" opacity=".85" transform="translate(1502 184.5)">
        <tspan x=".156" y="17.2">PARADA 1</tspan>
      </text>
    </g>

    <!-- Icon -->
    <g data-parallax="12">
      <g data-animate="fade-up">
        <g filter="url(#filter2_d)">
          <circle cx="1532" cy="515" r="196" fill="#EFC700"/>
        </g>
        <ellipse id="Oval_6" cx="1532" cy="507" fill="#FFD400" rx="196" ry="188"/>
        <g stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="10">
          <path d="M1546.47 478.429l-15.95 17.128c-11.47 12.313-30.07 12.313-41.54 0l-1.26-1.357 36.2-38.868c20.28-21.771 53.15-21.771 73.43 0"/>
          <path d="M1546.47 478.429l-15.95 17.128c-11.47 12.313-30.07 12.313-41.54 0l-1.26-1.357 36.2-38.868c20.28-21.771 53.15-21.771 73.43 0v0c16.83 18.07 20.07 46.106 7.87 68.045"
                opacity=".4"/>
          <path d="M1555.77 504.182l26.53 28.49c8.6 9.238 8.6 24.213 0 33.451l-12.02 12.901c-8.26 8.874-19.47 13.86-31.15 13.86-11.69 0-22.9-4.986-31.16-13.86l-41.76-44.838c-20.27-21.777-20.27-57.078 0-78.855v0c9.6-10.337 22.6-16.203 36.2-16.331"/>
          <path d="M1578.71 569.972l-28.57-30.675"/>
          <path d="M1557.58 588.548l-26.66-28.624"/>
        </g>
      </g>
    </g>

    <!-- Tunnel Top -->
    <g ref="TunnelTop" fill-rule="evenodd"  clip-rule="evenodd">
      <path fill="#E5E6EB" d="M976 34.355v51.29c20.329-3.553 35-13.692 35-25.645s-14.671-22.092-35-25.645zm-32 0v51.29C923.671 82.091 909 71.952 909 60s14.671-22.092 35-25.645z"/>
      <path fill="#fff" d="M981 35.388V0h-42v35.388C945.404 33.854 952.514 33 960 33c7.486 0 14.596.854 21 2.388z"/>
    </g>

    <!-- Tunnel Bottom -->
    <g ref="TunnelBottom" fill-rule="evenodd" clip-rule="evenodd">
      <path fill="#E5E6EB" d="M1516 1439.64v-51.28c-20.33 3.55-35 13.69-35 25.64 0 11.95 14.67 22.09 35 25.64zm32 0v-51.28c20.33 3.55 35 13.69 35 25.64 0 11.95-14.67 22.09-35 25.64z"/>
      <path fill="#fff" d="M1511 1438.61V1474h42v-35.39c-6.4 1.54-13.51 2.39-21 2.39s-14.6-.85-21-2.39z"/>
    </g>
  </g>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';
import ExpoScaleEase from 'gsap/EasePack';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin, ExpoScaleEase);

export default {
  mixins: [vueGSAPMixin],

  data() {
    return {
      linesTimeline: null,
      linesScrollTrigger: null,
      linesLabelTimeline01: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetRoutes(this.$el);

      setTimeout(() => {
        this.animateLines();
      }, 1000)
    });
  },

  methods: {
    animateLines() {
      let direction = 1;

      this.linesLabelTimeline01 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot01,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot01,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label,
              0.3,
              {
                yPercent: -150,
                opacity: 0,
              },
              {
                yPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          );

      this.linesTimeline = gsap.timeline()
          .fromTo(this.$refs.MovingDot,
              0.1,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route01,
                  align: this.$refs.Route01,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0.1
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              0.1
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()} ${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route02,
                  align: this.$refs.Route02,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              1.1
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline01.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline01.reverse();
                }
              },
              1.3
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()} ${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route03,
                  align: this.$refs.Route03,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              2.1
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.LabelBg,
              0.2,
              {
                fill: '#CCCED7',
                ease: 'none',
              },
              2.3
          )
          .to(this.$refs.Dot01,
              0.2,
              {
                fill: '#CCCED7',
                ease: 'none',
              },
              2.3
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()} ${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route04,
                  align: this.$refs.Route04,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              3.1
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength()}`,
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength()} ${this.$refs.Route04.getTotalLength()}`,
                ease: 'none',
              },
              4.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route05,
                  align: this.$refs.Route05,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              4.1
          )
          .to(this.$refs.Route05,
              1,
              {
                drawSVG: `${this.$refs.Route05.getTotalLength()}`,
                ease: 'none',
              },
              4.1
          )
          .to(this.$refs.Route05,
              1,
              {
                drawSVG: `${this.$refs.Route05.getTotalLength() *-1}`,
                ease: 'none',
              },
              5.1
          )
          .to(this.$refs.MovingDot,
              {
                scale: 0,
                duration: 1,
                ease: 'none'
              },
              5.1
          )

      ScrollTrigger.matchMedia({
        "(min-width: 960px)": () => {
          ScrollTrigger.create({
            trigger: this.$refs.Route01,
            start: 'top bottom-=20%',
            endTrigger: this.$refs.TunnelBottom,
            end: 'bottom center+=12%',
            scrub: 0.6,
            animation: this.linesTimeline,
            onUpdate: self => {
              if (self.direction === direction) {
                return;
              }
              direction = self.direction;
            },
          });
        },
      });
    },
  }
}
</script>


<template>
  <svg id="linesS01" class="hidden lg:block" width="100%" height="100%" fill="none" viewBox="0 0 1920 1085">
    <!-- Routes -->
    <g stroke-linecap="round" stroke-width="32" stroke="#FFD400">
      <path stroke="#F4F5F6" d="M960 1067V791.5c0-55.228 44.77-100 100-100h744c55.23 0 100-44.772 100-100V502c0-55.228-44.77-100-100-100h-744c-55.23 0-100-44.772-100-100V151c0-55.228 44.77-100 100-100h638"/>

      <path ref="Route03" data-route="-1" d="M960 1067V900"/>
      <path ref="Route02" data-route="1" d="M1432 402h372c55.23 0 100 44.772 100 100v89.5c0 55.228-44.77 100-100 100h-744c-55.23 0-100 44.772-100 100V900"/>
      <path ref="Route01" data-route="1" d="M1698 51h-638c-55.23 0-100 44.772-100 100v151c0 55.228 44.77 100 100 100h372"/>
    </g>

    <!-- Dots -->
    <g>
      <path ref="MovingDot" fill="#FF8B00" d="M1716 51c0-8.837-7.16-16-16-16s-16 7.163-16 16 7.16 16 16 16 16-7.163 16-16z"/>
      <path ref="Dot01" fill="#CCCED7" d="M976 204c0-8.836-7.164-16-16-16s-16 7.164-16 16c0 8.837 7.164 16 16 16s16-7.163 16-16z"/>
    </g>

    <!-- Label -->
    <g ref="Label">
      <path ref="LabelBg" fill="#CCCED7" fill-rule="evenodd" d="M899.4 248c-2.24 0-3.36 0-4.216-.436a3.991 3.991 0 01-1.748-1.748C893 244.96 893 243.84 893 241.6v-75.2c0-2.24 0-3.36.436-4.216a3.991 3.991 0 011.748-1.748c.856-.436 1.976-.436 4.216-.436h21.2c2.24 0 3.36 0 4.216.436a3.991 3.991 0 011.748 1.748c.436.856.436 1.976.436 4.216v29.115c0 .815 0 1.222.077 1.609a3.98 3.98 0 00.597 1.441c.219.328.507.616 1.083 1.192 1.318 1.318 1.977 1.977 2.282 2.712.406.98.406 2.082 0 3.062-.305.735-.964 1.394-2.282 2.712-.576.576-.864.864-1.083 1.192a3.98 3.98 0 00-.597 1.441c-.077.387-.077.794-.077 1.609V241.6c0 2.24 0 3.36-.436 4.216a3.991 3.991 0 01-1.748 1.748c-.856.436-1.976.436-4.216.436h-21.2z" clip-rule="evenodd"/>
      <text class="font-main-b text-16" fill="#ffffff" opacity=".85" transform="rotate(-90 563.25 -336.25)">
        <tspan x=".336" y="17.2">DESDE</tspan>
      </text>
    </g>

    <!-- Tunnel Top -->
    <g>
      <path fill="#E5E6EB" d="M976 1050.64v-51.285c20.329 3.555 35 13.695 35 25.645s-14.671 22.09-35 25.64zm-32 0v-51.285c-20.329 3.555-35 13.695-35 25.645s14.671 22.09 35 25.64z"/>
      <path fill="#fff" d="M981 1049.61V1085h-42v-35.39c6.404 1.54 13.514 2.39 21 2.39 7.486 0 14.596-.85 21-2.39z"/>
    </g>

    <!-- Tunnel Bottom -->
    <g>
      <path fill="#E5E6EB" d="M1681.64 35h-51.28c3.55-20.329 13.69-35 25.64-35 11.95 0 22.09 14.671 25.64 35zm0 32h-51.28c3.55 20.329 13.69 35 25.64 35 11.95 0 22.09-14.671 25.64-35z"/>
      <path fill="#fff" d="M1680.61 30H1716v42h-35.39c1.54-6.404 2.39-13.514 2.39-21 0-7.486-.85-14.596-2.39-21z"/>
    </g>

    <!-- Blink -->
    <g class="hidden" stroke-linecap="round">
      <path stroke="#F4F5F6" stroke-width="24" d="M1493.11 971.758l-3.96 41.22"/>
      <path stroke="#FF8B00" stroke-width="24" d="M1596.73 948.172l73.06 85.228"/>
      <path stroke="#FFD400" stroke-width="24" d="M1627.92 869.002l72.58-9.502"/>
      <path stroke="#F4F5F6" stroke-width="8" d="M1560.37 1006.5l6.63 59"/>
      <path stroke="#FFD400" stroke-width="8" d="M1660.62 931.916l36.98 9.512"/>
    </g>
  </svg>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin);

export default {
  mixins: [vueGSAPMixin],

  data() {
    return {
      linesIntroTimeline: null,
      linesScrollTimeline: null,
      linesScrollTrigger: null,
      linesLabelTimeline: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetRoutes(this.$el);

      this.mediaQueries['lg'].addListener(() => {
        this.resetTimelines();
        this.createTimelines();
        this.playTimelines();
      });

      setTimeout(() => {
        this.createTimelines();
        this.playTimelines();
      }, 1000);
    });
  },

  methods: {
    createTimelines() {
      if (this.mediaQueries['lg'].matches) {
        this.linesAnimations();
      }
    },

    playTimelines() {
      if (this.mediaQueries['lg'].matches) {
        this.linesIntroTimeline.play();
      }
    },

    resetTimelines() {
      this.linesIntroTimeline && this.linesIntroTimeline.kill();
      this.linesScrollTimeline && this.linesScrollTimeline.kill();
      this.linesScrollTrigger && this.linesScrollTrigger.kill();

      this.linesScrollTimeline = null;
      this.linesIntroTimeline = null;
      this.linesScrollTrigger = null;

      this.resetRoutes(this.$el);
    },

    linesAnimations() {
      const $this = this;

      this.linesScrollTimeline = gsap.timeline({paused: true})
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()} ${this.$refs.Route02.getTotalLength()}`,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route03,
                  align: this.$refs.Route03,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()}`,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength() * -1}`,
                ease: 'none'
              },
              1
          )
          .to(this.$refs.MovingDot,
              {
                scale: 0,
                duration: 0.1,
                ease: 'none'
              },
              1
          )

      this.linesIntroTimeline = gsap.timeline(
          {
            paused: true,
            onComplete: () => this.linesScrollTrigger = ScrollTrigger.create(
                {
                  trigger: $this.$el,
                  start: 'bottom bottom-=15%',
                  end: 'bottom center-=15%',
                  scrub: 0.6,
                  animation: this.linesScrollTimeline,
                }
            )
          })
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route02,
                  align: this.$refs.Route02,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: .5,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.Route02,
              {
                duration: .5,
                drawSVG: `${this.$refs.Route02.getTotalLength()}`,
                ease: 'none'
              },
              0
          )
    },
  }
}
</script>

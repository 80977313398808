<template>
  <a v-if="href" class="fixed lg:hidden z-40 bottom-0 left-0 w-full p-4 xsm:p-5 text-center text-14 xsm:text-18 sm:text-24 md:text-32" :class="classes" :href="href">
    {{ text }}
    <svg class="absolute right-0 center-y mr-5 sm:mr-6 md:mr-8 h-3 sm:h-4 md:h-5" fill="none" viewBox="0 0 16 17">
      <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
        <path class="stroke-current" d="M14.286 8.501H1.715"/>
        <path class="stroke-current" d="M9.715 3.93L14.286 8.5l-4.571 4.572"/>
      </g>
    </svg>
  </a>

  <button @click="openLoginForm()" v-else class="fixed lg:hidden z-40 bottom-0 left-0 w-full p-4 xsm:p-5 text-center text-14 xsm:text-18 sm:text-24 md:text-32 focus:outline-none" :class="classes">
    {{ text }}
    <svg class="absolute right-0 center-y mr-5 sm:mr-6 md:mr-8 h-3 sm:h-4 md:h-5" fill="none" viewBox="0 0 16 17">
      <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
        <path class="stroke-current" d="M14.286 8.501H1.715"/>
        <path class="stroke-current" d="M9.715 3.93L14.286 8.5l-4.571 4.572"/>
      </g>
    </svg>
  </button>

</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    href: String,
    classes: String,
    text: String,
  },

  methods: {
    ...mapMutations([`openLoginForm`]),
  }
}
</script>

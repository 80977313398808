<template>
  <div :data-parallax="parallax" class="flex flex-col lg:flex-row justify-between lg:justify-start items-center" :class="classes">
    <div class="hidden lg:block w-1/12"></div>

    <div class="flex flex-1 items-end lg:block lg:flex-none w-8/12 xsm:w-8/12 lg:w-4/12 lg:-mt-10 xl:-mt-12 3xl:-mt-14 4xl:-mt-16 5xl:-mt-18">
      <div data-animation-box class="w-full text-center lg:text-right">
        <div class="mb-4xsm:mb-6 sm:mb-8 md:mb-10 lg:mb-12 xl:mb-14 3xl:mb-16 4xl:mb-18 5xl:mb-20 text-gray-dark">
          <slot name="subtitle"></slot>
        </div>

        <div class="my-5 xsm:my-6 sm:my-10 md:my-12 lg:m-0">
          <div class="lg:flex lg:justify-end mb-2 xsm:mb-3 sm:mb-4 md:mb-5 lg:mb-3 xl:mb-4 3xl:mb-5 4xl:mb-6 5xl:mb-8">
            <slot name="title"></slot>
          </div>

          <slot name="paragraph"></slot>
        </div>
      </div>
    </div>

    <div class="hidden lg:block w-2/12"></div>

    <div class="w-6/12 lg:w-3/12" :class="`text-${imageAlignment}`">
      <slot name="image"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    parallax: Number,
    classes: String,
    imageAlignment: String
  }
}
</script>

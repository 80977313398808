<script>
import { mapMutations } from "vuex";

export default {
  props: {
    fixedButton: Boolean
  },

  methods: {
    ...mapMutations([`openLoginForm`]),
  }
}
</script>

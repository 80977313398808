<template>
  <svg class="hidden lg:block w-full" fill="none" viewBox="0 0 1920 3836">
    <about-lines-04></about-lines-04>
    <about-lines-03></about-lines-03>
    <about-lines-02></about-lines-02>
    <about-lines-01></about-lines-01>
    <defs>
      <filter id="filter0_d" width="432" height="432" x="1316" y="2765" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="10"/>
        <feGaussianBlur stdDeviation="10"/>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.831373 0 0 0 0 0 0 0 0 0.24 0"/>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
      <filter id="filter1_d" width="432" height="432" x="176" y="1537" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="10"/>
        <feGaussianBlur stdDeviation="10"/>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.545098 0 0 0 0 0 0 0 0 0.24 0"/>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
      <filter id="filter2_d" width="432" height="432" x="1316" y="309" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="10"/>
        <feGaussianBlur stdDeviation="10"/>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.831373 0 0 0 0 0 0 0 0 0.24 0"/>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>


import Vue from 'vue'
import './includes/vueConfigs'
import vueGSAPMixin from "./includes/vueGSAPMixin";
import MobileDetect from "mobile-detect";
import InputMask from 'vue-input-mask';
import vSelect from 'vue-select'
import { mapMutations, mapState } from "vuex";
import Scrollbar from "smooth-scrollbar";
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';
import SplitText from 'gsap-bonus/SplitText';
import store from "./store";

vSelect.props.components.default = () => ({
    OpenIndicator: {
        render: createElement => createElement('span',
            {
                domProps: {
                    innerHTML: '<svg class="w-3 md:w-4 lg:w-3 3xl:w-4" viewBox="0 0 16 16" fill="none"><path d="M15 5L8 12L1 5" class="stroke-current" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                }
            }),
    },
})

Vue.component('v-select', vSelect)
Vue.component('input-mask', InputMask)

gsap.registerPlugin(ScrollTrigger, CSSPlugin, SplitText);

/** Register all Vue components */
const files = require.context('./components', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

const vueRoot = document.getElementById('app')
if (vueRoot) {
    new Vue({
        el: vueRoot,
        store,
        mixins: [vueGSAPMixin],

        data() {
            return {
                loaded: false,
                md: new MobileDetect(window.navigator.userAgent),
                bodyScrollBar: null,
                textSplitsObjects: [],
                slideUpTimelines: [],
                slideUpScrollTriggers: [],
                imageShadowScrollTriggers: [],
                fadeUpScrollTriggers: [],
                fadeUpTimelines: [],
            }
        },

        created() {
            window.addEventListener('load', () => {
                this.splitText()
                gsap.set('.box-child', {yPercent: 102})

                if (!this.md.mobile()) {
                    this.smoothScroll()
                }

                for (let key in this.mediaQueries) {
                    this.mediaQueries[key].addListener(() => {
                        this.splitText()

                        if (key === 'lg') {
                            gsap.set('.box-child', {yPercent: 102})

                            setTimeout(() => {
                                this.slideUpAnimations()
                                this.fadeUp()
                            }, 60)
                        }
                    });
                }

                setTimeout(() => {
                    this.slideUpAnimations()
                    this.parallax()
                    this.fadeUp()
                    this.parallaxShadowAnimations()

                    this.loaded = true
                }, 1000)
            });
        },

        computed: {
            ...mapState({
                preloaderMounted: `preloaderMounted`,
            }),
        },

        watch: {
            preloaderMounted() {
                this.$el.removeAttribute('style')
            }
        },

        methods: {
            ...mapMutations([`openLoginForm`]),

            smoothScroll() {
                const $this = this

                $this.bodyScrollBar = Scrollbar.init(document.querySelector('[data-scrollbar]'),
                    {
                        damping: .075,
                        delegateTo: document,
                        renderByPixels: true
                    })

                $this.bodyScrollBar.setPosition(0, 0)
                $this.bodyScrollBar.track.xAxis.element.remove()

                ScrollTrigger.scrollerProxy('body', {
                    scrollTop(value) {
                        if (arguments.length) {
                            $this.bodyScrollBar.scrollTop = value
                        }
                        return $this.bodyScrollBar.scrollTop
                    },
                });

                $this.bodyScrollBar.addListener(ScrollTrigger.update)
            },

            scrollToSection(id, node) {
                let el = id ? document.getElementById(id) : node

                if (this.bodyScrollBar) {
                    this.bodyScrollBar.scrollIntoView(el, {
                        offsetTop: this.$refs.header.$el.clientHeight * 3,
                    })
                } else {
                    const y = el.getBoundingClientRect().top + window.pageYOffset - (this.$refs.header.$el.clientHeight * 2);

                    window.scrollTo({top: y, behavior: 'smooth'});
                }
            },

            parallaxShadowAnimations() {
                const targets = document.querySelectorAll('[data-image-shadow]');

                if (!targets.length) {
                    return;
                }

                if (this.imageShadowScrollTriggers.length > 0) {
                    [...this.imageShadowScrollTriggers].forEach(tl => {
                        tl.kill();
                    });

                    this.imageShadowScrollTriggers = [];
                }

                [...targets].forEach((target, i) => {

                    this.imageShadowScrollTriggers[i] = ScrollTrigger.matchMedia({
                        '(min-width: 960px)': function () {
                            gsap.timeline({
                                scrollTrigger: {
                                    trigger: target,
                                    start: 'top top+=5%',
                                    end: 'bottom top',
                                    scrub: 0.6,
                                }
                            })
                                .to(target,
                                    {
                                        height: '85%',
                                        width: '85%',
                                        ease: 'none'
                                    }, 0)
                        },
                    });
                });
            },

            splitText() {
                const targets = document.querySelectorAll('[data-split-text]');
                if (!targets.length) {
                    return;
                }

                if (this.textSplitsObjects.length > 0) {
                    [...this.textSplitsObjects].forEach(el => {
                        el.revert();
                    });
                }

                [...targets].forEach((el, i) => {
                    // the custom font is with large line height, so the SplitText divs needs adjustment
                    let gap = '';
                    switch (el.tagName) {
                        case 'H1':
                            gap = '-mt-1 sm:-mt-2 5xl:-mt-3';
                            break;
                        case 'H2':
                            gap = 'sm:-mb-1 4xl:-mb-2';
                            break;
                    }

                    // Split it twice so it can add 'overflow-hidden' class to the parent element
                    this.textSplitsObjects[i] = new SplitText(el, {type: 'lines', linesClass: 'box-child'});
                    new SplitText(el, {type: 'lines', linesClass: `overflow-hidden ${gap}`});
                });
            },

            slideUpAnimations() {
                const targets = document.querySelectorAll('[data-animation-box]');

                if (!targets.length) {
                    return;
                }

                if (this.slideUpTimelines.length > 0) {
                    [...this.slideUpTimelines].forEach(tl => {
                        tl.kill();
                    });

                    this.slideUpTimelines = [];
                }

                if (this.slideUpScrollTriggers.length > 0) {
                    [...this.slideUpScrollTriggers].forEach(tl => {
                        tl.kill();
                    });

                    this.slideUpScrollTriggers = [];
                }

                [...targets].forEach((box, i) => {
                    let boxElements = box.querySelectorAll('.box-child');

                    this.slideUpTimelines[i] = gsap.timeline({paused: true})
                        .to(boxElements,
                            0.4,
                            {
                                yPercent: 0,
                                stagger: .1,
                                onComplete: () => {
                                    [...boxElements].forEach(el => {
                                        el.classList.remove('will-change-transform');
                                    });
                                },
                                ease: 'power1',
                            })
                    
                    this.slideUpScrollTriggers[i] = ScrollTrigger.create(
                        {
                            trigger: box,
                            start: 'top bottom-=35%',
                            scrub: false,
                            onEnter: () => {
                                [...boxElements].forEach(el => {
                                    el.classList.add('will-change-transform');
                                });
                                this.slideUpTimelines[i].play();
                            },
                        }
                    );
                });
            },

            fadeUp() {
                // let footer = document.getElementById('footer');
                let time = .8;
                // footer.removeAttribute('data-animate');

                if (!this.mediaQueries['lg'].matches) {
                    time = .4;
                    // footer.setAttribute('data-animate', 'fade-up');
                }

                const targets = document.querySelectorAll('[data-animate="fade-up"]');
                if (!targets.length) {
                    return;
                }

                if (this.fadeUpTimelines.length > 0) {
                    [...this.fadeUpTimelines].forEach(tl => {
                        tl.kill();
                    });

                    this.resetProps(targets);

                    this.fadeUpTimelines = [];
                }

                if (this.fadeUpScrollTriggers.length > 0) {
                    [...this.fadeUpScrollTriggers].forEach(tl => {
                        tl.kill();
                    });

                    this.fadeUpScrollTriggers = [];
                }

                [...targets].forEach((el, i) => {
                    this.fadeUpTimelines[i] = gsap.timeline(
                        {
                            paused: true,
                            onComplete: () => {
                                el.classList.remove('will-change-transform');
                            }
                        })
                        .fromTo(el,
                            time,
                            {
                                yPercent: 15,
                                opacity: 0,
                            },
                            {
                                yPercent: 0,
                                opacity: 1,
                                ease: 'power2'
                            })

                    this.fadeUpScrollTriggers[i] = ScrollTrigger.create(
                        {
                            trigger: el,
                            start: 'top bottom-=35%',
                            scrub: false,
                            onEnter: () => {
                                el.classList.add('will-change-transform');
                                this.fadeUpTimelines[i].play();
                            }
                        }
                    );
                });
            },

            parallax() {
                const targets = document.querySelectorAll('[data-parallax]');
                if (!targets.length) {
                    return;
                }

                [...targets].forEach(el => {
                    const yPercent = parseInt(el.dataset.parallax);

                    ScrollTrigger.matchMedia({
                        "(min-width: 960px)": () => {
                            gsap.to(el, {
                                yPercent: yPercent,
                                // rotation: 0.01,
                                ease: 'none',
                                scrollTrigger: {
                                    trigger: el,
                                    start: 'top center+=5%',
                                    end: `bottom top-=10%`,
                                    scrub: .6,
                                    onEnter: () => {
                                        el.classList.add('will-change-transform');
                                    },
                                    onEnterBack: () => {
                                        el.classList.add('will-change-transform');
                                    },
                                    onLeave: () => {
                                        el.classList.remove('will-change-transform');
                                    },
                                    onLeaveBack: () => {
                                        el.classList.remove('will-change-transform');
                                    }
                                }
                            });
                        },
                    });
                });
            },
        }
    })
}

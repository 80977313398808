<template>
  <g>
    <!-- Routes -->
    <g stroke-linecap="round" stroke-width="32" :stroke="color2">
      <path stroke="#F4F5F6" d="M960.5 18v130.5c0 56.005 0 84.008-10.899 105.399a100.01 100.01 0 01-43.702 43.702C884.508 308.5 856.505 308.5 800.5 308.5H341.75c-55.298 0-82.947 0-104.123 10.633a100.006 100.006 0 00-44.494 44.494C182.5 384.803 182.5 412.452 182.5 467.75v0c0 55.298 0 82.947 10.633 104.123a100.006 100.006 0 0044.494 44.494C258.803 627 286.452 627 341.75 627H800.5c56.005 0 84.008 0 105.399 10.899a100.01 100.01 0 0143.702 43.702C960.5 702.992 960.5 730.995 960.5 787v38c0 56.005 0 84.008-10.899 105.399a100.01 100.01 0 01-43.702 43.702C884.508 985 856.505 985 800.5 985h-458c-56.005 0-84.008 0-105.399 10.899a100.003 100.003 0 00-43.702 43.701C182.5 1060.99 182.5 1088.99 182.5 1145v172.5c0 56.01 0 84.01 10.899 105.4a99.996 99.996 0 0043.702 43.7c21.391 10.9 49.394 10.9 105.399 10.9H877"/>

      <path ref="Route03" data-route="-1" d="M877 1477.5H342.5c-56.005 0-84.008 0-105.399-10.9a99.996 99.996 0 01-43.702-43.7c-10.899-21.39-10.899-49.39-10.899-105.4V1145c0-56.01 0-84.01 10.899-105.4a100.003 100.003 0 0143.702-43.701C258.492 985 286.495 985 342.5 985h458c56.005 0 84.008 0 105.399-10.899a100.01 100.01 0 0043.702-43.702C960.5 909.008 960.5 881.005 960.5 825v-19"/>
      <path ref="Route02" data-route="1" d="M571.5 308.5H341.75c-55.298 0-82.947 0-104.123 10.633a100.006 100.006 0 00-44.494 44.494C182.5 384.803 182.5 412.452 182.5 467.75v0c0 55.298 0 82.947 10.633 104.123a100.006 100.006 0 0044.494 44.494C258.803 627 286.452 627 341.75 627H800.5c56.005 0 84.008 0 105.399 10.899a100.01 100.01 0 0143.702 43.702C960.5 702.992 960.5 730.995 960.5 787v19"/>
      <path ref="Route01" data-route="1" d="M960.5 18v130.5c0 56.005 0 84.008-10.899 105.399a100.01 100.01 0 01-43.702 43.702C884.508 308.5 856.505 308.5 800.5 308.5h-229"/>
    </g>

    <!-- Dots -->
    <g>
      <path ref="MovingDot" :fill="color1" d="M944 16c0 8.837 7.164 16 16 16 8.837 0 16-7.163 16-16 0-8.836-7.163-16-16-16-8.836 0-16 7.164-16 16z"/>
      <path ref="Dot01" :fill="color1" d="M976.461 806c0 8.836-7.226 16-16.139 16s-16.138-7.164-16.138-16 7.225-16 16.138-16c8.913 0 16.139 7.164 16.139 16z"/>
    </g>

    <!-- Label 01 -->
    <g ref="Label01">
      <path :fill="color1" fill-rule="evenodd" d="M1012.49 764c2.24 0 3.36 0 4.22.436.75.383 1.37.995 1.75 1.748.43.856.43 1.976.43 4.216v71.2c0 2.24 0 3.36-.43 4.216-.38.753-1 1.365-1.75 1.748-.86.436-1.98.436-4.22.436H991c-2.24 0-3.361 0-4.216-.436a3.991 3.991 0 01-1.748-1.748c-.436-.856-.436-1.976-.436-4.216v-27.108c0-.811 0-1.217-.076-1.601a4.003 4.003 0 00-.61-1.464c-.22-.325-.508-.61-1.084-1.181-1.319-1.308-1.979-1.963-2.286-2.692a3.99 3.99 0 010-3.108c.307-.729.967-1.384 2.286-2.692.576-.571.864-.856 1.084-1.181.299-.442.506-.94.61-1.464.076-.384.076-.79.076-1.601V770.4c0-2.24 0-3.36.436-4.216a3.991 3.991 0 011.748-1.748C987.639 764 988.76 764 991 764h21.49z"/>
      <text class="font-main-b text-16" fill="#ffffff" opacity=".85" transform="rotate(90 118.92 893.42)">
        <tspan x=".156" y="17.2">PARADA 1</tspan>
      </text>
    </g>

    <!-- Label 02 -->
    <g ref="Label02">
      <path :fill="color1" fill-rule="evenodd" d="M824.221 1417.4c0-2.24 0-3.36.436-4.22.383-.75.995-1.36 1.748-1.74.855-.44 1.975-.44 4.216-.44h75.962c2.241 0 3.361 0 4.216.44.753.38 1.365.99 1.748 1.74.436.86.436 1.98.436 4.22v21.2c0 2.24 0 3.36-.436 4.22a3.973 3.973 0 01-1.748 1.74c-.855.44-1.975.44-4.216.44h-29.428c-.84 0-1.261 0-1.659.08-.492.1-.961.3-1.383.57-.34.22-.638.52-1.235 1.11-1.362 1.35-2.043 2.03-2.807 2.33-.944.37-1.994.37-2.938 0-.764-.3-1.445-.98-2.807-2.33-.597-.59-.895-.89-1.235-1.11a4.083 4.083 0 00-1.383-.57c-.398-.08-.818-.08-1.658-.08h-29.429c-2.241 0-3.361 0-4.216-.44a3.973 3.973 0 01-1.748-1.74c-.436-.86-.436-1.98-.436-4.22v-21.2z"/>
      <text class="font-main-b text-16" fill="#ffffff" opacity=".85">
        <tspan x="841.02" y="1434.2">DESTINO</tspan>
      </text>
    </g>

    <!-- FX -->
    <g>
      <!-- Moving Text-->
      <text ref="MovingText" class="font-main-b text-16" fill="#ffffff" opacity=".85">
        <tspan x="739.714" y="1483.2">LLEGANDO EN 1 MIN</tspan>
      </text>

      <!-- FX Lines -->
      <rect ref="FxLine01" width="76.659" height="6" fill="#F4F5F6" rx="3" transform="matrix(-1 0 0 1 763.703 1441)"/>
      <path ref="FxLine02" fill="#F4F5F6" d="M811.109 1519c0-1.66-1.343-3-3-3h-40.398c-1.657 0-3 1.34-3 3s1.343 3 3 3h40.398c1.657 0 3-1.34 3-3z"/>
    </g>

    <!-- Tunnel -->
    <g>
      <path fill="#E5E6EB" d="M976.5 34.355v51.29c20.329-3.553 35-13.692 35-25.645s-14.671-22.092-35-25.645zm-32 0v51.29c-20.329-3.553-35-13.692-35-25.645s14.671-22.092 35-25.645z"/>
      <path fill="#fff" d="M981.5 35.388V0h-42v35.388c6.404-1.534 13.514-2.388 21-2.388 7.486 0 14.596.854 21 2.388z"/>
      <path fill="#000" d="M960 17.5V14h1v3.5h3.5v1H961V22h-1v-3.5h-3.5v-1h3.5z" opacity=".1"/>
    </g>
  </g>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';
import ExpoScaleEase from 'gsap/EasePack';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin, ExpoScaleEase);

export default {
  mixins: [vueGSAPMixin],

  props: {
    color1: String,
    color2: String,
  },

  data() {
    return {
      linesTimeline: null,
      linesScrollTrigger: null,
      linesLabelTimeline01: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetRoutes(this.$el);

      setTimeout(() => {
        this.animateLines();
      }, 1000)
    });
  },

  methods: {
    animateLines() {
      let direction = 1;

      this.linesLabelTimeline01 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot01,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot01,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label01,
              0.3,
              {
                xPercent: 100,
                opacity: 0,
              },
              {
                xPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          );

      this.linesTimeline = gsap.timeline()
          .fromTo(this.$refs.MovingDot,
              0.1,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route01,
                  align: this.$refs.Route01,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0.1
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              0.1
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()} ${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route02,
                  align: this.$refs.Route02,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              1.1
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline01.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline01.reverse();
                }
              },
              2.1
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()} ${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route03,
                  align: this.$refs.Route03,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              2.1
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.MovingText,
              0.1,
              {
                opacity: .85,
              },
              2.87
          )
          .fromTo(this.$refs.MovingText,
              0.23,
              {
                xPercent: -432,
              },
              {
                xPercent: -15,
                ease: 'none'
              },
              2.87
          )
          .fromTo(this.$refs.Label02,
              0.2,
              {
                x: 8,
                yPercent: -150,
                opacity: 0,
              },
              {
                x: 8,
                yPercent: 0,
                opacity: 1,
                ease: 'none'
              },
              2.9
          );

      ScrollTrigger.matchMedia({
        "(min-width: 960px)": () => {
          ScrollTrigger.create({
            trigger: this.$refs.Route01,
            start: 'top bottom-=20%',
            endTrigger: this.$refs.Route03,
            end: 'bottom center+=12%',
            scrub: 0.6,
            animation: this.linesTimeline,
            onUpdate: self => {
              if (self.direction === direction) {
                return;
              }
              direction = self.direction;
            },
          });
        },
      });
    },
  }
}
</script>


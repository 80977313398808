<template>
  <div data-animation-box class="flex flex-col items-center w-full h-full py-8 xsm:py-12 sm:py-14 md:py-16 lg:p-0 bg-white border border-gray-mid rounded rounded-4xl lg:border-none text-center">

    <div class="mb-6 xsm:mb-10 sm:mb-14 md:mb-16 lg:mb-4 xl:mb-6 3xl:mb-8 4xl:mb-10 5xl:mb-12">
      <slot name="icon"></slot>
    </div>

    <div class="inline-block w-10/12 lg:w-full mb-6 xsm:mb-8 sm:mb-10 md:mb-12 lg:mb-3 xl:mb-4 3xl:mb-5 5xl:mb-8">
      <slot name="heading"></slot>
    </div>

    <div class="w-10/12 lg:w-full">
      <slot name="paragraph"></slot>
    </div>
  </div>
</template>

<script>
// TODO transform to inline template ^^
export default {
}
</script>

<template>
  <svg class="w-full" fill="none" viewBox="0 0 636 749">
    <!-- Routes -->
    <g stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
      <path id="LINE 01_2" stroke="#F4F5F6" d="M320 735V572c0-33.137-26.863-60-60-60H72.425c-33.137 0-60-26.863-60-60v-47c0-33.137 26.863-60 60-60H563.927c33.137 0 60-26.863 60-60V239.222c0-33.137-26.863-60-60-60h-437.08c-33.137 0-60-26.863-60-60V72.574c0-33.137 26.863-60 60-60H320"/>
      <path id="LINE 01-01" :stroke="color1" d="M624 262.619v-23.324c0-33.137-26.863-60-60-60H320"/>
      <path id="LINE 01-02" :stroke="color2" d="M103.048 345.943h435.231"/>
    </g>

    <!-- Tunnel -->
    <g fill-rule="evenodd" clip-path="url(#hero-tunnel-clip)" clip-rule="evenodd">
      <path fill="#E5E6EB" d="M332 722.866v-39.974c15.668 2.725 27 10.643 27 19.987 0 9.343-11.332 17.262-27 19.987zm-.074-39.987a70.28 70.28 0 00-11.926-1c-4.16 0-8.167.351-11.926 1h23.852zm-23.926.013v39.974c-15.668-2.725-27-10.644-27-19.987 0-9.344 11.332-17.262 27-19.987z"/>
      <path fill="#ffffff" d="M336 722.036v26.843h-32v-26.843c4.882 1.184 10.298 1.843 16 1.843s11.118-.659 16-1.843z"/>
    </g>

    <!-- Dots -->
    <g>
      <circle id="DOT" cx="624" cy="263" r="12" :fill="color2"/>
      <circle id="DOT_2" cx="103" cy="346" r="12" :fill="color1"/>
    </g>

    <defs>
      <clipPath id="hero-tunnel-clip">
        <path fill="#fff" d="M0 0h78v66H0z" transform="matrix(1 0 0 -1 281 748.879)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    color1: String,
    color2: String,
  },
}
</script>

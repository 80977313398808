// Map number x from range [a, b] to [c, d]
const map = (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c;

// Linear interpolation
const lerp = (start, end, amt) => (1 - amt) * start + amt * end;

// Gets the mouse position
const getMousePos = (e, bodyScrollBar) => {
    let posX = 0;
    let posY = 0;
    if (!e) e = window.event;

    if (bodyScrollBar) {
        posX = e.pageX + bodyScrollBar.scrollLeft;
        posY = e.pageY + bodyScrollBar.scrollTop;
    }
    else if (e.pageX || e.pageY) {
        posX = e.pageX;
        posY = e.pageY;
    }
    else if (e.clientX || e.clientY)    {
        posX = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        posY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }
    
    return { x : posX, y : posY }
};

const distance = (x1,y1,x2,y2) => {
    let a = x1 - x2;
    let b = y1 - y2;

    return Math.hypot(a,b);
}

// Generate a random float.
const getRandomFloat = (min, max) => (Math.random() * (max - min) + min).toFixed(2);

export { 
    map, 
    lerp, 
    getMousePos,
    distance,
    getRandomFloat
};

<template>
  <svg class="w-full" viewBox="0 0 124 125" fill="none">
    <path d="M112.375 112.906C112.375 114.962 111.558 116.933 110.105 118.386C108.652 119.84 106.68 120.656 104.625 120.656H19.375C17.3196 120.656 15.3483 119.84 13.8949 118.386C12.4415 116.933 11.625 114.962 11.625 112.906V12.1562C11.625 10.1008 12.4415 8.12958 13.8949 6.67617C15.3483 5.22277 17.3196 4.40625 19.375 4.40625H74.2915C76.3455 4.40669 78.3153 5.22249 79.7682 6.67442L110.107 37.0131C111.559 38.4659 112.375 40.4358 112.375 42.4898V112.906Z" stroke="#381CA0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M112.375 43.1562H81.375C79.3196 43.1562 77.3483 42.3397 75.8949 40.8863C74.4415 39.4329 73.625 37.4617 73.625 35.4062V4.40625" stroke="#381CA0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M29.1507 48.8095C30.2687 50.2801 31.7253 51.4591 33.3966 52.2461C35.0679 53.0332 36.9045 53.4052 38.7503 53.3303C44.6352 53.3303 49.4092 49.7498 49.4092 45.3375C49.4092 40.9252 44.6352 37.3499 38.7503 37.3499C32.8655 37.3499 28.0967 33.7694 28.0967 29.3519C28.0967 24.9344 32.8655 21.359 38.7503 21.359C40.5962 21.2842 42.4328 21.6561 44.1041 22.4432C45.7754 23.2303 47.232 24.4092 48.35 25.8799" stroke="#7663BE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M38.75 53.3301V58.6569" stroke="#7663BE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M38.75 16.0312V21.3581" stroke="#7663BE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M27.125 74.1562H96.875V105.156H27.125V74.1562Z" stroke="#381CA0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M27.125 89.6562H96.875" stroke="#381CA0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M50.375 74.1562V105.156" stroke="#381CA0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M73.625 74.1562V105.156" stroke="#381CA0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {}
</script>

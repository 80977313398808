<template>
    <g>
      <!--  Paths -->
      <g stroke-linecap="round" stroke-width="32" stroke="#00CD69">
        <path stroke="#F4F5F6" d="M960 18v130.5c0 56.005 0 84.008 10.899 105.399a100.014 100.014 0 0043.701 43.702c21.39 10.899 49.39 10.899 105.4 10.899h458.75c55.3 0 82.95 0 104.12 10.633a99.994 99.994 0 0144.5 44.494C1738 384.803 1738 412.452 1738 467.75v0c0 55.298 0 82.947-10.63 104.123a99.994 99.994 0 01-44.5 44.494C1661.7 627 1634.05 627 1578.75 627H1120c-56.01 0-84.01 0-105.4 10.899a100.014 100.014 0 00-43.701 43.702C960 702.992 960 730.995 960 787v38c0 56.005 0 84.008 10.899 105.399a100.014 100.014 0 0043.701 43.702C1035.99 985 1063.99 985 1120 985h458c56.01 0 84.01 0 105.4 10.899a99.989 99.989 0 0143.7 43.701c10.9 21.39 10.9 49.39 10.9 105.4v172.5c0 56.01 0 84.01-10.9 105.4a99.981 99.981 0 01-43.7 43.7c-21.39 10.9-49.39 10.9-105.4 10.9h-534.5"/>

        <path ref="Route03" data-route="-1" d="M1043.5 1477.5H1578c56.01 0 84.01 0 105.4-10.9a99.981 99.981 0 0043.7-43.7c10.9-21.39 10.9-49.39 10.9-105.4V1145c0-56.01 0-84.01-10.9-105.4a99.989 99.989 0 00-43.7-43.701C1662.01 985 1634.01 985 1578 985h-458c-56.01 0-84.01 0-105.4-10.899a100.014 100.014 0 01-43.701-43.702C960 909.008 960 881.005 960 825v-19"/>
        <path ref="Route02" data-route="1" d="M1349 308.5h229.75c55.3 0 82.95 0 104.12 10.633a99.994 99.994 0 0144.5 44.494C1738 384.803 1738 412.452 1738 467.75v0c0 55.298 0 82.947-10.63 104.123a99.994 99.994 0 01-44.5 44.494C1661.7 627 1634.05 627 1578.75 627H1120c-56.01 0-84.01 0-105.4 10.899a100.014 100.014 0 00-43.701 43.702C960 702.992 960 730.995 960 787v19"/>
        <path ref="Route01" data-route="1" d="M960 18v130.5c0 56.005 0 84.008 10.899 105.399a100.014 100.014 0 0043.701 43.702c21.39 10.899 49.39 10.899 105.4 10.899h229"/>
      </g>

      <!-- Dots -->
      <g>
        <path ref="MovingDot" fill="#00145B" d="M944 16c0 8.837 7.164 16 16 16 8.837 0 16-7.163 16-16 0-8.836-7.163-16-16-16-8.836 0-16 7.164-16 16z"/>
        <path ref="Dot01" fill="#00145B" d="M944 806c0 8.836 7.164 16 16 16 8.837 0 16-7.164 16-16s-7.163-16-16-16c-8.836 0-16 7.164-16 16z"/>
      </g>

      <!-- Label 01 -->
      <g ref="Label01">
        <path fill="#00145B" d="M900.4 848c-2.24 0-3.36 0-4.216-.436a3.991 3.991 0 01-1.748-1.748C894 844.96 894 843.84 894 841.6v-71.2c0-2.24 0-3.36.436-4.216a3.991 3.991 0 011.748-1.748c.856-.436 1.976-.436 4.216-.436h21.2c2.24 0 3.36 0 4.216.436a3.991 3.991 0 011.748 1.748c.436.856.436 1.976.436 4.216v27.115c0 .815 0 1.222.077 1.609a3.98 3.98 0 00.597 1.441c.219.328.507.616 1.083 1.192 1.318 1.318 1.977 1.977 2.282 2.712.406.98.406 2.082 0 3.062-.305.735-.964 1.394-2.282 2.712-.576.576-.864.864-1.083 1.192a3.98 3.98 0 00-.597 1.441c-.077.387-.077.794-.077 1.609V841.6c0 2.24 0 3.36-.436 4.216a3.991 3.991 0 01-1.748 1.748c-.856.436-1.976.436-4.216.436h-21.2z"/>
        <text class="font-main-b text-16" fill="#ffffff" opacity=".85" transform="rotate(-90 869 -31.5)">
          <tspan x=".156" y="17.2">PARADA 1</tspan>
        </text>
      </g>

      <!-- Label 02 -->
      <g ref="Label02">
        <path fill="#00145B" d="M999 1417.4c0-2.24 0-3.36.436-4.22a3.98 3.98 0 011.744-1.74c.86-.44 1.98-.44 4.22-.44h75.2c2.24 0 3.36 0 4.22.44.75.38 1.36.99 1.74 1.74.44.86.44 1.98.44 4.22v21.2c0 2.24 0 3.36-.44 4.22-.38.75-.99 1.36-1.74 1.74-.86.44-1.98.44-4.22.44h-29.11c-.82 0-1.23 0-1.61.08-.52.1-1.01.3-1.45.59-.32.22-.61.51-1.19 1.09-1.32 1.32-1.97 1.97-2.71 2.28-.98.4-2.08.4-3.06 0-.74-.31-1.39-.96-2.71-2.28-.58-.58-.87-.87-1.19-1.09-.44-.29-.93-.49-1.45-.59-.38-.08-.79-.08-1.61-.08h-29.11c-2.24 0-3.36 0-4.22-.44a3.98 3.98 0 01-1.744-1.74c-.436-.86-.436-1.98-.436-4.22v-21.2z"/>
        <text class="font-main-b text-16" fill="#ffffff" opacity=".85">
          <tspan x="1015.41" y="1434.2">DESTINO</tspan>
        </text>
      </g>

      <!-- FX -->
      <g>
        <!-- Moving Text-->
        <text ref="MovingText" class="font-main-b text-16 opacity-0" fill="#ffffff" opacity=".85">
          <tspan x="1043" y="1483.2">LLEGANDO EN 1 MIN</tspan>
        </text>

        <!-- FX Lines -->
        <rect ref="FxLine01" width="76" height="6" x="1147" y="1441" fill="#F4F5F6" rx="3"/>
        <path ref="FxLine02" fill="#F4F5F6" d="M1100 1519c0-1.66 1.34-3 3-3h40c1.66 0 3 1.34 3 3s-1.34 3-3 3h-40c-1.66 0-3-1.34-3-3z"/>
      </g>

      <!-- Tunnel -->
      <g>
        <path fill="#E5E6EB" d="M944 34.355v51.29C923.671 82.091 909 71.952 909 60s14.671-22.092 35-25.645zm32 0v51.29c20.329-3.553 35-13.692 35-25.645s-14.671-22.092-35-25.645z"/>
        <path fill="#fff" d="M939 35.388V0h42v35.388C974.596 33.854 967.486 33 960 33c-7.486 0-14.596.854-21 2.388z"/>
      </g>
    </g>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';
import ExpoScaleEase from 'gsap/EasePack';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin, ExpoScaleEase);

export default {
  mixins: [vueGSAPMixin],

  data() {
    return {
      linesTimeline: null,
      linesScrollTrigger: null,
      linesLabelTimeline01: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetRoutes(this.$el);

      setTimeout(() => {
        this.animateLines();
      }, 1000)
    });
  },

  methods: {
    animateLines() {
      let direction = 1;

      this.linesLabelTimeline01 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot01,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot01,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label01,
              0.3,
              {
                xPercent: -100,
                opacity: 0,
              },
              {
                xPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          );

      this.linesTimeline = gsap.timeline()
          .fromTo(this.$refs.MovingDot,
              0.1,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route01,
                  align: this.$refs.Route01,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0.1
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              0.1
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()} ${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route02,
                  align: this.$refs.Route02,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              1.1
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline01.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline01.reverse();
                }
              },
              2.1
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()} ${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route03,
                  align: this.$refs.Route03,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              2.1
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.MovingText,
              0.1,
              {
                opacity: .85,
              },
              2.87
          )
          .fromTo(this.$refs.MovingText,
              0.23,
              {
                xPercent: 432,
              },
              {
                xPercent: 20,
                ease: 'none'
              },
              2.87
          )
          .fromTo(this.$refs.Label02,
              0.2,
              {
                yPercent: -150,
                opacity: 0,
              },
              {
                yPercent: 0,
                opacity: 1,
                ease: 'none'
              },
              2.9
          );

      ScrollTrigger.matchMedia({
        "(min-width: 960px)": () => {
          ScrollTrigger.create({
            trigger: this.$refs.Route01,
            start: 'top bottom-=20%',
            endTrigger: this.$refs.Route03,
            end: 'bottom center+=12%',
            scrub: 0.6,
            animation: this.linesTimeline,
            onUpdate: self => {
              if (self.direction === direction) {
                return;
              }
              direction = self.direction;
            },
          });
        },
      });
    },
  }
}
</script>


<template>
  <svg id="linesS01" class="hidden lg:block" width="100%" height="100%" fill="none" viewBox="0 0 1920 1095">
    <!-- Routes -->
    <g stroke-linecap="round" stroke-width="32" :stroke="color2">
      <path stroke="#F4F5F6" d="M960 1077V783c0-55.228 44.77-100 100-100h744c55.23 0 100-44.772 100-100v-72c0-55.228-44.77-100-100-100h-744c-55.23 0-100-44.772-100-100V21"/>

      <path ref="Route03" data-route="-1" d="M960 1077V818"/>
      <path ref="Route02" data-route="-1" d="M960 818v-35c0-55.228 44.77-100 100-100h744c55.23 0 100-44.772 100-100v-72c0-55.228-44.77-100-100-100h-372"/>
      <path ref="Route01" data-route="1" d="M960 21v290c0 55.228 44.77 100 100 100h372"/>
    </g>

    <!-- Dots -->
    <g :fill="color1">
      <path ref="MovingDot" d="M960 0c-8.836 0-16 7.164-16 16 0 8.837 7.164 16 16 16 8.837 0 16-7.163 16-16 0-8.836-7.163-16-16-16z"/>
    </g>

    <!-- Label -->
    <g ref="Label">
      <path ref="LabelBg" :fill="color1" fill-rule="evenodd" d="M902.2 863c-2.24 0-3.26 0-4.216-.236a3.991 3.991 0 01-1.748-1.748C896 859.96 896 858.84 896 856.6v-75.2c0-2.24 0-3.26.236-4.216a3.991 3.991 0 011.748-1.748c.856-.236 1.976-.236 4.216-.236h21.2c2.24 0 3.26 0 4.216.236a3.991 3.991 0 011.748 1.748c.236.856.236 1.976.236 4.216v29.115c0 .815 0 1.222.077 1.609a3.98 3.98 0 00.597 1.241c.219.228.507.616 1.083 1.192 1.218 1.218 1.977 1.977 2.282 2.712.206.98.206 2.082 0 3.062-.205.735-.964 1.294-2.282 2.712-.576.576-.864.864-1.083 1.192a3.98 3.98 0 00-.597 1.241c-.077.287-.077.794-.077 1.609V856.6c0 2.24 0 3.26-.236 4.216a3.991 3.991 0 01-1.748 1.748c-.856.236-1.976.236-4.216.236h-21.2z" clip-rule="evenodd"/>
      <text class="font-main-b text-18" fill="#ffffff" opacity=".85" transform="rotate(-90 869.25 -32.75)">
        <tspan x=".267" y="17.2">PARE</tspan>
      </text>
    </g>

    <!-- Tunnel Top -->
    <g>
      <path fill="#E5E6EB" d="M976 1060.64v-51.29c20.229 3.55 35 13.69 35 25.65 0 11.95-14.671 22.09-35 25.64zm-32 0v-51.29c-20.229 3.55-35 13.69-35 25.65 0 11.95 14.671 22.09 35 25.64z"/>
      <path fill="#fff" d="M981 1059.61V1095h-42v-35.29c6.204 1.53 13.514 2.29 21 2.29 7.286 0 14.596-.86 21-2.29z"/>
    </g>

    <!-- Tunnel Bottom -->
    <g>
      <path fill="#E5E6EB" d="M944 34.255v51.29C923.671 82.091 909 71.952 909 60s14.671-22.092 35-25.645zm32 0v51.29c20.229-3.553 35-13.692 35-25.645s-14.671-22.092-35-25.645z"/>
      <path fill="#fff" d="M939 35.288V0h42v35.288C974.596 33.854 967.286 33 960 33c-7.286 0-14.596.854-21 2.288z"/>
    </g>

    <!-- Blink Fx -->
    <g v-if="blink" ref="BlinkFx" stroke-linecap="round">
      <path ref="BlinkFxLine01" stroke="#F4F5F6" stroke-width="24" d="M1502.63 108.836l-6.65-40.706"/>
      <path ref="BlinkFxLine02" stroke="#F4F5F6" stroke-width="8" d="M1564.03 73.267l7.85-37.266"/>
      <path ref="BlinkFxLine03" :stroke="color2" stroke-width="24" d="M1583.11 136.267L1665 59.686"/>
      <path ref="BlinkFxLine04" :stroke="color1" stroke-width="8" d="M1643 170.197l21.64-2.212"/>
      <path ref="BlinkFxLine05" :stroke="color1" stroke-width="24" d="M1611.29 238.938l41.19 2.129"/>
    </g>
  </svg>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin);

export default {
  mixins: [vueGSAPMixin],

  props: {
    color1: String,
    color2: String,
    blink: Boolean
  },

  data() {
    return {
      linesIntroTimeline: null,
      linesScrollTimeline: null,
      linesScrollTrigger: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetRoutes(this.$el);
      this.createTimelines();

      this.mediaQueries['lg'].addListener(() => {
        this.resetTimelines();
        this.createTimelines();
        this.playTimelines();
      });

      setTimeout(() => {
        this.playTimelines();
      }, 1000);
    });
  },

  methods: {
    createTimelines() {
      if (this.mediaQueries['lg'].matches) {
        this.linesAnimations();
      }
    },

    playTimelines() {
      if (this.mediaQueries['lg'].matches) {
        this.linesIntroTimeline.play();
      }
    },

    resetTimelines() {
      this.linesIntroTimeline && this.linesIntroTimeline.kill();
      this.linesScrollTimeline && this.linesScrollTimeline.kill();
      this.linesScrollTrigger && this.linesScrollTrigger.kill();

      this.linesScrollTimeline = null;
      this.linesIntroTimeline = null;
      this.linesScrollTrigger = null;

      this.resetRoutes(this.$el);
    },

    linesAnimations() {
      const $this = this;

      this.linesScrollTimeline = gsap.timeline({paused: true})
          .to(this.$refs.Label,
              0.2,
              {
                xPercent: -100,
                opacity: 0,
              },
              0
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength() * -1}`,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route03,
                  align: this.$refs.Route03,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()}`,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength() * -1}`,
                ease: 'none'
              },
              1
          )
          .to(this.$refs.MovingDot,
              {
                scale: 0,
                duration: 0.1,
                ease: 'none'
              },
              1
          );

      this.linesIntroTimeline = gsap.timeline(
          {
            paused: true,
            onComplete: () => this.linesScrollTrigger = ScrollTrigger.create(
                {
                  trigger: $this.$el,
                  start: 'bottom bottom-=15%',
                  end: 'bottom center-=15%',
                  scrub: 0.6,
                  animation: this.linesScrollTimeline,
                }
            )
          })
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route01,
                  align: this.$refs.Route01,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: .25,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.Route01,
              .25,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()}`,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.Route01,
              .25,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()} ${this.$refs.Route01.getTotalLength()}`,
                ease: 'none'
              },
              0.25
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route02,
                  align: this.$refs.Route02,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: .25,
                ease: 'none'
              },
              0.25
          )
          .to(this.$refs.Route02,
              {
                duration: .25,
                drawSVG: `${this.$refs.Route02.getTotalLength()}`,
                ease: 'none'
              },
              0.25
          )
          .fromTo(this.$refs.Label,
              0.5,
              {
                xPercent: -100,
                opacity: 0,
              },
              {
                xPercent: 0,
                opacity: 1,
                ease: 'Elastic.easeOut'
              },
              0.5
          )

          if (this.blink) {
            this.linesIntroTimeline
                .fromTo(this.$refs.BlinkFxLine01,
                    .6,
                    {
                      scale: .7,
                      xPercent: 300,
                      yPercent: 70,
                      opacity: 0,
                    },
                    {
                      scale: 1,
                      xPercent: 0,
                      yPercent: 0,
                      opacity: 1,
                      ease: 'Elastic.easeOut'
                    },
                    0.5
                )
                .fromTo(this.$refs.BlinkFxLine02,
                    .6,
                    {
                      scale: .7,
                      xPercent: 200,
                      yPercent: 150,
                      opacity: 0,
                    },
                    {
                      scale: 1,
                      xPercent: 0,
                      yPercent: 0,
                      opacity: 1,
                      ease: 'Elastic.easeOut'
                    },
                    0.5
                )
                .fromTo(this.$refs.BlinkFxLine03,
                    .6,
                    {
                      scale: .7,
                      xPercent: -150,
                      yPercent: 150,
                      opacity: 0,
                    },
                    {
                      scale: 1,
                      xPercent: 0,
                      yPercent: 0,
                      opacity: 1,
                      ease: 'Elastic.easeOut'
                    },
                    0.5
                )
                .fromTo(this.$refs.BlinkFxLine04,
                    .6,
                    {
                      scale: .7,
                      xPercent: -200,
                      yPercent: 70,
                      opacity: 0,
                    },
                    {
                      scale: 1,
                      xPercent: 0,
                      yPercent: 0,
                      opacity: 1,
                      ease: 'Elastic.easeOut'
                    },
                    0.5
                )
                .fromTo(this.$refs.BlinkFxLine05,
                    .6,
                    {
                      scale: .7,
                      xPercent: -300,
                      yPercent: 150,
                      opacity: 0,
                    },
                    {
                      scale: 1,
                      xPercent: 0,
                      yPercent: 0,
                      opacity: 1,
                      ease: 'Elastic.easeOut'
                    },
                    0.5
                )
          }
    },
  }
}
</script>

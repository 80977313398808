<template>
  <svg class="w-full" viewBox="0 0 124 123" fill="none">
    <path d="M29.0625 55.7187C29.0625 57.4998 29.4133 59.2634 30.0949 60.9089C30.7765 62.5544 31.7755 64.0495 33.0349 65.3089C34.2943 66.5683 35.7894 67.5673 37.4349 68.2489C39.0803 68.9304 40.8439 69.2812 42.625 69.2812C44.4061 69.2812 46.1697 68.9304 47.8151 68.2489C49.4606 67.5673 50.9557 66.5683 52.2151 65.3089C53.4745 64.0495 54.4735 62.5544 55.1551 60.9089C55.8367 59.2634 56.1875 57.4998 56.1875 55.7187C56.1875 53.9377 55.8367 52.1741 55.1551 50.5286C54.4735 48.8831 53.4745 47.388 52.2151 46.1286C50.9557 44.8692 49.4606 43.8702 47.8151 43.1886C46.1697 42.5071 44.4061 42.1562 42.625 42.1562C40.8439 42.1562 39.0803 42.5071 37.4349 43.1886C35.7894 43.8702 34.2943 44.8692 33.0349 46.1286C31.7755 47.388 30.7765 48.8831 30.0949 50.5286C29.4133 52.1741 29.0625 53.9377 29.0625 55.7187V55.7187Z" stroke="#7663BE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.375 96.4062C19.375 90.24 21.8245 84.3262 26.1848 79.966C30.545 75.6058 36.4587 73.1562 42.625 73.1562C48.7913 73.1562 54.705 75.6058 59.0652 79.966C63.4254 84.3262 65.875 90.24 65.875 96.4062H19.375Z" stroke="#7663BE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M73.625 57.6562H96.875" stroke="#381CA0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M73.625 73.1562H104.625" stroke="#381CA0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M112.375 26.6562H11.625C9.56957 26.6562 7.59833 27.4728 6.14492 28.9262C4.69152 30.3796 3.875 32.3508 3.875 34.4062V111.906C3.875 113.962 4.69152 115.933 6.14492 117.386C7.59833 118.84 9.56957 119.656 11.625 119.656H27.125C27.125 117.601 27.9415 115.63 29.3949 114.176C30.8483 112.723 32.8196 111.906 34.875 111.906C36.9304 111.906 38.9017 112.723 40.3551 114.176C41.8085 115.63 42.625 117.601 42.625 119.656H81.375C81.375 117.601 82.1915 115.63 83.6449 114.176C85.0983 112.723 87.0696 111.906 89.125 111.906C91.1804 111.906 93.1517 112.723 94.6051 114.176C96.0585 115.63 96.875 117.601 96.875 119.656H112.375C114.43 119.656 116.402 118.84 117.855 117.386C119.308 115.933 120.125 113.962 120.125 111.906V34.4062C120.125 32.3508 119.308 30.3796 117.855 28.9262C116.402 27.4728 114.43 26.6562 112.375 26.6562Z" stroke="#381CA0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M120.125 11.1562C120.125 9.10082 119.308 7.12958 117.855 5.67617C116.402 4.22277 114.43 3.40625 112.375 3.40625H11.625C9.56957 3.40625 7.59833 4.22277 6.14492 5.67617C4.69152 7.12958 3.875 9.10082 3.875 11.1562" stroke="#7663BE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {}
</script>

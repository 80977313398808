<template>
  <aside class="hidden sm:flex fixed bottom-0 left-0 items-end w-full pointer-events-none"
         :class="[fixedButton ? 'sm:mb-24 md:mb-30 lg:mb-8 4xl:mb-12' : 'mb-8 4xl:mb-12']">
    <div class="relative flex flex-col items-center flex-1 text-gray-dark pointer-events-auto">
      <div data-bottom-line class="absolute center-x bottom-full -ml-2 transform -scale-1 whitespace-no-wrap writing-mode-vertical-rl text-10 4xl:text-12 5xl:text-16 tracking-widest">
        <span v-if="social">Síguenos</span>
        <span v-else>Descargá la app</span>
      </div>

      <div data-cursor-zone="diff" data-cursor-large class="relative z-50 flex flex-col items-center justify-center p-4 4xl:p-6 5xl:p-8 -mb-4 4xl:-mb-6 5xl:-mb-8">

        <div v-if="social" data-bottom-line>
          <a data-magnet
             data-magnet-type="fixed"
             href="https://www.facebook.com/app.muv"
             target="_blank"
             title="Facebook"
             class="bottom-line-icon inline-flex items-center mb-4 4xl:mb-6 5xl:mb-8 pointer-events-auto">
            <svg class="w-8/12 mx-auto transition-all duration-200" viewBox="0 0 14 23" fill="none">
              <path class="fill-current" d="M4.2321 22.4L4.2 12.6H0V8.4H4.2V5.6C4.2 1.82112 6.54011 0 9.91113 0C11.5259 0 12.9137 0.120218 13.3181 0.17395V4.1231L10.9801 4.12416C9.1468 4.12416 8.79182 4.99534 8.79182 6.27374V8.4H14L12.6 12.6H8.7918V22.4H4.2321Z"/>
            </svg>
          </a>
        </div>

        <div v-else data-bottom-line>
          <a data-magnet
             data-magnet-type="fixed"
             :href="googlePlayUrl"
             target="_blank"
             title="Download from Google Play Store"
             class="bottom-line-icon inline-flex items-center mb-4 4xl:mb-6 5xl:mb-8 pointer-events-auto">
            <svg class="w-full transition-all duration-200" viewBox="0 0 25 33">
              <g class="fill-current" fill-rule="evenodd">
                <path d="M4.119 25.014c0 .569.461 1.03 1.03 1.03h2.06v4.634a1.545 1.545 0 003.089 0v-4.634h4.119v4.634a1.545 1.545 0 003.089 0v-4.634h2.06a1.03 1.03 0 001.03-1.03V11.627H4.118v13.387z"/>
                <path d="M1.545 11.627C.692 11.627 0 12.32 0 13.172v7.208a1.545 1.545 0 003.09 0v-7.208c0-.853-.693-1.545-1.545-1.545z"/>
                <path d="M23.17 11.627c-.853 0-1.545.692-1.545 1.545v7.208a1.545 1.545 0 003.09 0v-7.208c0-.853-.693-1.545-1.545-1.545z"/>
                <path d="M16.634 3.566l1.3-1.951a.515.515 0 10-.857-.57L15.72 3.082a8.185 8.185 0 00-6.724 0L7.637 1.042a.515.515 0 00-.857.571l1.3 1.951C5.708 5.012 4.12 7.616 4.12 10.598h16.476a8.224 8.224 0 00-3.961-7.032zM9.268 7.508a1.03 1.03 0 110-2.06 1.03 1.03 0 010 2.06zm6.178 0a1.03 1.03 0 11.001-2.06 1.03 1.03 0 010 2.06z"/>
              </g>
            </svg>
          </a>
        </div>

        <div v-if="social" data-bottom-line>
          <a data-magnet
             data-magnet-type="fixed"
             href="https://www.instagram.com/app.muv/?hl=es-la"
             target="_blank"
             title="Facebook"
             class="bottom-line-icon inline-block pointer-events-auto">
            <svg class="w-full transition-all duration-200" viewBox="0 0 24 23" fill="none">
              <g class="fill-current">
                <circle cx="17.403" cy="5.44886" r="1.344"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.99999 11.2C5.99999 14.2884 8.51159 16.8 11.6 16.8C14.6884 16.8 17.2 14.2884 17.2 11.2C17.2 8.11158 14.6884 5.59998 11.6 5.59998C8.51159 5.59998 5.99999 8.11158 5.99999 11.2ZM8.79999 11.2C8.79999 9.65578 10.0558 8.39998 11.6 8.39998C13.1442 8.39998 14.4 9.65578 14.4 11.2C14.4 12.7442 13.1442 14 11.6 14C10.0558 14 8.79999 12.7442 8.79999 11.2Z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.99999 22.4H17.2C20.0784 22.4 22.8 19.6784 22.8 16.8V5.6C22.8 2.7216 20.0784 0 17.2 0H5.99999C3.12159 0 0.399994 2.7216 0.399994 5.6V16.8C0.399994 19.6784 3.12159 22.4 5.99999 22.4ZM3.19999 5.6C3.19999 4.291 4.69099 2.8 5.99999 2.8H17.2C18.509 2.8 20 4.291 20 5.6V16.8C20 18.109 18.509 19.6 17.2 19.6H5.99999C4.66579 19.6 3.19999 18.1342 3.19999 16.8V5.6Z"/>
              </g>
            </svg>
          </a>
        </div>

        <div v-else data-bottom-line>
          <a data-magnet
             data-magnet-type="fixed"
             :href="appStoreUrl"
             target="_blank"
             title="Download from Apple App Store"
             class="bottom-line-icon inline-block pointer-events-auto">
            <svg class="w-full transition-all duration-200" viewBox="0 0 26 32">
              <g class="fill-current" fill-rule="evenodd">
                <path d="M25.101 22.903c-.686 1.503-1.013 2.174-1.897 3.503-1.231 1.854-2.966 4.165-5.118 4.185-1.911.018-2.403-1.23-4.997-1.216-2.593.013-3.136 1.238-5.048 1.22-2.151-.02-3.795-2.105-5.028-3.962C-.43 21.445-.79 15.36 1.333 12.123c1.51-2.302 3.89-3.646 6.131-3.646 2.28 0 3.712 1.236 5.598 1.236 1.828 0 2.942-1.238 5.58-1.238 1.992 0 4.104 1.074 5.607 2.928-4.928 2.674-4.126 9.638.852 11.5z"/>
                <path d="M17.721 5.496C18.804 4.122 19.625 2.182 19.325.2c-1.766.12-3.832 1.232-5.04 2.682-1.094 1.317-1.999 3.27-1.648 5.167 1.93.059 3.924-1.081 5.084-2.553z"/>
              </g>
            </svg>
          </a>
        </div>
      </div>
    </div>

    <div class="container mx-0 pointer-events-none"></div>

    <div class="relative flex justify-center flex-1 pointer-events-auto">
      <div data-cursor-zone="diff" data-cursor-large class="flex items-center justify-center p-4 4xl:p-6 5xl:p-8 -mb-4 4xl:-mb-6 5xl:-mb-8">
        <div data-bottom-line>
          <button
              @click="backToTop()"
              type="button"
              aria-label="Back to top"
              data-magnet
              data-magnet-type="fixed"
              class="bottom-line-icon inline-block text-gray-dark focus:outline-none">
            <svg class="w-full transition-all duration-200" viewBox="0 0 23 29">
              <g class="stroke-current" stroke-width="3" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <path d="M11.6 26.4V3"/>
                <path d="M2.5 12.1L11.6 3l9.1 9.1"/>
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';
import ScrollToPlugin from 'gsap-bonus/ScrollToPlugin';
import ExpoScaleEase from 'gsap/EasePack';

gsap.registerPlugin(ScrollTrigger, CSSPlugin, ScrollToPlugin, ExpoScaleEase);

export default {
  props: {
    fixedButton: Boolean,
    social: Boolean,
    appStoreLink: String,
    googlePlayLink: String,
  },

  data() {
    return {
      appStoreUrl: '',
      googlePlayUrl: '',
    }
  },

  created() {
    this.appStoreUrl = this.appStoreLink || 'https://apps.apple.com/py/app/muv/id1254658756'
    this.googlePlayUrl = this.googlePlayLink || 'https://play.google.com/store/apps/details?id=com.muv.customer&hl=es_PY'

    window.addEventListener('load', () => {
      setTimeout(() => {
        this.createTimeline();
      }, 1000);
    });
  },

  methods: {
    backToTop() {
      let target = this.$root.bodyScrollBar || window

      gsap.to(target, {
        duration: 0.5,
        scrollTo: {y: 0},
        ease: 'power1',
      });
    },

    createTimeline() {
      const $this = this;

      const anim = gsap.fromTo('[data-bottom-line]',
        {
          yPercent: 100,
          opacity: 0
        },
        {
          yPercent: 0,
          opacity: 1,
          stagger: 0.2,
          ease: 'expoScale(0.1, 5, power4.inOut)',
        });

      ScrollTrigger.matchMedia({
        "(min-width: 460px)": function () {
          ScrollTrigger.create({
            trigger: '.section-hero',
            start: `bottom bottom-=15%`,
            toggleActions: 'play none none reverse',
            scrub: false,
            animation: anim,
            onEnter: () => {
              $this.$el.classList.add('z-50');
            },
            onLeaveBack: () => {
              $this.$el.classList.remove('z-50');
            },
          });
        },
      });
    },
  }
}
</script>

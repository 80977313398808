<template>
  <div class="hidden lg:block absolute center-x top-0 w-1 h-0 bg-gray-bright transition-all duration-700"></div>
</template>

<script>
export default {
  created() {
    window.addEventListener('load', () => {
      setTimeout(() => {
        this.$el.classList.add('h-full')
      }, 1000);
    });
  },
}
</script>

<template>
  <svg id="linesS01" class="hidden lg:block" width="100%" height="100%" fill="none" viewBox="0 0 1920 1151">
  <!-- Routes -->
  <g stroke-linecap="round" stroke-width="32">
    <path stroke="#F4F5F6" d="M1529 272h-247c-55.23 0-100 44.772-100 100v175c0 55.228-44.77 100-100 100h-22c-55.23 0-100 44.772-100 100v386M1610 18v161c0 51.362 41.64 93 93 93v0c51.36 0 93 41.638 93 93v421c0 55.228-44.77 100-100 100h-131c-55.23 0-100-44.772-100-100v-39c0-55.228-44.77-100-100-100h-87m187-239v104c0 55.228 44.77 100 100 100h109"/>
    <path id="8" stroke="#F4F5F6" d="M1674 612h-104.5"/>
    <path id="7" stroke="#F4F5F6" d="M1465 408v104c0 55.228 44.77 100 100 100h4.5"/>

    <path ref="Route01_1" data-route="1" stroke="#FF8B00" d="M960 1133V890"/>
    <path ref="Route01_2" data-route="-1" stroke="#FF8B00" d="M1182 460v87c0 55.228-44.77 100-100 100h-22c-55.23 0-100 44.772-100 100v143"/>
    <path ref="Route01_3" data-route="-1" stroke="#FF8B00" d="M1529 272h-247c-55.23 0-100 44.772-100 100v88"/>

    <path ref="Route02_3" data-route="-1" stroke="#FFD400" d="M1278 647h87c55.23 0 100 44.772 100 100v39c0 55.228 44.77 100 100 100h65.5"/>
    <path ref="Route02_2" data-route="-1" stroke="#FFD400" d="M1630.5 886h65.5c55.23 0 100-44.772 100-100V579"/>
    <path ref="Route02_1" data-route="1" stroke="#FFD400" d="M1610 18v161c0 51.362 41.64 93 93 93v0c51.36 0 93 41.638 93 93v214"/>
  </g>

  <!-- Dots -->
  <g>
    <path ref="MovingDot01" fill="#FFD400" fill-rule="evenodd" d="M1513 272c0-8.836 7.16-16 16-16s16 7.164 16 16c0 8.837-7.16 16-16 16s-16-7.163-16-16z" clip-rule="evenodd"/>
    <path ref="MovingDot02" fill="#FF8B00" fill-rule="evenodd" d="M1262 647c0-8.836 7.16-16 16-16s16 7.164 16 16c0 8.837-7.16 16-16 16s-16-7.163-16-16z" clip-rule="evenodd"/>
  </g>

  <!-- 24 Blink -->
  <g>
    <text ref="text" fill="#FFD400" class="font-main-b" font-size="214.261">
      <tspan x="1106" y="907.078">24</tspan>
    </text>

    <g ref="BlinkFx" stroke-linecap="round">
      <path ref="BlinkFxLine03" stroke="#FFD400" stroke-width="13.921" d="M1366.89 901.332l23.67 3.48"/>
      <path ref="BlinkFxLine02" stroke="#FFD400" stroke-width="13.921" d="M1339.2 956.404l37.83 52.996"/>
      <path ref="BlinkFxLine01" stroke="#FF8B00" stroke-width="13.921" d="M1290.28 962.953l-15.45 39.547"/>
      <path ref="BlinkFxLine04" stroke="#FF8B00" stroke-width="4.64" d="M1377.1 944.041l32.3 11.943"/>
      <path ref="BlinkFxLine05" stroke="#FFD400" stroke-width="4.64" d="M1321.16 990.119l.21 22.151"/>
    </g>
  </g>

  <!-- Label 01 -->
  <g ref="Label01">
    <path fill="#FF8B00" fill-rule="evenodd" d="M1477 206.4c0-2.24 0-3.36.44-4.216.38-.753.99-1.365 1.74-1.748.86-.436 1.98-.436 4.22-.436h89.2c2.24 0 3.36 0 4.22.436.75.383 1.36.995 1.74 1.748.44.856.44 1.976.44 4.216v28.2c0 2.24 0 3.36-.44 4.216a3.973 3.973 0 01-1.74 1.748c-.86.436-1.98.436-4.22.436h-35.4c-.96 0-1.44 0-1.89.107-.4.094-.79.25-1.14.462-.4.239-.75.573-1.43 1.242l-.88.852c-1.56 1.522-2.35 2.283-3.25 2.569-.79.252-1.63.252-2.42 0-.9-.286-1.69-1.047-3.25-2.569l-.88-.852c-.68-.669-1.03-1.003-1.43-1.242a3.96 3.96 0 00-1.14-.462c-.45-.107-.93-.107-1.89-.107h-34.2c-2.24 0-3.36 0-4.22-.436a3.973 3.973 0 01-1.74-1.748c-.44-.856-.44-1.976-.44-4.216v-28.2z" clip-rule="evenodd"/>
    <text class="font-main-b text-18" fill="#ffffff" opacity=".85">
      <tspan x="1511.25" y="227.2">HOLA</tspan>
    </text>
  </g>

  <!-- Label 02 -->
  <g ref="Label02">
    <path id="label-bgr" fill="#FF8B00" fill-rule="evenodd" d="M1226 581.4c0-2.24 0-3.36.44-4.216.38-.753.99-1.365 1.74-1.748.86-.436 1.98-.436 4.22-.436h89.2c2.24 0 3.36 0 4.22.436.75.383 1.36.995 1.74 1.748.44.856.44 1.976.44 4.216v28.2c0 2.24 0 3.36-.44 4.216a3.973 3.973 0 01-1.74 1.748c-.86.436-1.98.436-4.22.436h-35.4c-.96 0-1.44 0-1.89.107-.4.094-.79.25-1.14.462-.4.239-.75.573-1.43 1.242l-.88.852c-1.56 1.522-2.35 2.283-3.25 2.569-.79.252-1.63.252-2.42 0-.9-.286-1.69-1.047-3.25-2.569l-.88-.852c-.68-.669-1.03-1.003-1.43-1.242a3.96 3.96 0 00-1.14-.462c-.45-.107-.93-.107-1.89-.107h-34.2c-2.24 0-3.36 0-4.22-.436a3.973 3.973 0 01-1.74-1.748c-.44-.856-.44-1.976-.44-4.216v-28.2z" clip-rule="evenodd"/>
    <text class="font-main-b text-18" fill="#ffffff" opacity=".85">
      <tspan x="1244.35" y="602.2">¡GRACIAS!</tspan>
    </text>
  </g>

  <!-- Tunnel Bottom -->
  <g fill-rule="evenodd" clip-rule="evenodd">
    <path fill="#E5E6EB" d="M944 1116.64v-51.28c-20.329 3.55-35 13.69-35 25.64 0 11.95 14.671 22.09 35 25.64zm32 0v-51.28c20.329 3.55 35 13.69 35 25.64 0 11.95-14.671 22.09-35 25.64z"/>
    <path fill="#fff" d="M939 1115.61V1151h42v-35.39c-6.404 1.54-13.514 2.39-21 2.39-7.486 0-14.596-.85-21-2.39z"/>
  </g>

  <!-- Tunnel Top -->
  <g fill-rule="evenodd" clip-rule="evenodd">
    <path fill="#E5E6EB" d="M1626 34.355v51.29c20.33-3.553 35-13.692 35-25.645s-14.67-22.092-35-25.645zm-32 0v51.29c-20.33-3.553-35-13.692-35-25.645s14.67-22.092 35-25.645z"/>
    <path fill="#fff" d="M1631 35.388V0h-42v35.388c6.4-1.534 13.51-2.388 21-2.388s14.6.854 21 2.388z"/>
  </g>
</svg>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin);

export default {
  mixins: [vueGSAPMixin],

  data() {
    return {
      linesIntroTimeline: null,
      linesScrollTimeline: null,
      linesScrollTrigger: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetRoutes(this.$el);
      this.createTimelines();

      this.mediaQueries['lg'].addListener(() => {
        this.resetTimelines();
        this.createTimelines();
        this.playTimelines();
      });

      setTimeout(() => {
        this.playTimelines();
      }, 1000);
    });
  },

  methods: {
    createTimelines() {
      if (this.mediaQueries['lg'].matches) {
        this.linesAnimations();
      }
    },

    playTimelines() {
      if (this.mediaQueries['lg'].matches) {
        this.linesIntroTimeline.play();
      }
    },

    resetTimelines() {
      this.linesIntroTimeline && this.linesIntroTimeline.kill();
      this.linesIntroTimeline = null;

      this.resetRoutes(this.$el);
    },

    linesAnimations() {
      this.linesIntroTimeline = gsap.timeline({ paused: true })
          .fromTo(this.$refs.MovingDot02,
              {
                opacity: 0,
                scale: 0,
                ease: 'none'
              },
              {
                opacity: 1,
                scale: 1,
              },
              0
          )
          .to(this.$refs.MovingDot02,
              {
                motionPath: {
                  path: this.$refs.Route02_1,
                  align: this.$refs.Route02_1,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: .25,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.Route02_1,
              .25,
              {
                drawSVG: `${this.$refs.Route02_1.getTotalLength()}`,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.Route02_1,
              .25,
              {
                drawSVG: `${this.$refs.Route02_1.getTotalLength()} ${this.$refs.Route02_1.getTotalLength()}`,
                ease: 'none'
              },
              0.25
          )
          .to(this.$refs.MovingDot02,
              {
                motionPath: {
                  path: this.$refs.Route02_2,
                  align: this.$refs.Route02_2,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: .25,
                ease: 'none'
              },
              0.25
          )
          .to(this.$refs.Route02_2,
              {
                duration: .25,
                drawSVG: `${this.$refs.Route02_2.getTotalLength()}`,
                ease: 'none'
              },
              0.25
          )
          .to(this.$refs.Route02_2,
              .25,
              {
                drawSVG: `${this.$refs.Route02_2.getTotalLength() * -1}`,
                ease: 'none'
              },
              0.5
          )
          .to(this.$refs.MovingDot02,
              {
                motionPath: {
                  path: this.$refs.Route02_3,
                  align: this.$refs.Route02_3,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: .25,
                ease: 'none'
              },
              0.5
          )
          .to(this.$refs.Route02_3,
              {
                duration: .25,
                drawSVG: `${this.$refs.Route02_3.getTotalLength()}`,
                ease: 'none'
              },
              0.5
          )
          .fromTo(this.$refs.Label02,
              0.5,
              {
                yPercent: -150,
                opacity: 0,
              },
              {
                yPercent: 0,
                opacity: 1,
                ease: 'Elastic.easeOut'
              },
              0.7
          )
          .fromTo(this.$refs.MovingDot01,
              {
                opacity: 0,
                scale: 0,
                ease: 'none'
              },
              {
                opacity: 1,
                scale: 1,
              },
              0
          )
          .to(this.$refs.MovingDot01,
              {
                motionPath: {
                  path: this.$refs.Route01_1,
                  align: this.$refs.Route01_1,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: .25,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.Route01_1,
              .25,
              {
                drawSVG: `${this.$refs.Route01_1.getTotalLength()}`,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.Route01_1,
              .25,
              {
                drawSVG: `${this.$refs.Route01_1.getTotalLength()} ${this.$refs.Route01_1.getTotalLength()}`,
                ease: 'none'
              },
              0.25
          )
          .to(this.$refs.MovingDot01,
              {
                motionPath: {
                  path: this.$refs.Route01_2,
                  align: this.$refs.Route01_2,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: .25,
                ease: 'none'
              },
              0.25
          )
          .to(this.$refs.Route01_2,
              {
                duration: .25,
                drawSVG: `${this.$refs.Route01_2.getTotalLength()}`,
                ease: 'none'
              },
              0.25
          )
          .to(this.$refs.Route01_2,
              .25,
              {
                drawSVG: `${this.$refs.Route01_2.getTotalLength() * -1}`,
                ease: 'none'
              },
              0.5
          )
          .to(this.$refs.MovingDot01,
              {
                motionPath: {
                  path: this.$refs.Route01_3,
                  align: this.$refs.Route01_3,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: .25,
                ease: 'none'
              },
              0.5
          )
          .to(this.$refs.Route01_3,
              {
                duration: .25,
                drawSVG: `${this.$refs.Route01_3.getTotalLength()}`,
                ease: 'none'
              },
              0.5
          )
          .fromTo(this.$refs.Label01,
              0.5,
              {
                yPercent: -150,
                opacity: 0,
              },
              {
                yPercent: 0,
                opacity: 1,
                ease: 'Elastic.easeOut'
              },
              0.7
          )
          .fromTo(this.$refs.text,
              0.5,
              {
                scale: .7,
                opacity: 0,
              },
              {
                scale: 1,
                opacity: 1,
                ease: 'Elastic.easeOut'
              },
              0.75
          )
          .fromTo(this.$refs.BlinkFxLine01,
              .6,
              {
                scale: .7,
                xPercent: 300,
                yPercent: 70,
                opacity: 0,
              },
              {
                scale: 1,
                xPercent: 0,
                yPercent: 0,
                opacity: 1,
                ease: 'Elastic.easeOut'
              },
              0.75
          )
          .fromTo(this.$refs.BlinkFxLine02,
              .6,
              {
                scale: .7,
                xPercent: 200,
                yPercent: 150,
                opacity: 0,
              },
              {
                scale: 1,
                xPercent: 0,
                yPercent: 0,
                opacity: 1,
                ease: 'Elastic.easeOut'
              },
              0.75
          )
          .fromTo(this.$refs.BlinkFxLine03,
              .6,
              {
                scale: .7,
                xPercent: -150,
                yPercent: 150,
                opacity: 0,
              },
              {
                scale: 1,
                xPercent: 0,
                yPercent: 0,
                opacity: 1,
                ease: 'Elastic.easeOut'
              },
              0.75
          )
          .fromTo(this.$refs.BlinkFxLine04,
              .6,
              {
                scale: .7,
                xPercent: -200,
                yPercent: 70,
                opacity: 0,
              },
              {
                scale: 1,
                xPercent: 0,
                yPercent: 0,
                opacity: 1,
                ease: 'Elastic.easeOut'
              },
              0.75
          )
          .fromTo(this.$refs.BlinkFxLine05,
              .6,
              {
                scale: .7,
                xPercent: -300,
                yPercent: 150,
                opacity: 0,
              },
              {
                scale: 1,
                xPercent: 0,
                yPercent: 0,
                opacity: 1,
                ease: 'Elastic.easeOut'
              },
              0.75
          )
    },
  }
}
</script>

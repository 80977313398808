import gsap from "gsap";
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(CSSPlugin);

const vueGSAPMixin = {
    methods: {
        resetProps(targets) {
            gsap.set(targets, {clearProps: 'all'});
        },

        resetRoutes(wrapper) {
            const routes = wrapper.querySelectorAll('[data-route]');
            if (!routes.length) {
                return
            }

            [...routes].forEach(route => {
                const length = route.getTotalLength();
                const direction = parseInt(route.dataset.route);

                gsap.set(route, {strokeDasharray: `0 999999px`, strokeDashoffset: `${direction * (length + 1)}`});
            });
        },
    }
}

export default vueGSAPMixin

import gsap from 'gsap';
import { EventEmitter } from 'events';
import { getMousePos } from './utils';

export default class MagnetCtrl extends EventEmitter {
    constructor(el, scrollArea) {
        super();

        this.mousepos = {x: 0, y: 0};
        window.addEventListener('mousemove', ev => this.mousepos = getMousePos(ev, scrollArea));
        
        // el: magnet
        this.domEl = el;
        // magnet state (hover)
        this.state = {
            hover: false
        };
        // the movement will take place when the distance from the mouse to the borderlines of the magnet is lower than this value
        this.distanceToTrigger = 15;

        // calculate size/position
        this.calculateSizePosition();

        window.addEventListener('resize', () => this.calculateSizePosition());
        // init events
        this.obj = this.mousePositionToMagnet.bind(this);
        this.initMouseMoveEvent();
    }

    initMouseMoveEvent() {
        window.addEventListener('mousemove', this.obj);
    }

    calculateSizePosition() {
        // size/position
        this.rect = this.domEl.getBoundingClientRect();

        // let div = document.createElement('div');
        // div.classList.add('fixed');
        // div.classList.add('bg-black');
        // div.style.width = `${this.rect.width}px`;
        // div.style.height = '1px';
        // div.style.top = `${this.rect.top}px`;
        // div.style.left = `${this.rect.left}px`;
        // document.body.appendChild(div);
        //
        // let div2 = document.createElement('div');
        // div2.classList.add('fixed');
        // div2.classList.add('bg-black');
        // div2.style.width = '1px';
        // div2.style.height = `${this.rect.height}px`;
        // div2.style.top = `${this.rect.top}px`;
        // div2.style.left = `${this.rect.left}px`;
        // document.body.appendChild(div2);
    }

    removeEventListener() {
        window.removeEventListener('mousemove', this.obj);
    }

    mousePositionToMagnet() {
        // calculate the distance from the mouse to the magnet borders
        let leftBorder = this.rect.left - this.distanceToTrigger,
            topBorder = this.rect.top - this.distanceToTrigger,
            rightBorder = this.rect.right + this.distanceToTrigger,
            bottomBorder = this.rect.bottom + this.distanceToTrigger;

        let mouseIsInMagnetTerritory =
            this.mousepos.x > leftBorder &&
            this.mousepos.x < rightBorder &&
            this.mousepos.y > topBorder &&
            this.mousepos.y < bottomBorder;

        if (mouseIsInMagnetTerritory) {
            this.enter();
        }
        else {
            this.leave();
        }
    }

    enter() {
        if (!this.state.hover) {
            this.emit('enter');

            const zone = this.domEl.closest('[data-cursor-zone]');
            if (zone) {
                this.emit('entered-zone');
                this.domEl.classList.add('text-black');
            }

            this.state.hover = true;
        }

        gsap.to(this.domEl, 0.45, {
            x: (this.mousepos.x - (this.rect.left + this.rect.width / 2)) / 8,
            y: (this.mousepos.y - (this.rect.top + this.rect.height / 2)) / 4,
            ease: 'power2.out'
        });
    }

    leave() {
        if (this.state.hover) {
            this.emit('leave');
            this.state.hover = false;
            this.domEl.classList.remove('text-black');

            gsap.killTweensOf(this.domEl);

            gsap.to(this.domEl, 0.15, {
                x: 0,
                y: 0,
                ease: 'power2.in'
            });
        }
    }
}

<template>
  <g>
    <!-- Routes -->
    <g stroke-linecap="round" stroke-width="32" :stroke="color2">
      <path stroke="#F4F5F6" d="M759 4036H176c-56.005 0-84.008 0-105.4-10.9a100.043 100.043 0 01-43.7-43.7C16 3960 16 3932 16 3876v-134c0-56.01 0-84.01 10.9-105.4a99.991 99.991 0 0143.7-43.7C91.993 3582 119.996 3582 176 3582h213c56.005 0 84.008 0 105.399-10.9a99.996 99.996 0 0043.702-43.7C549 3506.01 549 3478.01 549 3422v-514c0-56.01 0-84.01 10.899-105.4a99.996 99.996 0 0143.702-43.7C624.992 2748 652.995 2748 709 2748h869c56.01 0 84.01 0 105.4-10.9a100.004 100.004 0 0043.71-43.7c10.89-21.39 10.89-49.39 10.89-105.4v-286c0-56.01 0-84.01-10.89-105.4a100.004 100.004 0 00-43.71-43.7c-21.39-10.9-49.39-10.9-105.4-10.9h-336c-56.01 0-84.01 0-105.4-10.9a99.981 99.981 0 01-43.7-43.7c-10.9-21.39-10.9-49.39-10.9-105.4v-125 0c0-47.42 0-71.13 7.88-89.78a99.943 99.943 0 0153.22-53.22c18.65-7.88 42.36-7.88 89.78-7.88H1744c56.01 0 84.01 0 105.4-10.9a99.93 99.93 0 0043.7-43.7c10.9-21.39 10.9-49.39 10.9-105.4v-203.53c0-41.19-33.4-74.59-74.59-74.59v0c-41.2 0-74.6-33.4-74.6-74.59V1168"/>

      <path ref="Route04" data-route="-1" d="M759 4036H176c-56.005 0-84.008 0-105.4-10.9a100.043 100.043 0 01-43.7-43.7C16 3960 16 3932 16 3876v-134c0-56.01 0-84.01 10.9-105.4a99.991 99.991 0 0143.7-43.7C91.993 3582 119.996 3582 176 3582h106.162"/>
      <path ref="Route03" data-route="1" d="M1029 2748H709c-56.005 0-84.008 0-105.399 10.9a99.996 99.996 0 00-43.702 43.7C549 2823.99 549 2851.99 549 2908v514c0 56.01 0 84.01-10.899 105.4a99.996 99.996 0 01-43.702 43.7C473.008 3582 445.005 3582 389 3582H282.162"/>
      <path ref="Route02" data-route="1" d="M1082 1857v125c0 56.01 0 84.01 10.9 105.4a99.981 99.981 0 0043.7 43.7c21.39 10.9 49.39 10.9 105.4 10.9h336c56.01 0 84.01 0 105.4 10.9a99.93 99.93 0 0143.7 43.7c10.9 21.39 10.9 49.39 10.9 105.4v286c0 56.01 0 84.01-10.9 105.4a99.93 99.93 0 01-43.7 43.7c-21.39 10.9-49.39 10.9-105.4 10.9h-549"/>
      <path ref="Route01" data-route="1" d="M1754.81 1168v25.41c0 41.19 33.4 74.59 74.6 74.59v0c41.19 0 74.59 33.4 74.59 74.59v203.53c0 56.01 0 84.01-10.9 105.4a99.93 99.93 0 01-43.7 43.7c-21.39 10.9-49.39 10.9-105.4 10.9h-511.12c-47.42 0-71.13 0-89.78 7.88a99.943 99.943 0 00-53.22 53.22c-7.88 18.65-7.88 42.36-7.88 89.78v0"/>
    </g>

    <!-- Dots -->
    <g>
      <path ref="MovingDot" :fill="color1" d="M1739 1168c0-8.84 7.16-16 16-16s16 7.16 16 16-7.16 16-16 16-16-7.16-16-16z"/>
      <path ref="Dot01" :fill="color1" d="M1739 1168c0-8.84 7.16-16 16-16s16 7.16 16 16-7.16 16-16 16-16-7.16-16-16z"/>
      <path ref="Dot02" :fill="color1" d="M1098 1857c0 8.84-7.16 16-16 16s-16-7.16-16-16 7.16-16 16-16 16 7.16 16 16z"/>
      <path ref="Dot03" :fill="color1" d="M1045 2748c0 8.84-7.16 16-16 16s-16-7.16-16-16 7.16-16 16-16 16 7.16 16 16z"/>
    </g>

    <!-- Label 01 -->
    <g ref="Label01">
      <path ref="Label01Bg" :fill="color1" d="M1703 1102.4c0-2.24 0-3.36.44-4.22.38-.75.99-1.36 1.74-1.74.86-.44 1.98-.44 4.22-.44h89.2c2.24 0 3.36 0 4.22.44.75.38 1.36.99 1.74 1.74.44.86.44 1.98.44 4.22v28.2c0 2.24 0 3.36-.44 4.22-.38.75-.99 1.36-1.74 1.74-.86.44-1.98.44-4.22.44h-35.4c-.96 0-1.44 0-1.89.11-.4.09-.79.25-1.14.46-.4.24-.75.57-1.43 1.24l-.88.85c-1.56 1.52-2.35 2.29-3.25 2.57-.79.25-1.63.25-2.42 0-.9-.28-1.69-1.05-3.25-2.57l-.88-.85c-.68-.67-1.03-1-1.43-1.24a3.9 3.9 0 00-1.14-.46c-.45-.11-.93-.11-1.89-.11h-34.2c-2.24 0-3.36 0-4.22-.44-.75-.38-1.36-.99-1.74-1.74-.44-.86-.44-1.98-.44-4.22v-28.2z"/>
      <text class="font-main-b text-16" fill="#ffffff" opacity=".85">
        <tspan x="1732.34" y="1123.2">DESDE</tspan>
      </text>
    </g>

    <!-- Label 02 -->
    <g ref="Label02">
      <path ref="Label02Bg" :fill="color1" d="M1022.4 1908c-2.24 0-3.36 0-4.22-.44-.75-.38-1.36-.99-1.74-1.74-.44-.86-.44-1.98-.44-4.22v-81.2c0-2.24 0-3.36.44-4.22.38-.75.99-1.36 1.74-1.74.86-.44 1.98-.44 4.22-.44h21.2c2.24 0 3.36 0 4.22.44.75.38 1.36.99 1.74 1.74.44.86.44 1.98.44 4.22v32.11c0 .82 0 1.23.08 1.61.1.52.3 1.01.59 1.45.22.32.51.61 1.09 1.19 1.32 1.32 1.97 1.97 2.28 2.71.4.98.4 2.08 0 3.06-.31.74-.96 1.39-2.28 2.71-.58.58-.87.87-1.09 1.19-.29.44-.49.93-.59 1.45-.08.38-.08.79-.08 1.61v32.11c0 2.24 0 3.36-.44 4.22-.38.75-.99 1.36-1.74 1.74-.86.44-1.98.44-4.22.44h-21.2z"/>
      <text class="font-main-b text-16" fill="#ffffff" opacity=".85" transform="rotate(-90 1458.25 435.75)">
        <tspan x=".43" y="17.2">PARADA 2</tspan>
      </text>
    </g>

    <!-- Label 03 -->
    <g ref="Label03">
      <path ref="Label03Bg" :fill="color1" d="M985 2688.4c0-2.24 0-3.36.436-4.22.383-.75.995-1.36 1.748-1.74.856-.44 1.976-.44 4.216-.44h75.2c2.24 0 3.36 0 4.22.44.75.38 1.36.99 1.74 1.74.44.86.44 1.98.44 4.22v21.2c0 2.24 0 3.36-.44 4.22-.38.75-.99 1.36-1.74 1.74-.86.44-1.98.44-4.22.44h-29.11c-.82 0-1.23 0-1.61.08-.52.1-1.01.3-1.45.59-.32.22-.61.51-1.19 1.09-1.32 1.32-1.97 1.97-2.71 2.28-.98.4-2.08.4-3.06 0-.74-.31-1.39-.96-2.71-2.28-.58-.58-.87-.87-1.19-1.09-.44-.29-.93-.49-1.45-.59-.38-.08-.79-.08-1.61-.08H991.4c-2.24 0-3.36 0-4.216-.44a3.973 3.973 0 01-1.748-1.74c-.436-.86-.436-1.98-.436-4.22v-21.2z"/>
      <text class="font-main-b text-16" fill="#ffffff" opacity=".85" transform="translate(1011.5 2688.5)">
        <tspan x=".367" y="17.2">PARE</tspan>
      </text>
    </g>

    <!-- FX -->
    <g id="FX">
      <path ref="FxLine01" fill="#F4F5F6" fill-rule="evenodd" d="M1188 2705c0-1.66-1.34-3-3-3h-70c-1.66 0-3 1.34-3 3s1.34 3 3 3h70c1.66 0 3-1.34 3-3z" clip-rule="evenodd"/>
      <path ref="FxLine02" fill="#F4F5F6" fill-rule="evenodd" d="M1234 2785c0-1.66-1.34-3-3-3h-40c-1.66 0-3 1.34-3 3s1.34 3 3 3h40c1.66 0 3-1.34 3-3z" clip-rule="evenodd"/>

      <text ref="MovingText" class="font-main-b text-16 opacity-0" fill="#ffffff" opacity=".85">
        <tspan x="1055" y="2753.93">LLEGANDO EN 5 MINS</tspan>
      </text>
    </g>
  </g>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';
import ExpoScaleEase from 'gsap/EasePack';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin, ExpoScaleEase);

export default {
  mixins: [vueGSAPMixin],

  props: {
    color1: String,
    color2: String,
  },

  data() {
    return {
      linesTimeline: null,
      linesScrollTrigger: null,
      linesLabelTimeline01: null,
      linesLabelTimeline02: null,
      linesLabelTimeline03: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetRoutes(this.$el);

      setTimeout(() => {
        this.animateLines();
      }, 1000)
    });
  },

  methods: {
    animateLines() {
      let direction = 1;

      this.linesLabelTimeline01 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot01,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot01,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label01,
              0.3,
              {
                yPercent: -150,
                opacity: 0,
              },
              {
                yPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          );

      this.linesLabelTimeline02 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot02,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot02,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label02,
              0.3,
              {
                xPercent: -150,
                opacity: 0,
              },
              {
                xPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          );

      this.linesLabelTimeline03 = gsap.timeline({paused: true})
          .fromTo(this.$refs.Dot03,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.Dot03,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.Label03,
              0.3,
              {
                yPercent: -150,
                opacity: 0,
              },
              {
                yPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          );

      this.linesTimeline = gsap.timeline()
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline01.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline01.reverse();
                }
              },
              0.1
          )
          .fromTo(this.$refs.MovingDot,
              0.1,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1,
                ease: 'none'
              },
              0.1
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route01,
                  align: this.$refs.Route01,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0.2
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              0.2
          )
          .to(this.$refs.Route01,
              1,
              {
                drawSVG: `${this.$refs.Route01.getTotalLength()} ${this.$refs.Route01.getTotalLength()}`,
                ease: 'none',
              },
              1.2
          )
          .to(this.$refs.Dot01,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              1.2
          )
          .to(this.$refs.Label01Bg,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              1.2
          )
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline02.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline02.reverse();
                }
              },
              1.2
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route02,
                  align: this.$refs.Route02,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              1.2
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              1.2
          )
          .to(this.$refs.MovingText,
              0.1,
              {
                opacity: .85,
              },
              1.97
          )
          .fromTo(this.$refs.MovingText,
              0.23,
              {
                xPercent: 360,
              },
              {
                xPercent: 0,
                ease: 'none'
              },
              1.97
          )
          .to(this.$refs.Dot02,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              2.2
          )
          .to(this.$refs.Label02Bg,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              2.2
          )
          .add(() => {
                if (direction === 1) {
                  this.linesLabelTimeline03.play();
                } else if (direction === -1) {
                  this.linesLabelTimeline03.reverse();
                }
              },
              2.2
          )
          .to(this.$refs.Route02,
              1,
              {
                drawSVG: `${this.$refs.Route02.getTotalLength()} ${this.$refs.Route02.getTotalLength()}`,
                ease: 'none',
              },
              2.2
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route03,
                  align: this.$refs.Route03,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              2.2
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              2.2
          )
          .to(this.$refs.MovingText,
              0.1,
              {
                fill: '#ccced7',
                ease: 'none'
              },
              3.2
          )
          .to(this.$refs.Dot03,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              3.2
          )
          .to(this.$refs.Label03Bg,
              0.1,
              {
                fill: '#CCCED7',
                ease: 'none'
              },
              3.2
          )
          .to(this.$refs.Route03,
              1,
              {
                drawSVG: `${this.$refs.Route03.getTotalLength()} ${this.$refs.Route03.getTotalLength()}`,
                ease: 'none',
              },
              3.2
          )
          .to(this.$refs.MovingDot,
              {
                motionPath: {
                  path: this.$refs.Route04,
                  align: this.$refs.Route04,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              3.2
          )
          .to(this.$refs.Route04,
              1,
              {
                drawSVG: `${this.$refs.Route04.getTotalLength()}`,
                ease: 'none',
              },
              3.2
          )

      ScrollTrigger.matchMedia({
        "(min-width: 960px)": () => {
          ScrollTrigger.create({
            trigger: this.$refs.Route01,
            start: 'top bottom-=20%',
            endTrigger: this.$refs.Route04,
            end: 'bottom center+=12%',
            scrub: 0.6,
            animation: this.linesTimeline,
            onUpdate: self => {
              if (self.direction === direction) {
                return;
              }
              direction = self.direction;
            },
          });
        },
      });
    },
  }
}
</script>


<template>
  <svg class="w-full" viewBox="0 0 130 131" fill="none">
    <path d="M104.785 82.4891V116.64C104.785 122.656 99.9085 127.532 93.8934 127.532V127.532C87.8782 127.532 83.002 122.656 83.002 116.64V113.034H25.1434V116.46C25.1434 122.575 20.1865 127.532 14.0717 127.532V127.532C7.95698 127.532 3 122.575 3 116.46L3 69.7467C3 68.868 3.20037 68.001 3.58586 67.2114L19.7561 34.092C21.9443 29.6102 26.4956 26.7676 31.483 26.7676H52.0379" stroke="#381CA0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.9551 91.943H39.0061" stroke="#381CA0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.2832 69.0586H72.1307" stroke="#381CA0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M68.8643 91.943H82.9153" stroke="#381CA0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M126.999 28.2563C127.017 37.8523 123.925 47.1672 118.233 54.6604C112.542 62.1536 104.593 67.3763 95.701 69.4647C86.8089 67.3763 78.8599 62.1536 73.1686 54.6604C67.4774 47.1672 64.3848 37.8523 64.4024 28.2563V11.7729C64.4024 9.5871 65.2268 7.49079 66.6942 5.94518C68.1616 4.39957 70.1518 3.53125 72.2271 3.53125H119.175C121.25 3.53125 123.24 4.39957 124.708 5.94518C126.175 7.49079 126.999 9.5871 126.999 11.7729V28.2563Z" stroke="#7663BE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M110.419 21.846L95.701 43.5694L85.3031 33.8524" stroke="#7663BE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {}
</script>

<template>
  <div data-animate="fade-up" class="w-full lg:w-10/12 lg:h-20 xl:h-24 3xl:h-28 4xl:h-32 5xl:h-44 mb-4 xl:mb-5 3xl:mb-6 4xl:mb-8 5xl:mb-10">
    <div data-check-list-item data-offset="20" class="w-full h-full">
      <div class="flex items-center justify-center w-full h-full py-4 sm:py-5 md:py-6 lg:py-0 px-6 md:px-8 lg:px-5 xl:px-6 3xl:px-8 4xl:px-10 5xl:px-14 rounded-2xl md:rounded-3xl lg:rounded-lg 3xl:rounded-2xl border border-gray-dark">

        <span class="hidden lg:block flex-shrink-0 w-8 xl:w-10 4xl:w-12 5xl:w-16 mr-4 xl:mr-6 4xl:mr-8 5xl:mr-10">
          <slot name="icon"></slot>
        </span>

        <h4 class="lg:pr-5 xl:pr-6 3xl:pr-8 4xl:pr-10 5xl:pr-14 text-14 xsm:text-22 md:text-26 lg:text-16 xl:text-18 3xl:text-20 4xl:text-28 5xl:text-38 leading-tight"><slot name="heading"></slot></h4>

        <svg viewBox="0 0 25 19"
             class="absolute lg:relative left-0 top-0 bottom-0 my-auto ml-5 sm:ml-6 md:ml-8 lg:ml-auto h-2 sm:h-3 md:h-4 lg:h-3 xl:h-4 4xl:h-5">
          <path class="stroke-current text-orange" d="M1.75 11.25l6 6 15-15" stroke-width="3.087" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

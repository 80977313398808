<template>
  <svg class="hidden lg:block w-full" fill="none" viewBox="0 0 1920 1760">
    <!-- Routes -->
    <g stroke-linecap="round" stroke-width="32">
      <path stroke="#F4F5F6" d="M952 20v134c0 55.228 44.772 100 100 100h752c55.23 0 100 44.772 100 100v89c0 55.228-44.77 100-100 100H116c-55.228 0-100 44.772-100 100v229c0 55.228 44.772 100 100 100h696c55.228 0 100 44.77 100 100v116c0 55.23-44.772 100-100 100H281c-55.228 0-100 44.77-100 100v256c0 55.23 44.772 100 100 100h1024"/>

      <path ref="route07" data-route="-1" :stroke="color2" d="M1305 1744H743.25"/>
      <path ref="route06" data-route="1" :stroke="color2" d="M912 1130v58c0 55.23-44.772 100-100 100H281c-55.228 0-100 44.77-100 100v256c0 55.23 44.772 100 100 100h462.25"/>
      <path ref="route05" data-route="1" :stroke="color2" d="M16 757.5V872c0 55.228 44.772 100 100 100h696c55.228 0 100 44.77 100 100v58"/>
      <path ref="route04" data-route="1" :stroke="color1" d="M856.5 543H116c-55.228 0-100 44.772-100 100v114.5"/>
      <path ref="route03" data-route="1" :stroke="color1" d="M1697 543H856.5"/>
      <path ref="route02" data-route="1" :stroke="color1" d="M1428 254h376c55.23 0 100 44.772 100 100v89c0 55.228-44.77 100-100 100h-107"/>
      <path ref="route01" data-route="1" :stroke="color1" d="M952 20v134c0 55.228 44.772 100 100 100h376"/>
    </g>

    <!-- Dots -->
    <g>
      <path ref="movingDot" :fill="color2" d="M952 32c-8.836 0-16-7.163-16-16 0-8.836 7.164-16 16-16 8.837 0 16 7.164 16 16 0 8.837-7.163 16-16 16z"/>
      <path ref="dot01" :fill="color2" d="M1696.81 559c-8.83 0-16-7.164-16-16s7.17-16 16-16c8.84 0 16 7.164 16 16s-7.16 16-16 16z"/>
      <path ref="dot02" class="opacity-0" fill="#E5E6EB" d="M16 773c-8.836 0-16-7.164-16-16s7.164-16 16-16c8.837 0 16 7.164 16 16s-7.163 16-16 16z"/>
      <path ref="dot03" class="opacity-0" fill="#E5E6EB" d="M912 1146c-8.836 0-16-7.16-16-16s7.164-16 16-16c8.837 0 16 7.16 16 16s-7.163 16-16 16z"/>
      <path ref="dot04" class="opacity-0" fill="#E5E6EB" d="M1305 1760c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16z"/>
    </g>

    <!-- Label -->
    <g ref="label">
      <path ref="labelBg" :fill="color1" d="M1653 476.4C1653 474.16 1653 473.04 1653.44 472.184C1653.82 471.431 1654.43 470.819 1655.18 470.436C1656.04 470 1657.16 470 1659.4 470H1734.6C1736.84 470 1737.96 470 1738.82 470.436C1739.57 470.819 1740.18 471.431 1740.56 472.184C1741 473.04 1741 474.16 1741 476.4V497.6C1741 499.84 1741 500.96 1740.56 501.816C1740.18 502.569 1739.57 503.181 1738.82 503.564C1737.96 504 1736.84 504 1734.6 504H1705.49C1704.67 504 1704.26 504 1703.88 504.077C1703.36 504.179 1702.87 504.382 1702.43 504.674C1702.11 504.893 1701.82 505.181 1701.24 505.757C1699.92 507.075 1699.27 507.734 1698.53 508.039C1697.55 508.445 1696.45 508.445 1695.47 508.039C1694.73 507.734 1694.08 507.075 1692.76 505.757C1692.18 505.181 1691.89 504.893 1691.57 504.674C1691.13 504.382 1690.64 504.179 1690.12 504.077C1689.74 504 1689.33 504 1688.51 504H1659.4C1657.16 504 1656.04 504 1655.18 503.564C1654.43 503.181 1653.82 502.569 1653.44 501.816C1653 500.96 1653 499.84 1653 497.6V476.4Z"/>
      <text class="font-main-b text-16" fill="#ffffff" opacity=".85" transform="translate(1673 476.5)">
        <tspan x="-6" y="16.5">GS. 8,500</tspan>
      </text>
    </g>

    <!-- Blink Fx -->
    <g stroke-linecap="round">
      <path ref="blinkFxLine01" stroke="#F4F5F6" stroke-width="24" d="M633.629 738.836l-6.648-40.706"/>
      <path ref="blinkFxLine02" stroke="#F4F5F6" stroke-width="8" d="M695.025 703.367l7.855-37.366"/>
      <path ref="blinkFxLine03" :stroke="color2" stroke-width="24" d="M714.111 766.465l81.885-76.781"/>
      <path ref="blinkFxLine04" :stroke="color1" stroke-width="8" d="M774 800.199l21.645-2.312"/>
      <path ref="blinkFxLine05" :stroke="color1" stroke-width="24" d="M742.291 868.938l41.19 2.129"/>
    </g>

    <!-- Tunnel -->
    <g>
      <path fill="#E5E6EB" d="M936 34.355v51.29C915.671 82.091 901 71.952 901 60s14.671-22.092 35-25.645zm32 0v51.29c20.329-3.553 35-13.692 35-25.645s-14.671-22.092-35-25.645z"/>
      <path fill="#fff" d="M931 35.388V0h42v35.388C966.596 33.854 959.486 33 952 33c-7.486 0-14.596.854-21 2.388z"/>
    </g>
  </svg>
</template>

<script>
import vueGSAPMixin from "@/includes/vueGSAPMixin";
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap-bonus/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin, CSSPlugin);

export default {
  mixins: [vueGSAPMixin],

  props: {
    color1: String,
    color2: String,
  },

  data() {
    return {
      linesTimeline: null,
      sTrigger: null,
      labelTimeline: null,
      blinkFxTimeline: null,
    }
  },

  created() {
    window.addEventListener('load', () => {
      this.resetTimelines();
      setTimeout(() => {
        this.createTimelines();
      }, 1000)

      this.mediaQueries['lg'].addListener(() => {
        // TODO Custom timelines doesn't play on resize
        this.resetTimelines();
        this.createTimelines();
      });
    });
  },

  methods: {
    createTimelines() {
      if (this.mediaQueries['lg'].matches) {
        this.linesAnimation();
      }
    },

    resetTimelines() {
      this.labelTimeline && this.labelTimeline.kill();
      this.blinkFxTimeline && this.blinkFxTimeline.kill();
      this.linesTimeline && this.linesTimeline.kill();
      this.sTrigger && this.sTrigger.kill();

      this.linesTimeline = null;
      this.sTrigger = null;
      this.labelTimeline = null;
      this.blinkFxTimeline = null;

      this.resetRoutes(this.$el);
    },

    linesAnimation() {
      let direction = 1;

      this.labelTimeline = gsap.timeline({paused: true})
          .fromTo(this.$refs.dot01,
              0.2,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1.3,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          )
          .to(this.$refs.dot01,
              0.2,
              {
                scale: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              }
          )
          .fromTo(this.$refs.label,
              0.3,
              {
                yPercent: -200,
                opacity: 0,
              },
              {
                yPercent: 0,
                opacity: 1,
                ease: 'expoScale(0.1, 5, power4.inOut)'
              },
              0
          );

      this.blinkFxTimeline = gsap.timeline({paused: true})
          .fromTo(this.$refs.blinkFxLine01,
              1,
              {
                scale: .7,
                xPercent: 300,
                yPercent: 70,
                opacity: 0,
              },
              {
                scale: 1,
                xPercent: 0,
                yPercent: 0,
                opacity: 1,
                ease: 'Elastic.easeOut'
              },
              0
          )
          .fromTo(this.$refs.blinkFxLine02,
              1,
              {
                scale: .7,
                xPercent: 200,
                yPercent: 150,
                opacity: 0,
              },
              {
                scale: 1,
                xPercent: 0,
                yPercent: 0,
                opacity: 1,
                ease: 'Elastic.easeOut'
              },
              0
          )
          .fromTo(this.$refs.blinkFxLine03,
              1,
              {
                scale: .7,
                xPercent: -150,
                yPercent: 150,
                opacity: 0,
              },
              {
                scale: 1,
                xPercent: 0,
                yPercent: 0,
                opacity: 1,
                ease: 'Elastic.easeOut'
              },
              0
          )
          .fromTo(this.$refs.blinkFxLine04,
              1,
              {
                scale: .7,
                xPercent: -200,
                yPercent: 70,
                opacity: 0,
              },
              {
                scale: 1,
                xPercent: 0,
                yPercent: 0,
                opacity: 1,
                ease: 'Elastic.easeOut'
              },
              0
          )
          .fromTo(this.$refs.blinkFxLine05,
              1,
              {
                scale: .7,
                xPercent: -300,
                yPercent: 150,
                opacity: 0,
              },
              {
                scale: 1,
                xPercent: 0,
                yPercent: 0,
                opacity: 1,
                ease: 'Elastic.easeOut'
              },
              0
          )

      this.linesTimeline = gsap.timeline()
          .fromTo(this.$refs.movingDot,
              0.1,
              {
                scale: 0,
                transformOrigin: 'center center',
                ease: 'none'
              },
              {
                scale: 1,
                ease: 'none'
              },
              0
          )
          .to(this.$refs.movingDot,
              {
                motionPath: {
                  path: this.$refs.route01,
                  align: this.$refs.route01,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              0.1
          )
          .to(this.$refs.route01,
              1,
              {
                drawSVG: `${this.$refs.route01.getTotalLength()}`,
                ease: 'none',
              },
              0.1
          )
          .to(this.$refs.route01,
              1,
              {
                drawSVG: `${this.$refs.route01.getTotalLength()} ${this.$refs.route01.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .to(this.$refs.movingDot,
              {
                motionPath: {
                  path: this.$refs.route02,
                  align: this.$refs.route02,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              1.1
          )
          .to(this.$refs.route02,
              1,
              {
                drawSVG: `${this.$refs.route02.getTotalLength()}`,
                ease: 'none',
              },
              1.1
          )
          .to(this.$refs.route02,
              1,
              {
                drawSVG: `${this.$refs.route02.getTotalLength()} ${this.$refs.route02.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .add(() => {
                if (direction === 1) {
                  this.labelTimeline.play();
                } else if (direction === -1) {
                  this.labelTimeline.reverse();
                }
              },
              2.1
          )
          .to(this.$refs.movingDot,
              {
                motionPath: {
                  path: this.$refs.route03,
                  align: this.$refs.route03,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              2.1
          )
          .to(this.$refs.route03,
              1,
              {
                drawSVG: `${this.$refs.route03.getTotalLength()}`,
                ease: 'none',
              },
              2.1
          )
          .to(this.$refs.labelBg,
              .2,
              {
                fill: '#CCCED7',
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.dot01,
              .2,
              {
                fill: '#CCCED7',
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.route03,
              1,
              {
                drawSVG: `${this.$refs.route03.getTotalLength()} ${this.$refs.route03.getTotalLength()}`,
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.movingDot,
              {
                motionPath: {
                  path: this.$refs.route04,
                  align: this.$refs.route04,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              3.1
          )
          .to(this.$refs.route04,
              1,
              {
                drawSVG: `${this.$refs.route04.getTotalLength()}`,
                ease: 'none',
              },
              3.1
          )
          .to(this.$refs.route04,
              1,
              {
                drawSVG: `${this.$refs.route04.getTotalLength()} ${this.$refs.route04.getTotalLength()}`,
                ease: 'none',
              },
              4.1
          )
          .to(this.$refs.movingDot,
              0.1,
              {
                fill: this.color1,
                ease: 'none',
              },
              4.1
          )
          .to(this.$refs.movingDot,
              {
                motionPath: {
                  path: this.$refs.route05,
                  align: this.$refs.route05,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              4.1
          )
          .to(this.$refs.route05,
              1,
              {
                drawSVG: `${this.$refs.route05.getTotalLength()}`,
                ease: 'none',
              },
              4.1
          )
          .add(() => {
                this.blinkFxTimeline.play()
              },
              3.9
          )
          .to(this.$refs.dot02,
              0.1,
              {
                opacity: 1,
                ease: 'none',
              },
              5.1
          )
          .to(this.$refs.route05,
              1,
              {
                drawSVG: `${this.$refs.route05.getTotalLength()} ${this.$refs.route05.getTotalLength()}`,
                ease: 'none',
              },
              5.1
          )
          .to(this.$refs.movingDot,
              {
                motionPath: {
                  path: this.$refs.route06,
                  align: this.$refs.route06,
                  start: 0,
                  end: 1,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              5.1
          )
          .to(this.$refs.route06,
              1,
              {
                drawSVG: `${this.$refs.route06.getTotalLength()}`,
                ease: 'none',
              },
              5.1
          )
          .to(this.$refs.dot03,
              0.1,
              {
                opacity: 1,
                ease: 'none',
              },
              6.1
          )
          .to(this.$refs.route06,
              1,
              {
                drawSVG: `${this.$refs.route06.getTotalLength()} ${this.$refs.route06.getTotalLength()}`,
                ease: 'none',
              },
              6.1
          )
          .to(this.$refs.movingDot,
              {
                motionPath: {
                  path: this.$refs.route07,
                  align: this.$refs.route07,
                  start: 1,
                  end: 0,
                  alignOrigin: [0.5, 0.5],
                },
                duration: 1,
                ease: 'none'
              },
              6.1
          )
          .to(this.$refs.route07,
              1,
              {
                drawSVG: `${this.$refs.route07.getTotalLength()}`,
                ease: 'none',
              },
              6.1
          )

      this.sTrigger = ScrollTrigger.create({
        trigger: this.$el,
        start: 'top bottom-=30%',
        end: 'bottom bottom-=30%',
        scrub: 0.6,
        animation: this.linesTimeline,
        onUpdate: self => {
          if (self.direction === direction) {
            return;
          }
          direction = self.direction;
        },
      });
    }
  }
}
</script>

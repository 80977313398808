<template>
  <svg class="hidden lg:block w-full" fill="none" viewBox="0 0 1920 4454">
    <delivery-how-it-works-line-02></delivery-how-it-works-line-02>
    <delivery-how-it-works-line-03></delivery-how-it-works-line-03>
    <delivery-how-it-works-line-01></delivery-how-it-works-line-01>
  </svg>
</template>

<script>
export default {}
</script>


<template>
  <svg class="w-full" viewBox="0 0 124 125" fill="none">
    <path d="M93 62.7344V106.744C93 108.598 92.6347 110.434 91.925 112.147C91.2154 113.86 90.1752 115.416 88.8639 116.727C87.5526 118.038 85.9959 119.077 84.2828 119.786C82.5696 120.495 80.7336 120.86 78.8795 120.859H45.1205C43.2664 120.86 41.4304 120.495 39.7172 119.786C38.0041 119.077 36.4474 118.038 35.1361 116.727C33.8248 115.416 32.7846 113.86 32.075 112.147C31.3653 110.434 31 108.598 31 106.744V26.4799C31 22.7349 32.4877 19.1433 35.1358 16.4952C37.7839 13.8471 41.3755 12.3594 45.1205 12.3594H58" stroke="#381CA0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M31 97.4062H93" stroke="#381CA0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M51.7279 61.457L62.5352 71.0039L78 51.6055" stroke="#7663BE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M80.834 5.56761C80.834 5.56761 92.334 4.56685 100.834 13.0676C109.334 21.5684 107.334 32.0676 107.334 32.0676" stroke="#7663BE" stroke-width="6" stroke-linecap="round"/>
    <path d="M79.8977 19.0529C79.8977 19.0529 85.8777 18.1429 90.6137 21.986C95.3498 25.8291 94.663 31.0333 94.663 31.0333" stroke="#7663BE" stroke-width="6" stroke-linecap="round"/>
    <path d="M77.001 29.9164C77.001 29.9164 79.5398 29.3858 81.6637 31.0683C83.7875 32.7508 83.6223 35.1613 83.6223 35.1613" stroke="#7663BE" stroke-width="6" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {}
</script>

<script>
import {mapMutations} from "vuex";
import axios from 'axios'

export default {
  data() {
    return {
      name: null,
      phone: null,
      address: null,
      mail: null,
      errorName: null,
      errorPhone: null,
      errorAddress: null,
      errorMail: null,
      postUrl: '/send.php',
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    }
  },

  methods: {
    ...mapMutations([`openRegisterCompleteScreen`]),

    submitForm() {
      this.validateAddress()
      this.validateMail()
      this.validateName()
      this.validatePhone()

      if (this.errorMail || this.errorName || this.errorAddress || this.errorPhone) {
        return;
      }

      axios.post(this.postUrl, {
        name: this.name,
        phone: this.phone,
        mail: this.mail,
        address: this.address,
      }).then(_ => {

        this.resetForm()

        // Open success screen
        this.openRegisterCompleteScreen()
      }).catch(error => {
        console.log(error);
      })
    },

    validateName() {
      this.errorName = this.name === null || this.name === '';
    },

    validateMail() {
      this.errorMail = this.mail === null || this.mail === '' || !this.reg.test(this.mail);
    },

    validateAddress() {
      this.errorAddress = this.address === null || this.address === '';
    },

    validatePhone() {
      this.errorPhone = this.phone === null || this.phone === '';
    },

    resetForm() {
      this.errorMail = false
      this.mail = null
      this.errorName = false
      this.name = null
      this.errorAddress = false
      this.address = null
      this.errorPhone = false
      this.phone = null
    },
  },
}
</script>
